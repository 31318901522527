import { useState, useEffect } from "react";
import React, { useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import SubmitButton from "../Buttons/Submit";
import CancelIcon from "../../assets/imgs/icons/cancel.svg";
import SearchIcon from "../../assets/imgs/icons/SearchBlue.svg";

const schema = yup.object({}).required();

const SelectFromList = ({
  title,
  options,
  selectedList,
  onResolve,
  onReject,
}) => {
  const backRef = useRef(null);
  const [error, setError] = useState("");
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const [virtualOptions, setVirtualOptions] = useState([]);
  const [showedOptions, setShowedOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [countSelected, setCountSelected] = useState(0);

  const toggleOption = (e, optionId) => {
    setVirtualOptions(
      virtualOptions.map((option) => {
        return option.id === optionId
          ? { ...option, checked: e.target.checked }
          : { ...option };
      })
    );
  };

  const clearSelection = () => {
    setVirtualOptions(
      virtualOptions.map((option) => {
        return { ...option, checked: false };
      })
    );
  };
  const searchOption = (e) => {
    const key = e.target.value;
    setSearchValue(key);
    setShowedOptions(
      virtualOptions.filter((option) =>
        option.name.toLowerCase().includes(key.toLowerCase())
      )
    );
  };
  useEffect(() => {
    setShowedOptions(virtualOptions);
    setSearchValue("");
    const checkedList = virtualOptions.filter((option) => option.checked);

    if (checkedList.length) {
      setCountSelected(checkedList.length);
    } else {
      setCountSelected(0);
    }
  }, [virtualOptions]);
  useEffect(() => {
    setVirtualOptions(
      options.map((option) => {
        return { ...option, checked: selectedList.includes(option.id) };
      })
    );
  }, [options]);
  const onSubmit = async (data) => {
    try {
      const checkedList = virtualOptions.filter((option) => option.checked);
      onResolve(checkedList);
    } catch (error) {
      setError(error);
    }
  };
  return (
    <div
      ref={backRef}
      isOpen={backRef}
      className="fixed inset-0 overflow-y-auto"
      style={{ zIndex: 1000 }}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block overflow-hidden text-left align-bottom bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
              <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div className="w-full sm:flex sm:items-start ">
                  <div className="w-full ">
                    <div className="flex flex-row justify-between py-4 border-b">
                      <h3
                        className="text-lg font-medium text-gray-900 leading-6"
                        id="modal-title"
                      >
                        {title}
                      </h3>
                      <div
                        className="cursor-pointer"
                        onClick={() => onReject()}
                      >
                        <img src={CancelIcon} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row w-full h-full p-2 border-b-2">
                  <img src={SearchIcon} />
                  <input
                    className="w-full px-2 py-2 ml-2 border-0 focus:outline-none"
                    placeholder="Search"
                    value={searchValue}
                    onChange={searchOption}
                  />
                </div>
                <div className="overflow-y-auto max-h-552">
                  {showedOptions.map((option) => {
                    if (option.type === "category") {
                      return (
                        <div className="w-full py-3 text-sm font-bold border-b font-roboto bg-bgGray2">
                          <div className="flex flex-row items-center px-4">
                            <div>{option.name}</div>
                            <div className="flex justify-center px-1 ml-2 text-sm font-normal text-white rounded-full bg-arrowRigh font-roboto">
                              {showedOptions.length - 1}
                            </div>
                          </div>
                        </div>
                      );
                    } else {
                      return (
                        <div className="py-2 py-4 text-sm border-b font-roboto">
                          <div className="flex flex-row items-center justify-between ">
                            <div className="flex flex-row">
                              <div className="px-4">
                                <input
                                  type="checkbox"
                                  checked={option.checked}
                                  onChange={(e) => {
                                    return toggleOption(e, option.id);
                                  }}
                                />
                              </div>
                              <div>{option.name}</div>
                            </div>
                            <div className="font-bold font-roboto">
                              <div>{option.amount_formated}</div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
                </div>
                <div className="flex flex-row items-center justify-between mt-10">
                  <div className="flex flex-row items-center">
                    {countSelected !== 0 && (
                      <>
                        <div className="flex justify-center px-1 mr-2 text-sm font-normal text-white rounded-full bg-arrowTop font-roboto">
                          {countSelected}
                        </div>
                        <div>Selected</div>
                      </>
                    )}
                  </div>
                  <div className="flex flex-row items-center">
                    {countSelected !== 0 && (
                      <div
                        className="px-8 text-sm font-bold cursor-pointer font-roboto text-grayColor"
                        onClick={clearSelection}
                      >
                        Clear selection
                      </div>
                    )}
                    <div className="w-24">
                      <SubmitButton>Done</SubmitButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default SelectFromList;
