import { useState } from "react";

const Tabs = ({ tabs, className }) => {
  const [activeTab, setActiveTab] = useState(tabs[0]);
  return (
    <div>
      <div className={`flex py-2  ${className}`}>
        {tabs.map((option) => {
          return (
            <div
              className={`flex items-center  px-4 text-center whitespace-nowrap cursor-pointer`}
            >
              <span
                className={`h-full py-1 text-base font-Roboto ${
                  activeTab.key === option.key ? "activeTab" : ""
                }`}
                onClick={() => {
                  setActiveTab(option);
                }}
              >
                {activeTab.key === option.key
                  ? option.onActiveText || option.text
                  : option.text}
              </span>
            </div>
          );
        })}
      </div>
      <div className="py-4 px-20">{activeTab.component}</div>
    </div>
  );
};

export default Tabs;
