import { useEffect, useRef, useState, useMemo, Suspense } from "react";
import { ReactComponent as ArrowDropDown } from "../../../assets/imgs/icons/arrow_drop_down.svg";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import DatePicker from "react-datepicker";
import { formatDate, lastDayOfMonth, returnListOfYears } from "../../../utils";
import { parse } from "date-fns";

import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import "./style.scss";

const TYPES = [
  {
    text: "Default",
    key: "default",
  },
  {
    text: "Custom",
    key: "custom",
  },
];
const DateRangeFilter = ({
  onChangeFilter,
  title,
  from_name,
  to_name,
  filters,
}) => {
  const [activeTab, setActiveTab] = useState("default");
  const [startDate, setStartDate] = useState(
    filters[from_name]
      ? parse(filters[from_name], "yyyy-MM-dd", new Date())
      : new Date()
  );
  const [endDate, setEndDate] = useState(
    filters[to_name]
      ? parse(filters[to_name], "yyyy-MM-dd", new Date())
      : new Date()
  );
  const [hasValue, setHasValue] = useState(
    filters[from_name] || filters[to_name]
  );

  const [quarter, setQuarter] = useState();

  const yearsList = returnListOfYears(2000);

  const onChangeQuarter = (date) => {
    setQuarter(date);
    setStartDate(date);
    let newDate = new Date(date.getTime());
    newDate.setMonth(date.getMonth() + 2);
    setEndDate(lastDayOfMonth(newDate));
    setHasValue(true);
  };

  const onChangeMonth = (date) => {
    setQuarter(null);
    setStartDate(date);
    setEndDate(lastDayOfMonth(date));
    setHasValue(true);
    // setIsOpen(false);
  };

  const onChangeCustome = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    setHasValue(true);
  };

  const methods = useForm({});

  useEffect(() => {
    const values = {
      [from_name]: formatDate(startDate),
      [to_name]: formatDate(endDate),
    };
    onChangeFilter(values);
  }, [startDate, endDate]);

  return (
    <div className="relative flex pr-2">
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button
            className={`inline-flex  w-full justify-center rounded-md bg-opacity-20 px-4 py-2 text-sm font-medium text-grayColorText2 hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75  
          ${hasValue ? "border-black border-2" : "border"}`}
          >
            <div className="flex flex-row items-center">
              <div className="px-2">{title} </div>
              {hasValue && (
                <div className="font-bold text-black">{`${formatDate(
                  startDate
                )}-${formatDate(endDate)}`}</div>
              )}
              <ArrowDropDown />
            </div>
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute left-0 z-50 mt-2 bg-white shadow-lg origin-top-right divide-y divide-gray-100 rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="h-full">
              <FormProvider {...methods}>
                <form className="h-full">
                  <div className="flex flex-row justify-between h-full">
                    <div className="flex flex-col w-32 h-full mt-2 border-r cursor-pointer">
                      <div className="border-b">
                        <Menu
                          as="div"
                          className="relative inline-block text-left"
                        >
                          <div>
                            <Menu.Button className="inline-flex w-full justify-center rounded-md text-black bg-opacity-20 px-4 py-2 text-sm font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                              {startDate.getFullYear()}
                              <ArrowDropDown />
                            </Menu.Button>
                          </div>
                          <Transition
                            as={Fragment}
                            enter="transition ease-out duration-100"
                            enterFrom="transform opacity-0 scale-95"
                            enterTo="transform opacity-100 scale-100"
                            leave="transition ease-in duration-75"
                            leaveFrom="transform opacity-100 scale-100"
                            leaveTo="transform opacity-0 scale-95"
                          >
                            <Menu.Items className="absolute left-0 mt-2 w-32 origin-top-right divide-y divide-gray-100  bg-white shadow-sm ring-1 ring-black ring-opacity-5 focus:outline-none">
                              <div
                                className="px-1 py-1 "
                                style={{
                                  height: "15rem",
                                  overflowY: "scroll",
                                }}
                              >
                                {yearsList.map((year) => (
                                  <Menu.Item>
                                    {({ active }) => (
                                      <button
                                        type="button"
                                        onClick={() => {
                                          // change start date year
                                          setEndDate(
                                            lastDayOfMonth(
                                              new Date(
                                                startDate.setFullYear(year)
                                              )
                                            )
                                          );
                                          setStartDate(
                                            new Date(
                                              startDate.setFullYear(year)
                                            )
                                          );
                                        }}
                                        className={`${
                                          active
                                            ? "bg-violet-500"
                                            : "text-gray-900"
                                        } flex w-full items-center rounded-md px-2 py-2 text-sm ${
                                          startDate.getFullYear() === year
                                            ? "bg-backgroundBlue"
                                            : ""
                                        }  `}
                                      >
                                        {year}
                                      </button>
                                    )}
                                  </Menu.Item>
                                ))}
                              </div>
                            </Menu.Items>
                          </Transition>
                        </Menu>
                        {/* <select
                          name=""
                          className="w-full pt-4 border-none"
                          value={startDate.getFullYear()}
                          style={{ height: "3rem" }}
                          onChange={(e) => {
                            // change start date year
                            setEndDate(
                              lastDayOfMonth(
                                new Date(startDate.setFullYear(e.target.value))
                              )
                            );

                            setStartDate(
                              new Date(startDate.setFullYear(e.target.value))
                            );
                          }}
                        >
                          {yearsList.map((year) => (
                            <option value={year}>{year}</option>
                          ))}
                        </select> */}
                      </div>
                      <div className="mt-2">
                        {TYPES.map((option) => {
                          return (
                            <button
                              type="button"
                              className={`flex items-center w-full py-2 pr-16 ${
                                activeTab === option.key
                                  ? " border-l-4 bg-backgroundBlue border-arrowTop"
                                  : ""
                              }`}
                              onClick={() => {
                                setActiveTab(option.key);
                              }}
                            >
                              <div className="flex flex-row px-2">
                                <div className="">{option.text}</div>
                              </div>
                            </button>
                          );
                        })}
                      </div>
                    </div>
                    <div className="w-full">
                      {activeTab === "default" ? (
                        <div className="min-w-max">
                          <div className="flex items-center pl-4 border-b gap-4">
                            <p>Year</p>
                            <DatePicker
                              name="date_range"
                              selected={quarter}
                              calendarClassName="date-picker-onfilter"
                              onChange={onChangeQuarter}
                              showQuarterYearPicker
                              inline
                            />
                          </div>
                          <DatePicker
                            name="date_range"
                            className="w-full"
                            calendarClassName="date-picker-onfilter"
                            startDate={startDate}
                            endDate={endDate}
                            onChange={onChangeMonth}
                            showMonthYearPicker
                            inline
                            selected={endDate}
                          />
                        </div>
                      ) : (
                        <DatePicker
                          name="date_range"
                          selected={startDate}
                          calendarClassName="date-picker-onfilter date-picker-onfilter-month"
                          onChange={onChangeCustome}
                          startDate={startDate}
                          endDate={endDate}
                          selectsRange
                          inline
                        />
                      )}
                    </div>
                  </div>
                </form>
              </FormProvider>
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default DateRangeFilter;
