import { useEffect } from "react";
import { useTable, useSortBy } from "react-table";
import caretDown from "../../assets/imgs/icons/caret-down.svg";
import caretUp from "../../assets/imgs/icons/caret-up.svg";
import emptyState from "../../assets/imgs/doc-empty.png";
import { FaPlus } from "react-icons/fa";
import Button from "../Button";
const Table = ({ columns, data, handleClickRow, onChangeSortBy, ...props }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { sortBy },
  } = useTable(
    {
      columns,
      data,
      ...props,
    },
    useSortBy
  );

  useEffect(() => {
    let orderBy = null;
    if (sortBy.length) {
      orderBy = sortBy[0].id;
      orderBy = orderBy?.replace("erp_data.", "");
      if (orderBy === "date") {
        orderBy = "doc_issuance_date";
      }
      if (sortBy[0].desc) {
        orderBy += " desc";
      }
    }
    onChangeSortBy(orderBy);
  }, [sortBy]);
  return (
    <table
      className="w-full relative text-sm text-center table-auto font-roboto"
      {...getTableProps()}
    >
      <thead className="bg-bgGray">
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th
                {...column.getHeaderProps(column?.getSortByToggleProps())}
                className="p-4 text-xs"
              >
                <div className="flex flex-row items-center justify-center">
                  {column.render("Header")}
                  <span>
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <img src={caretDown} />
                      ) : (
                        <img src={caretUp} />
                      )
                    ) : (
                      ""
                    )}
                  </span>
                </div>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      {rows.length === 0 ? (
        <div
          style={{
            top: "50%",
            left: "50%",
            transform: "translate(-90%, 70%)",
          }}
          className="absolute"
        >
          <div className="flex flex-col justify-center items-center">
            <img src={emptyState} alt="" />
            <h4 className="text-black text-lg mt-3">Documents</h4>
            <p className="text-grayColorText2 mt-4 text-sm">
              Upload a document or create one to show here
            </p>
            <Button
              className="font-bold flex items-center gap-2 rounded-sm mt-4"
              type="primary"
              href="/new-request/upload"
              size="small"
            >
              <FaPlus />
              Upload Document
            </Button>
          </div>
        </div>
      ) : (
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                className="border-b cursor-pointer"
                onClick={() => {
                  handleClickRow(row.original.id, row.original);
                }}
              >
                {row.cells.map((cell) => {
                  return (
                    <td
                      {...cell.getCellProps()}
                      className="py-2 text-sm leading-4"
                    >
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      )}
    </table>
  );
};

export default Table;
