import { useImage } from "react-image";

const Thumbnail = ({ srcImage, numberOfAttachments }) => {
  const { src } = useImage({
    srcList: srcImage,
    useSuspense: false,
  });
  return (
    <div className="flex flex-row justify-center relative">
      <img src={src} width="60" height="60" />
      {numberOfAttachments > 1 && (
        <div className="absolute bottom-0 right-10 bg-arrowRigh px-2 py-1 text-white rounded-tl-md rounded-br-md">
          {numberOfAttachments}
        </div>
      )}
    </div>
  );
};

export default Thumbnail;
