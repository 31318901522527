import React, { useEffect, useRef } from "react";
import succuessUploadDocumentFile from "../../assets/lottiefile/successUploadDocument.json";
import { Player } from "@lottiefiles/react-lottie-player";
const SuccessUploadDocument = ({
  title,
  message,
  time,
  availableBalance,
  onResolve,
  onReject,
}) => {
  const backRef = useRef(null);
  useEffect(() => {
    setTimeout(() => {
      onResolve(true);
    }, time);
  }, []);
  return (
    <div
      ref={backRef}
      isOpen={backRef}
      className="fixed inset-0 overflow-y-auto"
      style={{ zIndex: 1000 }}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <div
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          aria-hidden="true"
        ></div>
        <span
          className="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
        >
          &#8203;
        </span>
        <div className="inline-block overflow-hidden text-left align-bottom bg-white shadow-xl rounded-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full">
          <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
            <div className="w-full sm:flex sm:items-start ">
              <div className="w-full">
                <div className="flex flex-col items-center justify-center font-roboto">
                  <div>
                    <Player
                      autoplay={true}
                      loop={false}
                      controls={false}
                      keepLastFrame={true}
                      src={succuessUploadDocumentFile}
                      style={{ height: "200px", width: "200px" }}
                    ></Player>
                  </div>
                  <div className="font-bold leading-7">{title}</div>
                  <div className="text-sm leading-5">{message}</div>
                  {availableBalance && (
                    <div className="flex flex-col items-center justify-center w-full py-2 mt-4 bg-bgGray ">
                      <div className="text-sm font-roboto">
                        Available balance now
                      </div>
                      <div className="mt-1 text-2xl font-bold font-roboto">
                        {availableBalance}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessUploadDocument;
