const SubmitButton = ({ children, className, ...props }) => {
  return (
    <button
      className={`w-full submit_btn p-4 bg-arrowTop rounded text-sm font-bold text-gray-50 disabled:text-black  disabled:bg-disableBtnGray ${className}`}
      type="submit"
      {...props}
    >
      {children}
    </button>
  );
};

export default SubmitButton;
