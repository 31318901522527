import { Disclosure } from "@headlessui/react";

const GeneralReport = ({ data }) => {
  const handleLevelZero = (row) => {
    return (
      <div className="w-full py-2 border-b">
        <div className="text-lg font-bold leading-6">{row.name}</div>
      </div>
    );
  };
  const handleLevelOne = (row) => {
    return (
      <div className="w-full py-2">
        <div className="font-bold text-medium leading-6">{row.name}</div>
      </div>
    );
  };

  const handleLevelTwo = (row) => {
    const childrens = data.filter((frow) => frow.parent_id == row.id);
    return (
      <Disclosure>
        {({ open }) => (
          <>
            <Disclosure.Button
              className={`flex w-full p-2 ${open ? "" : "border-b"}`}
            >
              <div className="flex flex-row items-center justify-between w-full ">
                <div className="font-medium text-medium leading-6">
                  {row.name}
                </div>
                <div className="font-medium text-medium leading-6">
                  {row.columns[0].name}
                </div>
              </div>
            </Disclosure.Button>

            <Disclosure.Panel
              className={`pl-12 pr-2 ${open ? "border-b" : ""}`}
            >
              <ul className="list-disc">
                {childrens.map((child) => handleLevelThree(child))}
              </ul>
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    );
  };

  const handleLevelThree = (row) => {
    return (
      <li>
        <div className="flex flex-row items-center justify-between w-full py-2">
          <div className="leading-5">{row.name}</div>
          <div className="leading-5">{row.columns[0].name}</div>
        </div>
      </li>
    );
  };
  const handleLevels = (row) => {
    switch (row.level) {
      case 0:
        return handleLevelZero(row);
        break;

      case 1:
        return handleLevelOne(row);
        break;

      case 2:
        return handleLevelTwo(row);
        break;
      default:
        return;
    }
  };
  return (
    <div>
      {data
        .filter((row) => row.level <= 2)
        .map((row) => {
          return handleLevels(row);
        })}
    </div>
  );
};

export default GeneralReport;
