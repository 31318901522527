import { useFormContext } from "react-hook-form";
import ErrorMessage from "../../ErrorMessage";
import ErrorInput from "../Error";
import _ from "lodash";

const TextInput = ({ name, serverErrors, ...props }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="w-full">
      <div>
        <input
          type="text"
          className={`w-full placeholder:text-inputGrayColor placeholder:text-sm ${
            _.get(errors, name)
              ? "text-errorColor border-errorColor focus:text-errorColor focus:border-errorColor focus:bg-white placeholder:text-errorColor"
              : ""
          }`}
          {...register(name)}
          {...props}
        />
      </div>
      <div>
        <ErrorInput name={name} errors={errors} serverErrors={serverErrors} />
      </div>
    </div>
  );
};

export default TextInput;
