import { useState, useMemo, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import DocumentsService from "../../services/Documents";
import Tabs from "../../components/Tabs";
import TitleField from "../../components/View/Field/Title";
import ValueFeild from "../../components/View/Field/value";
import ClientStatus from "./ClientStatus";
import PaymentStatus from "./PaymentStatus";
import DocumentAttachments from "./DocumentAttachments";
import { trackPromise } from "react-promise-tracker";
import Spinner from "../../components/Spinner";
import DocumentAssingees from "./DocumentAssignee";
import UserValue from "../../components/View/Field/UserValue";
import CalendarIcon from "../../assets/imgs/icons/calendar.svg";
import Container from "../../components/Container";
const DocumentDetails = ({}) => {
  const params = useParams();
  const documentId = params.documentId;
  const { state } = useLocation();
  const [document, setDocument] = useState();
  console.log(document);

  const DOCUMENT_TABS = useMemo(() => [
    {
      text: "Documents",
      key: "documents",
      component: (
        <DocumentAttachments
          attachments={document?.erp_data?.attachments || []}
        />
      ),
    },
    {
      text: "Assignee",
      key: "assignee",
      component: (
        <DocumentAssingees assignees={document?.erp_data?.assignees} />
      ),
    },

    {
      text: "Payments Received",
      key: "paymentsReceived",
    },
    {
      text: "Logs and history",
      key: "LogsAndHistory",
    },
  ]);
  const getDocument = async (documentId) => {
    const document = await DocumentsService.getDocument(documentId);
    setDocument(document);
  };
  useEffect(() => {
    trackPromise(getDocument(documentId), "document");
  }, [documentId]);
  return (
    <>
      {document && (
        <div>
          <Container>
            <div className="flex flex-row items-center justify-between w-1/3 mt-8">
              <div className="flex flex-col">
                <div>
                  <TitleField title="DOC. Ref #" />
                </div>
                <div className="mt-2">
                  <ValueFeild
                    value={document?.erp_data?.reference}
                    className="font-bold"
                    showPending={true}
                  />
                </div>
              </div>
              <div className="flex flex-col">
                <TitleField title="Request ID" />
                <div className="mt-2">
                  <ValueFeild
                    value={document?.erp_data?.name}
                    className="font-bold"
                  />
                </div>
              </div>

              <div className="flex flex-col">
                <TitleField title="Status" />
                <div className="mt-2">
                  <ClientStatus status={document?.erp_data.client_status} />
                </div>
              </div>
            </div>
          </Container>
          <div className="mt-8 border-t border-b ">
            <Container>
              <div className="flex flex-row items-center justify-between py-6 ">
                <div className="flex flex-col">
                  <TitleField title="Creation Date & Time" />
                  <div className="mt-2">
                    <ValueFeild
                      value={document?.erp_data?.created_at}
                      icon={CalendarIcon}
                    />
                  </div>
                </div>

                <div className="flex flex-col">
                  <TitleField title="Document Date" />
                  <div className="mt-2">
                    <ValueFeild
                      value={document?.erp_data?.date}
                      showPending={true}
                      icon={CalendarIcon}
                    />
                  </div>
                </div>

                <div className="flex flex-col">
                  <TitleField title="Created by" />
                  <div className="mt-2">
                    <UserValue name={document?.erp_data?.creator} />
                  </div>
                </div>
                <div className="flex flex-col">
                  {document?.erp_data?.approver_id !=
                    document.erp_data.creator_id && (
                    <div>
                      <TitleField title="Approver" />
                      <div className="mt-2">
                        <UserValue name={document?.erp_data?.approver} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Container>
          </div>
          <Container>
            <div className="mt-4">
              <div className="font-bold">Details</div>
              <div className="w-1/3">
                <table className="table-auto">
                  <tbody className="">
                    <tr className="">
                      <td className="py-2 pr-44">
                        <TitleField title="Parnter Name" />
                      </td>
                      <td>
                        <ValueFeild
                          value={document?.erp_data?.beneficiary}
                          showPending={true}
                          className="font-bold"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="py-2 pr-44">
                        <TitleField title="Payment Status" />
                      </td>
                      <td>
                        <PaymentStatus
                          status={document?.erp_data?.payment_status}
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="py-2 pr-44">
                        <TitleField title="Payment Method" />
                      </td>
                      <td>
                        <ValueFeild
                          value={document?.erp_data?.payment_method}
                          className="font-bold"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="py-2 pr-44">
                        <TitleField title="Currency" />
                      </td>
                      <td>
                        <ValueFeild
                          value={
                            document?.erp_data?.currency ||
                            "AED - Emirati Dirham"
                          }
                          className="font-bold"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pr-44">
                        <TitleField title="Amount" />
                      </td>
                      <td>
                        <ValueFeild
                          value={document?.erp_data?.amount_with_currency}
                          className="font-bold"
                          showPending
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pr-44">
                        <TitleField title="Cost Center" />
                      </td>
                      <td>
                        <ValueFeild
                          value={document?.erp_data?.cost_center}
                          className="font-bold"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pr-44">
                        <TitleField title="Category" />
                      </td>
                      <td>
                        <ValueFeild
                          value={document?.erp_data?.tag}
                          className="font-bold"
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="py-2 pr-44">
                        <TitleField title="Description" />
                      </td>
                      <td>
                        <ValueFeild
                          value={document?.erp_data?.client_description}
                          className="font-bold"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Container>
          <div className="mt-4">
            <Tabs tabs={DOCUMENT_TABS} className="bg-bgGray px-14" />
          </div>
        </div>
      )}
      <Spinner area="document" />
    </>
  );
};

export default DocumentDetails;
