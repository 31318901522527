import getHttpClient from "../api";
const client = getHttpClient();

const services = {
  getBalanchSheet: async (companyId, date_to) => {
    try {
      const data = {
        date_to: date_to,
      };
      const response = await client.post(
        `/reports/balance-sheet/${companyId}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getProfitAndLoss: async (companyId, date_from, date_to) => {
    try {
      const data = {
        date_from: date_from,
        date_to: date_to,
      };
      const response = await client.post(
        `/reports/profit-and-loss/${companyId}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  getCashFlow: async (companyId, date_from, date_to) => {
    try {
      const data = {
        date_from: date_from,
        date_to: date_to,
      };
      const response = await client.post(
        `/reports/cash-flow/${companyId}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default services;
