import { connect } from "react-redux";

const ReportHeader = ({ title, currentCompany, date }) => {
  return (
    <div>
      <div>
        <div className="py-1 text-2xl font-bold">{title}</div>
        <div className="">{currentCompany.name}</div>
        <div className="py-1 text-sm text-gray">{`as of ${date}`}</div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentCompany: state.Company.currentCompany,
  };
};
export default connect(mapStateToProps, {})(ReportHeader);
