import { useState, useEffect } from "react";
import React, { useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import SubmitButton from "../Buttons/Submit";
import CancelIcon from "../../assets/imgs/icons/cancel.svg";
import MonthPicker from "../Inputs/MonthPicker";

const schema = yup.object({}).required();

const SelectMonth = ({ title, filterDate, onResolve, onReject }) => {
  const backRef = useRef(null);
  const [error, setError] = useState("");
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: { payroll_month: null },
  });

  const Cancel = () => {
    onReject();
  };

  const onSubmit = async (data) => {
    try {
      onResolve(data);
    } catch (error) {
      setError(error);
    }
  };
  return (
    <div
      ref={backRef}
      isOpen={backRef}
      className="fixed inset-0 overflow-y-auto"
      style={{ zIndex: 1000 }}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block overflow-hidden text-left align-bottom bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
              <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div className="w-full sm:flex sm:items-start ">
                  <div className="w-full ">
                    <div className="flex flex-row justify-between py-4">
                      <h3
                        className="text-lg font-medium text-gray-900 leading-6"
                        id="modal-title"
                      >
                        {title}
                      </h3>
                      <div
                        className="cursor-pointer"
                        onClick={() => onReject()}
                      >
                        <img src={CancelIcon} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <MonthPicker
                    name={`payroll_month`}
                    maxDate={new Date()}
                    serverErrors={error}
                    inline
                    filterDate={filterDate}
                  />
                </div>
                <div className="flex flex-row items-center justify-between mt-10">
                  <div className="flex flex-row items-center"></div>
                  <div className="flex flex-row items-center">
                    <div
                      className="px-8 text-sm font-bold cursor-pointer font-roboto text-grayColor"
                      onClick={Cancel}
                    >
                      Cancel
                    </div>
                    <div className="w-36">
                      <SubmitButton>Continue</SubmitButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default SelectMonth;
