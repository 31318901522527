import { useState } from "react";
import ReactPaginate from "react-paginate";

const Pagination = ({
  currentPage,
  pageSize,
  listSize,
  pageCount,
  handlePageClick,
}) => {
  return (
    <div className="flex flex-row items-center justify-between px-4">
      <div>
        {listSize > 0 && (
          <div>
            showing {Math.min(pageSize, listSize)} of {listSize}
          </div>
        )}
      </div>
      <div className="p-4">
        {pageCount > 0 && (
          <ReactPaginate
            // disableInitialCallback={true}
            className="flex flex-row"
            breakLabel="..."
            nextLabel=">"
            forcePage={currentPage}
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel="<"
            breakLinkClassName="py-2 px-4 border border-grayBorder rounded-md m-1 text-black"
            pageLinkClassName="py-2 px-4 border border-grayBorder rounded-md m-1 cursor-pointer text-black"
            previousLinkClassName="py-2 px-4 border border-grayBorder rounded-md m-1 cursor-pointer text-black"
            nextLinkClassName="py-2 px-4 border border-grayBorder rounded-md m-1 cursor-pointer text-black"
            activeLinkClassName="py-2 px-4 bg-arrowTop border border-grayBorder rounded-md m-1 cursor-pointer text-white"
            renderOnZeroPageCount={null}
          />
        )}
      </div>
    </div>
  );
};

export default Pagination;
