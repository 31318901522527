import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import "./style.scss";
import Logo from "../../assets/imgs/logoTheaccounter.svg";
import HomeImg from "../../assets/imgs/home-beta.svg";
import AddDocumentIcon from "../../assets/imgs/icons/add-document.svg";
import LogoutIcon from "../../assets/imgs/icons/logout.svg";
import EditIcon from "../../assets/imgs/icons/edit.svg";
import DropDownIcon from "../../assets/imgs/icons/arrow_drop_down.svg";
import AddIcon from "../../assets/imgs/icons/add-2.svg";
import SwitchCompany from "../../components/SwitchCompany";
import Auth from "../../services/Auth";
import useModal from "../../components/Modal/useModal";
import ConfirmLogout from "../../components/Modal/ConfirmLogout";
import { generateAvatar } from "../../utils";
const Home = ({ user, currentCompany }) => {
  const callPromptModalConfrimLogout = useModal(ConfirmLogout);
  const [isOpen, setIsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const ref = useRef(null);
  const profileRef = useRef(null);
  const navigator = useNavigate();

  const toggleOpen = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const toggleProfileOpen = () => {
    setIsProfileOpen((isProfileOpen) => !isProfileOpen);
  };

  const onClickOutside = () => {
    setIsOpen(false);
  };

  const onClickOutsideProfile = () => {
    setIsProfileOpen(false);
  };
  const logout = async () => {
    try {
      const res = await callPromptModalConfrimLogout({
        title: "Log out?",
        message: "Are you sure you want to log out?",
      });
      if (res) {
        await Auth.logout();
        navigator("/login");
      }
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        onClickOutside && onClickOutsideProfile();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [profileRef]);
  const avatar = generateAvatar(user?.name);

  return (
    <div className="container-center-horizontal">
      <div className="home-page-beta screen">
        <div className="header">
          <img className="logo" src={Logo} />
          <div className="left">
            <div className="text">
              <div className="company roboto-normal-rolling-stone-14px-2">
                Company
              </div>
              <SwitchCompany />
            </div>
          </div>
          <div className="right">
            <div className="relative" ref={ref}>
              <div className="button" onClick={toggleOpen}>
                <img className="add-2" src={AddIcon} />
                <div className="button-1 roboto-bold-white-14px">Create</div>
              </div>
              {isOpen && (
                <div
                  className="absolute py-2 bg-white rounded-md shadow-dropdownMenu overflow-hidden z-20 mt-4"
                  style={{ marginLeft: "-40px" }}
                >
                  <div className="dropdown-menu-add-a-new-orderscreen">
                    <div
                      className="list-group-item"
                      onClick={() => {
                        navigator("/new-request/upload");
                      }}
                    >
                      <img className="add-document" src={AddDocumentIcon} />
                      <div className="text valign-text-middle roboto-medium-outer-space-14px">
                        Upload Document
                      </div>
                    </div>
                    <div
                      className="list-group-item-1"
                      onClick={() => {
                        navigator("/new-request/create-payment");
                      }}
                    >
                      <img className="edit" src={EditIcon} />
                      <div className="text valign-text-middle roboto-medium-outer-space-14px">
                        Create Document
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="relative" ref={profileRef}>
              <div className="profile" onClick={toggleProfileOpen}>
                <div className="avatar">
                  <div className="mj valign-text-middle roboto-bold-pacific-blue-16px">
                    {avatar}
                  </div>
                </div>
                <div className="name valign-text-middle roboto-medium-outer-space-16px">
                  {user?.name}
                </div>
                <img className="arrow-drop-down" src={DropDownIcon} />
              </div>

              {isProfileOpen && (
                <div className="absolute bg-white rounded-md shadow-dropdownMenu overflow-hidden z-20 profile-menu w-auto">
                  <div className="w-full h-full border-1px-outer-space-2">
                    <div className="dropdown-menu-user-menu-1 border-1px-outer-space-2 px-4">
                      <div className="list-group-itemdefault w-max">
                        <div className="avatar">
                          <div className="mj valign-text-middle">{avatar}</div>
                        </div>
                        <div className="flex-col-profile w-max">
                          <div className="nameProfile">{user?.name}</div>
                          <div className="yourmailcom">
                            {user.email || user.mobile}
                          </div>
                        </div>
                      </div>
                      <div
                        className="list-group-itemdefault-1 w-max"
                        onClick={logout}
                      >
                        <div className="sign-out">
                          <img src={LogoutIcon} alt="" />
                        </div>
                        <div className="text valign-text-middle">Log out</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="cards">
          <div className="flex-row">
            <div className="flex-col">
              <div className="badgebase">
                <div className="badge valign-text-middle roboto-bold-white-14px">
                  BETA 1.0
                </div>
              </div>
              <h1 className="welcome-to-the-first poppins-bold-outer-space-24px">
                Welcome to the first beta edition of The Accounter web-version.
              </h1>
              <p className="here-to-change-the-w roboto-medium-outer-space-14px">
                Here to change the world of Accounting for SMEs.
                <br />
                This is not our final form, many more amazing features are under
                preparation and will be added to this page very soon.
                <br />
                Enjoy simple accounting made easy. Start uploading now.
              </p>
              <div className="buttons">
                <div
                  className="button-2"
                  onClick={(e) => {
                    e.preventDefault();
                    navigator("/new-request/upload");
                  }}
                >
                  <div className="button-3 roboto-bold-white-14px">
                    Upload Document
                  </div>
                </div>
                <div
                  className="button-4 border-1px-pacific-blue"
                  onClick={(e) => {
                    e.preventDefault();
                    navigator("/new-request/create-payment");
                  }}
                >
                  <div className="button-5 roboto-bold-pacific-blue-14px">
                    Create Document
                  </div>
                </div>
              </div>
            </div>
            <div className="overlap-group">
              <img src={HomeImg} />
            </div>
          </div>
          <p className="if-you-have-any-ques">
            <span className="roboto-normal-outer-space-14px">
              If you have any questions or need help, contact our
            </span>
            <span className="span1">&nbsp;</span>
            <a
              href={`mailto:customersupport@theaccounter.com?subject=${
                user.name
              }%20is%20facing%20an%20issue%20on%20Theaccounter%20web%20app%20-%20(${
                user.email || user.mobile
              })&body=User%20Name%3A%20${user.name}%0D%0AUser%20Contact%3A%20${
                user.email || user.mobile
              }%0D%0ACompany%20Name%3A%20${
                currentCompany.name
              }%0D%0A%0D%0AStart%20describing%20your%20issue%20here`}
              target="_blank"
            >
              <span className="span2">support team</span>
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.currentUser,
    currentCompany: state.Company.currentCompany,
  };
};

export default connect(mapStateToProps, {})(Home);
