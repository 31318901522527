import { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { connect } from "react-redux";

import SwitchCompany from "../SwitchCompany";
import useModal from "../../components/Modal/useModal";
import ConfirmLogout from "../../components/Modal/ConfirmLogout";
import Auth from "../../services/Auth";
import { generateAvatar } from "../../utils";
import AddIcon from "../../assets/imgs/icons/add-2.svg";
import AddDocumentIcon from "../../assets/imgs/icons/add-document.svg";
import EditIcon from "../../assets/imgs/icons/edit.svg";
import DropDownIcon from "../../assets/imgs/icons/arrow_drop_down.svg";
import LogoutIcon from "../../assets/imgs/icons/logout.svg";
import "./style.scss";
import CreatingDropDown from "./components/CreatingDropdown";

const Header = ({ user, currentCompany }) => {
  const callPromptModalConfrimLogout = useModal(ConfirmLogout);
  const [isOpen, setIsOpen] = useState(false);
  const [isProfileOpen, setIsProfileOpen] = useState(false);
  const ref = useRef(null);
  const profileRef = useRef(null);
  const navigator = useNavigate();

  const toggleOpen = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const toggleProfileOpen = () => {
    setIsProfileOpen((isProfileOpen) => !isProfileOpen);
  };

  const onClickOutside = () => {
    setIsOpen(false);
  };

  const onClickOutsideProfile = () => {
    setIsProfileOpen(false);
  };
  const logout = async () => {
    try {
      const res = await callPromptModalConfrimLogout({
        title: "Log out?",
        message: "Are you sure you want to log out?",
      });
      if (res) {
        await Auth.logout();
        navigator("/login");
      }
    } catch (e) {
      console.error(e);
    }
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileRef.current && !profileRef.current.contains(event.target)) {
        onClickOutside && onClickOutsideProfile();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [profileRef]);
  const avatar = generateAvatar(user?.name);
  return (
    <header className="sticky top-0 z-30 bg-white border-b container-center-horizontal">
      <div className="w-full px-8 py-2 pr-12">
        <div className="flex items-center justify-between h-16 -mb-px">
          <div className="left">
            <div className="text">
              <div className="company roboto-normal-rolling-stone-14px-2">
                Company
              </div>
              <SwitchCompany />
            </div>
          </div>

          <div className="right">
            <CreatingDropDown />

            <div className="relative" ref={profileRef}>
              <div className="profile" onClick={toggleProfileOpen}>
                <div className="avatar">
                  <div className="mj valign-text-middle roboto-bold-pacific-blue-16px">
                    {avatar}
                  </div>
                </div>
                <div className="name valign-text-middle roboto-medium-outer-space-16px">
                  {user?.name}
                </div>
                <img className="arrow-drop-down" src={DropDownIcon} />
              </div>

              {isProfileOpen && (
                <div className="absolute right-0 z-20 w-auto overflow-hidden bg-white rounded-md shadow-dropdownMenu profile-menu">
                  <div className="w-full h-full border-1px-outer-space-2">
                    <div className="px-4 dropdown-menu-user-menu-1 border-1px-outer-space-2">
                      <div className="list-group-itemdefault w-max">
                        <div className="avatar">
                          <div className="mj valign-text-middle">{avatar}</div>
                        </div>
                        <div className="flex-col-profile w-max">
                          <div className="nameProfile">{user?.name}</div>
                          <div className="yourmailcom">
                            {user.email || user.mobile}
                          </div>
                        </div>
                      </div>
                      <div
                        className="list-group-itemdefault-1 w-max"
                        onClick={logout}
                      >
                        <div className="sign-out">
                          <img src={LogoutIcon} alt="" />
                        </div>
                        <div className="text valign-text-middle">Log out</div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.currentUser,
    currentCompany: state.Company.currentCompany,
  };
};

export default connect(mapStateToProps, {})(Header);
