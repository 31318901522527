import { debounce } from "lodash";
import { useEffect, useMemo, useReducer, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Pagination from "../../components/Pagination";
import Spinner from "../../components/Spinner";
import Table from "../../components/Table";
import Thumbnail from "../../components/Thumbnail";
import ClientStatus from "./ClientStatus";
import Filter from "./Filter";
import PaymentStatus from "./PaymentStatus";
import { connect, useSelector } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import queryString from "query-string";
const DocumentList = ({
  currentCompany,
  columns,
  getDocumentsService,
  handleClickRow,
}) => {
  const location = useLocation();
  const [listSize, setListSize] = useState(0);
  const [pageSize, setPageSize] = useState(30);
  const [pageCount, setPageCount] = useState(0);

  const { refreshKey } = useSelector((state) => state.Helpers);

  console.log();

  const [searchValue, setSearchValue] = useState(
    queryString.parse(location.search).search_value || null
  );
  const [orderBy, setOrderBy] = useState();

  const [actualFilters, setActualFilters] = useState(
    queryString.parse(location.search)
  );
  const [data, setData] = useState([]);

  const setFilterInQueryString = (object) => {
    const result = "?" + new URLSearchParams(object).toString();
    const newUrl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      result;
    window.history.pushState({ path: newUrl }, "", newUrl);
  };

  const getDocuments = async () => {
    try {
      const loadDocuments = async () => {
        const cleanedFilters = Object.fromEntries(
          Object.entries(actualFilters).filter(([_, v]) => v)
        );
        if (searchValue) {
          cleanedFilters.search_value = searchValue;
        }
        if (orderBy) {
          cleanedFilters.order_by = orderBy;
        }
        setFilterInQueryString(cleanedFilters);
        const documents = await getDocumentsService(
          currentCompany.id,
          cleanedFilters
        );
        setData(documents?.results);
        setListSize(documents.count);
        setPageCount(parseInt(documents.count / documents.results.length));
      };

      await trackPromise(loadDocuments(), "table");
    } catch (error) {
      console.error(error);
    }
  };

  const debouncedGetDocuments = debounce(() => {
    getDocuments();
  }, 500);

  useEffect(() => {
    debouncedGetDocuments();
  }, [actualFilters, searchValue, orderBy, refreshKey]);

  // useEffect(() => {
  //   handlePageClick({
  //     selected: queryString.parse(location.search)["page"] | 1,
  //   });
  // }, [searchValue, orderBy, actualFilters]);

  const handlePageClick = ({ selected }) => {
    const ob = actualFilters;
    ob.page = +selected + 1;
    setActualFilters({ ...actualFilters, ...ob });
    setFilterInQueryString(ob);
  };

  const onSearchChange = (value) => {
    // const ob = actualFilters;
    // setActualFilters({ ...actualFilters, ...ob });
    setSearchValue(value);
  };
  const onChangeSortBy = (value) => {
    setOrderBy(value);
  };
  const handleApplyFilter = (filters) => {
    setActualFilters({ ...filters });
  };

  return (
    <div>
      <Filter
        searchValue={searchValue}
        onApplyFilter={handleApplyFilter}
        onSearchChange={onSearchChange}
      />
      <div className="relative flex flex-col mt-4  min-h-630">
        <Table
          columns={columns}
          data={data}
          handleClickRow={handleClickRow}
          onChangeSortBy={onChangeSortBy}
          manualSortBy={true}
          initialState={{ hiddenColumns: ["id"] }}
        />
        <Spinner area="table" />
      </div>
      <Pagination
        pageSize={pageSize}
        listSize={listSize}
        pageCount={pageCount}
        handlePageClick={handlePageClick}
        currentPage={(queryString.parse(location.search)["page"] - 1) | 0}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentCompany: state.Company.currentCompany,
  };
};

export default connect(mapStateToProps, {})(DocumentList);
