import React from "react";
import { usePromiseTracker } from "react-promise-tracker";
import spinnerFile from "../../assets/lottiefile/loader.json";
import { Player } from "@lottiefiles/react-lottie-player";

const Spinner = (props) => {
  const { promiseInProgress } = usePromiseTracker({ area: props.area });
  return (
    promiseInProgress && (
      <div
        className="absolute w-full h-full top-0 flex justify-center items-center"
        style={{ zIndex: 2000 }}
      >
        <div className="flex flex-col items-center p-4 bg-white rounded-2xl h-44 w-44 shadow-toastify">
          <Player
            autoplay={true}
            loop={true}
            controls={false}
            keepLastFrame={true}
            src={spinnerFile}
            style={{ height: "144px", width: "144px" }}
          ></Player>
        </div>
      </div>
    )
  );
};
export default Spinner;
