import { connect } from "react-redux";
import SubmitButton from "../../Buttons/Submit";
import CodeVerificationInput from "../../Inputs/CodeVerification";
import { goNext, goPrev } from "../../../store/signup/actions";
import Timer from "../../Timer";
import { useEffect, useState } from "react";
import Verification from "../../../services/Verification";
import ErrorMessage from "../../ErrorMessage";
import editIcon from "../../../assets/imgs/edit.svg";

const CodeVerification = ({
  contact,
  type,
  goNext,
  goPrev,
  validate_contact,
}) => {
  const [canResend, setCanResend] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [minutes, setMinutes] = useState();
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [errorResend, setErrorResend] = useState("");
  const [resetInput, setresetInput] = useState(false);
  const handleVerify = async () => {
    try {
      setIsSubmitting(true);
      setErrorResend(false);
      await Verification.verifyCode(contact, code);
      goNext();
    } catch (err) {
      setError(err);
    } finally {
      setIsSubmitting(false);
    }
  };
  const resend = async () => {
    try {
      if (type === "email") {
        const { email } = await Verification.resendVerifyEmail(
          contact,
          validate_contact
        );
      } else if (type === "mobile") {
        const { email } = await Verification.resendVerifyMobile(
          contact,
          validate_contact
        );
      }
      setCanResend(false);
      setErrorResend("");
      setresetInput(true);
      setError("");
    } catch (err) {
      setErrorResend(err);
    }
  };
  useEffect(() => {
    if (!canResend) {
      const time = new Date();
      time.setSeconds(time.getSeconds() + 120); // 2 minutes timer
      setMinutes(time);
    }
  }, [canResend]);
  const handleOnChangeCode = (code) => {
    setCode(code);
  };
  return (
    <div>
      <div className="bg-backgroundGray w-full h-18 p-4 mt-5">
        <div className="flex justify-between ">
          <div className="text-sm font-bold leading-5">{contact}</div>
          {goPrev && (
            <div>
              <img
                src={editIcon}
                alt=""
                onClick={() => {
                  goPrev();
                }}
              />
            </div>
          )}
        </div>
        <div className="text-sm font-normal leading-5 text-grayColorText">
          {type === "mobile"
            ? "Number not confirmed yet"
            : "Email not confirmed yet"}
        </div>
      </div>
      <div className="mt-5 w-276">
        <div className="text-sm font-medium font-poppins">
          Confirmation code
        </div>
        <CodeVerificationInput
          name="token"
          length={4}
          onChange={handleOnChangeCode}
          reset={resetInput}
          setReset={setresetInput}
          serverErrors={error}
        />
        <div className="mt-4 text-sm text-gray-500 leading-5 mb-7">
          Enter the 4-digit code verification code sent to your {type}
        </div>
        {error?.detail && <ErrorMessage>{error.detail}</ErrorMessage>}
        <div className="">
          <SubmitButton
            onClick={handleVerify}
            disabled={code.length !== 4 || isSubmitting}
          >
            Confirm
          </SubmitButton>
        </div>
        {!canResend && minutes && (
          <div className="mt-8">
            <Timer
              expiryTimestamp={minutes}
              onExpire={() => {
                setCanResend(true);
                setMinutes(null);
                setErrorResend("");
              }}
            />
          </div>
        )}
        {canResend && (
          <div>
            <div className="mt-8 font-roboto leading-5 ">
              I didn't receive a code
              <button
                className="cursor-pointer pl-3 text-arrowRigh"
                onClick={resend}
              >
                Resend
              </button>
            </div>
            {errorResend?.detail && (
              <ErrorMessage>{errorResend.detail}</ErrorMessage>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default CodeVerification;
