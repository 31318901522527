import { useState } from "react";
import { connect } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import SubmitButton from "../../Buttons/Submit";
import EmailInput from "../../Inputs/Email";
import Verification from "../../../services/Verification";

import { goNext, setSignupContact } from "../../../store/signup/actions";
import ErrorMessage from "../../ErrorMessage";

const schema = yup.object({
  email: yup.string().email("Invalid email").required("This field is required"),
});
const SendCodeToEmail = ({ step, goNext, toggleType, setSignupContact }) => {
  const [error, setError] = useState("");
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const handleSendCode = async (data) => {
    try {
      await Verification.verifyEmail(data?.email);
      setSignupContact(data?.email, "email");
      goNext();
    } catch (error) {
      methods.setError("email", error.detail || error.email);
      // setError(error);
    }
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleSendCode)}>
        <div className="mt-11 text-sm font-normal leading-5	">
          Enter your email address
        </div>
        <div className="mt-6">
          <EmailInput
            name="email"
            serverErrors={error}
            placeholder="Email Address"
          />
        </div>
        <div className="mt-10">
          <SubmitButton disabled={methods.formState.isSubmitting}>
            Send Code
          </SubmitButton>
        </div>

        <div
          className="mt-8 text-arrowRigh cursor-pointer"
          onClick={toggleType}
        >
          Register using phone number
        </div>
      </form>
    </FormProvider>
  );
};
const mapStateToProps = (state) => {
  return {
    step: state.signup.step,
  };
};
export default connect(mapStateToProps, {
  goNext,
  setSignupContact,
})(SendCodeToEmail);
