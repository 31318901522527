import { useEffect, useState } from "react";
import Container from "../../components/Container";
import DateRangeFilter from "../../components/Filters/DateRangeFilter";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import ReportsService from "../../services/Reports";
import { formatDate } from "../../utils";
import ReportHeader from "./ReportHeader";
import GeneralReport from "./GeneralReport";
import { trackPromise } from "react-promise-tracker";

const CashFlow = ({ currentCompany }) => {
  const location = useLocation();
  const [data, setData] = useState([]);
  const [filters, setFilters] = useState(queryString.parse(location.search));
  const onChangeDateRangeFilter = (value) => {
    setFilters({ ...filters, ...value });
  };

  const getReportData = async () => {
    try {
      const get = async () => {
        const date_from = formatDate(filters?.date_from || new Date());
        const date_to = formatDate(filters?.date_to || new Date());
        const data = await ReportsService.getCashFlow(
          currentCompany.id,
          date_from,
          date_to
        );
        console.log(data);
        setData(data);
      };
      await trackPromise(get());
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getReportData();
  }, []);
  return (
    <div className="px-20">
      <div className="flex flex-row items-center">
        <div className="">Report Date</div>
        <div className="px-2">
          <DateRangeFilter
            name="document_date"
            title=""
            from_name="date_from"
            to_name="date_to"
            onChangeFilter={onChangeDateRangeFilter}
            filters={filters}
          />
        </div>
      </div>
      <div className="py-4">
        <ReportHeader title="Balance Sheet" date={filters.date_to} />{" "}
      </div>
      <div>
        <GeneralReport data={data} />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentCompany: state.Company.currentCompany,
  };
};
export default connect(mapStateToProps, {})(CashFlow);
