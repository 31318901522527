import { combineReducers } from "redux";
import signup from "./signup/reducer";
import user from "./User/reducer";
import Company from "./Company/reducer";
import resetPassword from "./resetPassword/reducer";
import verifyAccount from "./verifyAccount/reducer";
import FileViewer from "./Layout/FileViewer/reducer";
import Helpers from "./Layout/helpers/reducer";
import Currencies from "./currencies/reducer";
import PaymentMethods from "./paymentMethods/reducer";
const rootReducer = combineReducers({
  signup,
  user,
  Company,
  resetPassword,
  verifyAccount,
  FileViewer,
  Currencies,
  PaymentMethods,
  Helpers,
});
export default rootReducer;
