const ActionButton = ({ Icon, children, className, ...props }) => {
  return (
    <button
      className={`px-8 py-2.5 rounded-md font-bold ${className}`}
      type="button"
      {...props}
    >
      <div className="flex flex-row items-center">
        <div className="px-1">{Icon && <Icon />}</div>
        <div>{children}</div>
      </div>
    </button>
  );
};

export default ActionButton;
