import { capitalizeFirstLetter } from "../../utils";

const STATUSES = [
  { value: "DRAFT", label: "Draft", color: "#6F7780" },
  { value: "PROCESSING", label: "Processing", color: "#00ACC4" },
  { value: "ACTION_REQUIRED", label: "Action Required", color: "#F44343" },
  { value: "COMPLETED", label: "Completed", color: "#48BB78" },
  { value: "PENDING_APPROVAL", label: "Pending Approval", color: "#F5831F" },
  {
    value: "CANCELLED",
    label: "Cancelled",
    color: "#E5E7EB",
    textColor: "#6F7780",
  },
  { value: "ISSUE_REPORTED", label: "Issue Reported", color: "#1A55AF" },
];

const PATMENT_STATUSES = [
  { value: "fully_paid", label: "Fully Paid", color: "#48BB78" },
  { value: "partially_paid", label: "Partially Paid", color: "#F5841F" },
  { value: "not_paid", label: "Not Paid", color: "#FF3355" },
];

const colorClientStatus = (value) => {
  const status = STATUSES.find(
    (paymentStatus) => paymentStatus.value === value
  );
  return {
    backgroundColor: status?.color || "#1A55AF",
    color: status?.textColor,
  };
};

const displayText = (value) => {
  return value
    ? capitalizeFirstLetter(value?.replace("_", " ").toLowerCase())
    : "";
};

const colorPaymentStatus = (value) => {
  const color = PATMENT_STATUSES.find(
    (paymentStatus) => paymentStatus.value === value
  )?.color;
  return { color: color };
};
export {
  STATUSES,
  PATMENT_STATUSES,
  colorClientStatus,
  colorPaymentStatus,
  displayText,
};
