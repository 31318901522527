import { Menu, Transition } from '@headlessui/react'
import { Fragment, useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import AddIcon from "../../../../assets/imgs/icons/add-2.svg";
import AddDocumentIcon from "../../../../assets/imgs/icons/add-document.svg";
import DescriptionIcon from "../../../../assets/imgs/icons/description.svg";
import MoneyTransferIcon from "../../../../assets/imgs/icons/moneyTransfer.svg";

export default function CreatingDropDown() {
    return (
        <div className="">
            <Menu as="div" className="relative inline-block text-left">
                <div>
                    <Menu.Button className="inline-flex w-full gap-2 justify-center rounded-md bg-primary2  px-5 py-2 text-md font-medium text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75">
                        <img className="add-2" src={AddIcon} />
                        Create
                    </Menu.Button>
                </div>
                <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                >
                    <Menu.Items className="absolute -left-20 mt-2 w-72 origin-top-right divide-y divide-gray-100 rounded-sm bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="p-4">
                            <Menu.Item>
                                <Link to={"/new-request/upload"} className='flex gap-4 border-b pb-3 text-black'>
                                    <img className="add-document" src={AddDocumentIcon} />
                                    Upload Document
                                </Link>
                            </Menu.Item>
                            <div>


                                <p className='text-grayColorText2 pt-4 pb-4'>Create Document </p>

                                <Menu.Item>
                                    <Link to={"/new-request/create-payment"} className='flex items-center gap-4 pb-3 text-black'>
                                        <img className="h-4" src={DescriptionIcon} />
                                        Payment
                                    </Link>
                                </Menu.Item>

                                <Menu.Item>
                                    <Link to={"/new-request/create-receipt"} className='flex gap-4 mt-3 pb-3 text-black'>
                                        <img className="add-document" src={AddDocumentIcon} />
                                        Receipts
                                    </Link>
                                </Menu.Item>

                                <Menu.Item>
                                    <Link to={"/new-request/create-salaries"} className='flex gap-4 mt-3 border-b pb-3 text-black'>
                                        <img className="add-document" src={AddDocumentIcon} />
                                        Salaries
                                    </Link>
                                </Menu.Item>


                                <p className='text-grayColorText2 pt-4 pb-4'> More </p>

                                {/* <Menu.Item>
                                    <Link to={"/new-request/upload"} className='flex items-center gap-4 pb-3 text-black'>
                                        <img className="h-4" src={DescriptionIcon} />
                                        Sales Invoice
                                    </Link>
                                </Menu.Item> */}

                                <Menu.Item>
                                    <Link to={"/money-transfer?"} className='flex gap-4 items-center mt-3 pb-3 text-black'>
                                        <img className="h-4" src={MoneyTransferIcon} />
                                        Money Transfer
                                    </Link>
                                </Menu.Item>

                                {/* <Menu.Item>
                                    <Link to={"/new-request/upload"} className='flex gap-4 mt-3 border-b pb-3 text-black'>
                                        <img className="add-document" src={AddDocumentIcon} />
                                        Payment ownership r
                                    </Link>
                                </Menu.Item> */}

                            </div>

                        </div>


                    </Menu.Items>
                </Transition>
            </Menu>
        </div>
    )
}

