import getHttpClient from "../api";
const client = getHttpClient();

const services = {
  getMoneyTransfers: async (companyId, filters) => {
    try {
      let data = { ...filters };
      if (data.page) {
        data.page = +data.page;
      }
      const response = await client.get(`/dms/money-transfer/${companyId}`, {
        params: data,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  confirmMoneyTransfer: async (documentId) => {
    try {
      const response = await client.post(
        `/dms/confirm-money-transfer/${documentId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },

  cancelMoneyTransfer: async (documentId) => {
    try {
      const response = await client.post(
        `/dms/cancel-money-transfer/${documentId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  delcineMoneyTransfer: async (documentId) => {
    try {
      const response = await client.post(
        `/dms/decline-money-transfer/${documentId}`
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  createMoneyTransfer: async (
    companyId,
    currency,
    transfer_date,
    to_user,
    from_payment_method,
    to_payment_method,
    amount,
    description,
    attachments
  ) => {
    try {
      let data = {
        currency: currency || null,
        transfer_date: transfer_date || null,
        to_user: to_user || null,
        from_payment_method: from_payment_method || null,
        to_payment_method: to_payment_method || null,
        amount: amount || 0.0,
        description: description || "",
        attachments: attachments || [],
      };
      const response = await client.post(
        `/dms/money-transfer/${companyId}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default services;
