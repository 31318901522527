import { useFormContext } from "react-hook-form";
import ErrorMessage from "../../ErrorMessage";
import ErrorInput from "../Error";
import _ from "lodash";
const MonetaryInput = ({
  name,
  currency,
  serverErrors,
  showCurrency = true,
  ...props
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="relative w-full">
      <div className="w-full">
        <input
          type="text"
          placeholder="00.00"
          className={` w-full mb-1 font-bold leading-5 placeholder-monetary	${
            _.get(errors, name)
              ? "text-errorColor border-errorColor focus:text-errorColor focus:border-errorColor focus:bg-white placeholder:text-errorColor"
              : ""
          }`}
          {...register(name, { defaultValue: 0.0 })}
          {...props}
        />
        {showCurrency && (
          <div className="absolute top-2 right-4 h-full font-bold text-lg">
            <div>{currency?.code || "AED"}</div>
          </div>
        )}
      </div>
      <ErrorInput name={name} errors={errors} serverErrors={serverErrors} />
    </div>
  );
};

export default MonetaryInput;
