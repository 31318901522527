import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import { connect } from "react-redux";
import _ from "lodash";
import VendorIcon from "../../../assets/imgs/icons/store.svg";
import CustomerIcon from "../../../assets/imgs/icons/customer.svg";
import ErrorMessage from "../../ErrorMessage";
import { useFormContext, useFieldArray } from "react-hook-form";
import arrowRightIcon from "../../../assets/imgs/icons/arrow_right.svg";
import CancelIcon from "../../../assets/imgs/icons/cancelSmall.svg";
import WarningOrangeIcon from "../../../assets/imgs/icons/warningOrange.svg";
import trashIcon from "../../../assets/imgs/icons/trash.svg";
import MonetaryInput from "../../Inputs/Monetary";
import CreatableSelectInput from "../../Inputs/CreatableSelect";
import useModal from "../../Modal/useModal";
import createNewVendor from "../../Modal/createNewVendor";
import UploadDocumentService from "../../../services/UploadDocument";
import { formatDate, getCookie } from "../../../utils";
import SelectFromList from "../../Modal/selectFromList";
import AmountInput from "../../Inputs/Amount";
import CheckBoxInput from "../../Inputs/CheckBox";
import { ToastifyError, ToastifySuccess } from "../../../toastify";
import { toast } from "react-toastify";
const PartnerWithOutstanding = forwardRef(
  (
    {
      type,
      error,
      currentCurrency,
      outstandingsOfPartner,
      setOutstandingsOfPartner,
      remainingAmount,
      setRemainingAmount,
      currentCompany,
    },
    ref
  ) => {
    const user = JSON.parse(getCookie("user"));
    const callPromptModalPartner = useModal(createNewVendor);
    const callPromptModalSelectOutstandings = useModal(SelectFromList);
    const [partners, setPartners] = useState([]);
    const [totalOutstandings, setTotalOutstandings] = useState([]);
    const [totalAddedAmount, setTotalAddedAmount] = useState([]);
    const [partner, setPartner] = useState(null);

    const methods = useFormContext();
    const outstandings = methods.watch("outstandings");
    const {
      fields: outstandingsFields,
      append: appendOutstandingField,
      insert: insertOutstandingField,
      remove: removeOutstandingField,
    } = useFieldArray({
      control: methods.control,
      name: "outstandings",
    });

    const paid_amount = methods.watch("paid_amount");
    const handleCreateNewPartner = async () => {
      try {
        const partners = await callPromptModalPartner({ type: type });
        setPartners(
          partners.map((partner) => {
            return { value: partner.id, label: partner.name };
          })
        );
      } catch (e) {
        console.error(e);
      }
    };
    const getCustomers = async () => {
      try {
        const customers = await UploadDocumentService.getCustomers(
          currentCompany.id
        );
        setPartners(
          customers.map((customer) => {
            return { value: customer.id, label: customer.name };
          })
        );
      } catch (error) {
        console.error(error);
      }
    };
    const getVendors = async () => {
      try {
        const vendors = await UploadDocumentService.getVendors(
          currentCompany.id
        );
        setPartners(
          vendors.map((vendor) => {
            return { value: vendor.id, label: vendor.name };
          })
        );
      } catch (error) {
        console.error(error);
      }
    };
    const selectOutstandings = async () => {
      try {
        const res = await callPromptModalSelectOutstandings({
          title: "Outstanding",
          options: outstandingsOfPartner,
          selectedList: outstandings.map((option) => option.outstandingId),
        });
        const totalOutstandings = res.reduce(
          (partialSum, a) => partialSum + parseFloat(a.amount),
          0
        );
        setTotalOutstandings(totalOutstandings.toFixed(2));
        const currentAmounts = outstandings.map((outstanding) => {
          return {
            id: outstanding.outstandingId,
            amount: outstanding.outstandingAmount,
          };
        });
        removeOutstandingField();
        res.forEach((r) => {
          appendOutstandingField({
            outstandingId: r.id,
            name: r.name,
            amount_formated: r.amount_formated,
            amount: r.amount,
            outstandingAmount:
              currentAmounts.find((outstanding) => r.id === outstanding.id)
                ?.amount ||
              (res.length === 1
                ? Math.min(r.amount, paid_amount)
                : paid_amount >= totalOutstandings
                ? r.amount
                : ""),
          });
        });
      } catch (e) {
        console.error(e);
      }
    };
    const getOutstandingsOfCustomer = async (customerId) => {
      try {
        const outstandings =
          await UploadDocumentService.getOutstandingsOfCustomer(customerId);
        if (outstandings.length)
          outstandings.unshift({
            name: "Outstanding Invoices",
            type: "category",
          });
        setOutstandingsOfPartner(outstandings);
      } catch (error) {
        console.error(error);
      }
    };
    const getOutstandingsOfVendor = async (vendorId) => {
      try {
        const outstandings =
          await UploadDocumentService.getOutstandingsOfVendor(vendorId);
        if (outstandings.length)
          outstandings.unshift({ name: "Outstanding Bills", type: "category" });
        setOutstandingsOfPartner(outstandings);
      } catch (error) {
        console.error(error);
      }
    };
    useEffect(() => {
      if (type === "vendor") {
        getVendors();
      } else {
        getCustomers();
      }
    }, []);
    useEffect(() => {
      removeOutstandingField();
      if (partner) {
        if (type === "vendor") {
          getOutstandingsOfVendor(partner);
        } else {
          getOutstandingsOfCustomer(partner);
        }
      } else {
        setOutstandingsOfPartner([]);
      }
      setTotalOutstandings(0);
      setRemainingAmount(0);
      removeOutstandingField();
    }, [partner]);
    useEffect(() => {
      setRemainingAmount(
        parseFloat(
          parseFloat(paid_amount) - parseFloat(totalAddedAmount)
        ).toFixed(2)
      );
    }, [paid_amount, totalAddedAmount]);

    useEffect(() => {
      const subscription = methods.watch((value, { name, type }) => {
        setTotalAddedAmount(
          value.outstandings.reduce(
            (partialSum, a) => partialSum + parseFloat(a.outstandingAmount),
            0
          )
        );
        const totalOutstandings = value.outstandings.reduce(
          (partialSum, a) => partialSum + parseFloat(a.amount),
          0
        );

        methods.trigger("amountOfOutstandings");
        methods.trigger("outstandings");
        setTotalOutstandings(totalOutstandings.toFixed(2));
        setPartner(value.partner);
      });
      return () => subscription.unsubscribe();
    }, [methods.watch]);

    useImperativeHandle(ref, () => ({
      selectOutstandingsFromOutside() {
        selectOutstandings();
      },
    }));

    return (
      <div>
        <div className="flex flex-row items-center mb-4">
          <div className="w-6 h-6">
            <img src={type === "vendor" ? VendorIcon : CustomerIcon} alt="" />
          </div>
          <div className="ml-3">
            {type === "vendor" ? "Vendor" : "Customer"}
          </div>
        </div>
        <div>
          <CreatableSelectInput
            options={partners}
            name="partner"
            placeholder={`Select ${type}`}
            serverErrors={error}
            title={`Add a new ${type}`}
            onCreate={handleCreateNewPartner}
          />
        </div>
        {outstandingsOfPartner.length > 0 && (
          <div>
            <div className="p-4 mt-2 bg-arrowTop bg-opacity-10">
              <div className="flex flex-row items-center justify-between cursor-pointer">
                <div
                  className="flex flex-row items-center text-sm font-bold font-roboto"
                  onClick={selectOutstandings}
                >
                  <div>Outstanding amounts from this {type}</div>
                  <div className="flex justify-center px-1 ml-1 text-sm font-normal text-white rounded-xl min-w-2 bg-arrowRigh font-roboto">
                    {outstandings.length
                      ? `${outstandings.length} of ${
                          outstandingsOfPartner.length - 1
                        }`
                      : outstandingsOfPartner.length - 1}
                  </div>
                </div>
              </div>
            </div>
            {totalOutstandings > paid_amount &&
              !outstandings.every(
                (outstanding) => outstanding.outstandingAmount
              ) && (
                <div className="flex flex-row items-start p-4 mt-2 bg-arrowLeft bg-opacity-10">
                  <img src={WarningOrangeIcon} alt="" />
                  <div className="ml-3 text-sm font-medium">
                    The {type == "vendor" ? "paid" : "received"} amount is less
                    than the total amount for the{" "}
                    {type == "vendor" ? "bills" : "invoices"} selected, please
                    add the {type == "vendor" ? "paid" : "received"} amount for
                    each {type == "vendor" ? "bill" : "invoice"}
                  </div>
                </div>
              )}

            {methods.formState?.errors?.amountOfOutstandings && (
              <ErrorMessage>
                <div className="flex justify-center">
                  {methods.formState?.errors?.amountOfOutstandings?.message}
                </div>
              </ErrorMessage>
            )}
            {outstandingsFields?.map((outstanding, i) => (
              <div className="p-2 mt-1 text-sm font-roboto">
                <div className="flex flex-row items-center justify-between">
                  <div className="flex flex-row items-center">
                    <input
                      {...methods.register(`outstandings.${i}.outstandingId`)}
                      type="text"
                      className="hidden"
                    />
                    <div className="mr-2">
                      <img
                        src={CancelIcon}
                        alt=""
                        onClick={() => {
                          removeOutstandingField(i);
                          ToastifyError(
                            <div className="flex flex-row justify-between w-full">
                              <div>
                                The {type == "vendor" ? "bill" : "invoice"} was
                                deleted
                              </div>
                              <div className="flex flex-row">
                                <div
                                  className="cursor-pointer"
                                  onClick={() => {
                                    toast.dismiss();
                                    insertOutstandingField(i, outstanding);
                                  }}
                                >
                                  undo
                                </div>
                                <div className="mx-2">|</div>
                              </div>
                            </div>,
                            {
                              icon: <img src={trashIcon} alt="" />,
                            }
                          );
                        }}
                      />
                    </div>
                    <div>
                      <div className="">{outstanding.name}</div>

                      <div className="mt-1 font-bold">
                        {outstanding.amount_formated}
                      </div>
                    </div>
                  </div>
                  <div className="w-1/3">
                    <AmountInput
                      name={`outstandings.${i}.outstandingAmount`}
                      currency={null}
                      placeholder="Add amount"
                    />
                  </div>
                </div>
                {_.get(
                  methods.formState?.errors,
                  `outstandings.${i}.amountOfOutstanding`
                ) && (
                  <ErrorMessage>
                    <div className="flex justify-center">
                      {_.get(
                        methods.formState?.errors,
                        `outstandings.${i}.amountOfOutstanding.message`
                      )}
                    </div>
                  </ErrorMessage>
                )}
              </div>
            ))}
            {outstandingsFields.length > 0 && (
              <div>
                <div className="mt-2 bg-bgGray">
                  <div className="px-3">
                    <div className="flex flex-row justify-between py-3 border-b border-borderColor">
                      <div className="text-sm">Selected total</div>
                      <div className="text-sm font-bold">
                        {totalOutstandings} {currentCurrency?.code}
                      </div>
                    </div>
                  </div>
                  <div className="px-3">
                    <div className="flex flex-row justify-between py-3 border-b border-borderColor">
                      <div className="text-sm">You have added amount</div>
                      <div className="text-sm font-bold">
                        {paid_amount || 0.0} {currentCurrency?.code}
                      </div>
                    </div>
                  </div>
                  {remainingAmount > 0 && (
                    <div>
                      <div className="px-3">
                        <div className="flex flex-row justify-between py-3 border-b border-borderColor">
                          <div className="text-sm">Remaining Amount</div>
                          <div className="text-sm font-bold">
                            {remainingAmount} {currentCurrency?.code}
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  {remainingAmount > 0 && (
                    <div className="p-2 mt-1 text-sm">
                      <CheckBoxInput
                        name="addRemainingCredit"
                        serverErrors={error}
                        label={`Add the remaining amount as a credit to the ${type}`}
                      />
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
);

export default PartnerWithOutstanding;
