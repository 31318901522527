import { colorClientStatus, displayText } from "./documentUtils";

const ClientStatus = ({ status }) => {
  return (
    <div
      style={colorClientStatus(status)}
      className="px-2 py-2 text-xs font-bold text-white max-w-max rounded-md leading-3"
    >
      {displayText(status)}
    </div>
  );
};

export default ClientStatus;
