import { useEffect, useState } from "react";
import { connect } from "react-redux";
import AskResetPasswordForm from "../../components/Forms/AskResetPassword";
import ResetPasswordForm from "../../components/Forms/ResetPassword";
import ContactToResetPasswordForm from "../../components/Forms/ContactToResetPassword";
import CodeVerificationToResetPassword from "../../components/Forms/CodeVerificationToResetPassword";
import Logo from "../../assets/imgs/logoTheaccounter.png";
import { setStep } from "../../store/resetPassword/actions";
const ResetPassword = ({ step, setStep }) => {
  useEffect(() => {
    return () => {
      setStep(0);
    };
  }, []);
  const handleStep = () => {
    switch (step) {
      case 0:
        return <AskResetPasswordForm />;
      case 1:
        return <ContactToResetPasswordForm />;
      case 2:
        return <CodeVerificationToResetPassword />;
      case 3:
        return <ResetPasswordForm />;

      default:
    }
  };
  return (
    <section className="flex flex-col mx-16 pt-12 h-screen justify-between	">
      <div className="flex flex-row">
        <img src={Logo} alt="" />
      </div>
      <div className="flex flex-col items-center justify-center mt-6 mb-auto">
        <div
          className={`w-425 flex flex-col  ${
            step === 2 ? "items-center " : ""
          } p-1`}
        >
          {handleStep()}
        </div>
      </div>

      <div className="w-full justify-self-end mt-6 mb-1.875">
        <div className="text-center">
          Copyright © 2021 The Accounter - All Rights Reserved.
        </div>
      </div>
    </section>
  );
};

const mapStateToProps = (state) => {
  return {
    step: state.resetPassword.step,
  };
};
export default connect(mapStateToProps, { setStep })(ResetPassword);
