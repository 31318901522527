import { NavLink, Outlet } from "react-router-dom";

const MENU = [
  {
    key: "balanceSheet",
    value: "Balance Sheet",
    link: "/reports/balancesheet",
  },
  {
    key: "profitandLossStatement",
    value: "Profit and Loss Statement",
    link: "/reports/profitandLossStatement",
  },
  {
    key: "cashFlowstatement",
    value: "Cash Flow statement",
    link: "/reports/cashFlowstatement",
  },
  {
    key: "inventoryReport",
    value: "Inventory Report",
    link: "/reports/inventoryReport",
  },
  {
    key: "taxReport",
    value: "Tax Report",
    link: "/reports/taxReport",
  },
  {
    key: "agedReceivable",
    value: "Aged Receivable",
    link: "/reports/agedReceivable",
  },
  {
    key: "agedPayable",
    value: "Aged Payable",
    link: "/reports/agedPayable",
  },
  {
    key: "assetReport",
    value: "Asset Report",
    link: "/reports/assetReport",
  },
  {
    key: "moneyStatement",
    value: "Money Statement",
    link: "/reports/moneyStatement",
  },
  {
    key: "statementofAccount",
    value: "Statement of Account",
    link: "/reports/statementofAccount",
  },
];

const ReportsLayout = () => {
  return (
    <div className="flex w-full h-screen">
      <aside className="flex flex-col items-center px-8 bg-white shadow whitespace-nowrap ">
        <div className="flex flex-col w-full mt-4 cursor-pointer ">
          <div className="py-2 text-xl font-bold">Reports</div>
          <nav>
            {MENU.map(({ link, value, Icon }, i) => (
              <NavLink
                key={i}
                to={link}
                className={({ isActive }) =>
                  "flex items-center py-4 px-2 text-black" +
                  (isActive
                    ? " bg-backgroundBlue border-l-4 border-arrowTop menu-is-active font-bold"
                    : "")
                }
              >
                <div className="flex flex-row">
                  {Icon && <Icon />}
                  <div className="ml-2">{value}</div>
                </div>
              </NavLink>
            ))}
          </nav>
        </div>
      </aside>
      <div className="w-full ml-auto">
        <div className="container">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default ReportsLayout;
