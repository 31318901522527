import { useEffect, useRef, useState } from "react";
import SearchIcon from "../../../assets/imgs/icons/search.svg";
import arrowDropDown from "../../../assets/imgs/icons/arrow_drop_down.svg";

const MultiSelectFilter = ({
  name,
  onChangeFilter,
  title,
  options,
  labelClassName,
  useBgColor,
  useDotColor,
  filters,
}) => {
  const [isOpen, setIsOpen] = useState();
  const [countSelected, setCountSelected] = useState(0);
  const [firstName, setFirstName] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [virtualOptions, setVirtualOptions] = useState([]);
  const [showedOptions, setShowedOptions] = useState([]);
  const ref = useRef(null);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const onClickOutside = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);

  const onChange = (e) => {
    const selected = virtualOptions
      .filter((option) => option.checked)
      .map((option) => option.value);
    if (onChangeFilter) onChangeFilter(name, selected.join(",").toLowerCase());
  };
  const searchOption = (e) => {
    const key = e.target.value;
    setSearchValue(key);
    setShowedOptions(
      virtualOptions.filter((option) =>
        option.label.toLowerCase().includes(key.toLowerCase())
      )
    );
  };

  const toggleOption = (e, optionId) => {
    setVirtualOptions(
      virtualOptions.map((option) => {
        return option.value === optionId
          ? { ...option, checked: !option.checked }
          : { ...option };
      })
    );
  };
  const handleClearSelection = (e) => {
    setVirtualOptions(
      virtualOptions.map((option) => {
        return { ...option, checked: false };
      })
    );
    setIsOpen(false);
  };
  useEffect(() => {
    setShowedOptions(virtualOptions);
    setSearchValue("");
    const checkedList = virtualOptions.filter((option) => option.checked);

    if (checkedList.length) {
      setFirstName(checkedList[0].label);
      setCountSelected(checkedList.length);
    } else {
      setCountSelected(0);
    }
    onChange();
  }, [virtualOptions]);

  useEffect(() => {
    const selected = filters[name]?.split(",");
    setVirtualOptions(
      options.map((option) => {
        return {
          ...option,
          checked: selected?.includes(option?.value?.toString()?.toLowerCase()),
        };
      })
    );
  }, [options]);
  return (
    <div className="relative flex p-2">
      <div
        onClick={toggleOpen}
        className={`px-3 py-2 rounded-md cursor-pointer  min-w-max ${
          countSelected > 0 ? "border-black border-2" : "border"
        }`}
      >
        <div className="flex flex-row items-center">
          <div className="flex flex-row items-center px-2 text-grayColorText2">
            {title}
            {countSelected > 0 && (
              <div className="ml-1 font-bold">: {firstName}</div>
            )}{" "}
            {countSelected > 1 && (
              <div className="flex justify-center items-center ml-1 text-sm  text-black rounded-lg w-7 h-4 bg-grayBorder font-roboto">{`+${
                countSelected - 1
              }`}</div>
            )}
          </div>
          <img src={arrowDropDown} />
        </div>
      </div>
      {isOpen && (
        <div
          className="absolute z-20 pt-2 overflow-hidden bg-white rounded-md shadow-dropdownMenu"
          ref={ref}
          style={{ width: "20rem", left: 0, top: 50 }}
        >
          <div className="flex flex-row w-full h-full px-2 border-b-2">
            <img src={SearchIcon} />
            <input
              className="w-full px-2 py-2 ml-2 border-0 focus:outline-none"
              placeholder="Search"
              value={searchValue}
              onChange={searchOption}
            />
          </div>
          <div className="overflow-auto max-h-60">
            {showedOptions.length === 0 && (
              <div className="py-4 text-sm font-roboto">
                <div className="flex flex-row justify-center">
                  <div>No Option</div>
                </div>
              </div>
            )}
            {showedOptions.length > 0 && (
              <div>
                {showedOptions.map((option) => (
                  <div className="py-3 text-sm cursor-pointer font-roboto">
                    <div
                      className="flex flex-row items-center"
                      onClick={(e) => {
                        toggleOption(e, option.value);
                      }}
                    >
                      <div className="mx-4">
                        <input
                          type="checkbox"
                          checked={option.checked}
                          onChange={(e) => {
                            return toggleOption(e, option.value);
                          }}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                      </div>

                      {option?.icon && (
                        <div className="mr-2">
                          <img src={option?.icon} alt="" />
                        </div>
                      )}
                      {useDotColor && (
                        <span
                          className="w-2 h-2 mr-2 bg-gray-500 rounded-full"
                          style={{ backgroundColor: option.color }}
                        ></span>
                      )}
                      <div
                        className={labelClassName}
                        style={
                          useBgColor
                            ? {
                                backgroundColor: option.color,
                                color: option.textColor || "white",
                              }
                            : {}
                        }
                      >
                        {option.label}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>

          {showedOptions.length > 0 && (
            <div
              className="flex justify-center items-center py-3 border-t-2 cursor-pointer text-grayColorText2"
              onClick={handleClearSelection}
            >
              Clear Selection
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MultiSelectFilter;
