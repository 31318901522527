// import authImage from "../../assets/imgs/auth-img.png";
import Logo from "../../components/Logo";
import LoginForm from "../../components/Forms/Login";
const Login = () => {
  return (
    <section className="flex flex-row h-screen w-full p-6">
      <div className="w-4/6 hidden md:block">
        <img
          src="/images/auth-img.png"
          alt=""
          className="w-full h-full object-fill"
        />
        <div className="relative">
          <div className="px-14 py-7 rounded-3xl w-full absolute bottom-0 left-0 bg-arrowBottom text-white text-2xl font-poppins leading-loose font-bold opacity-90">
            Full-Fledged Accounting & VAT <br />
            reporting... In a Snap!
          </div>
        </div>
      </div>
      <div className="bg-white w-full h-full flex flex-col items-center">
        <div className="flex flex-col mt-auto mb-auto">
          <div className="flex flex-col items-center justify-center w-425">
            <div className="w-full flex flex-col p-1">
              <div className="flex justify-center">
                <Logo />
              </div>
            </div>

            <p className="mt-4 text-black text-2xl font-bold leading-9 font-poppins">
              It's great to have you here!
            </p>
            <div className="mt-2 text-sm font-light text-grayColor leading-5 font-roboto ">
              You can login to access your account.
            </div>
            <div className="w-full mt-8">
              <LoginForm />
            </div>
          </div>
        </div>

        <div className="w-full mt-6 justify-self-end">
          <div className="text-center">
            Copyright © 2021 The Accounter - All Rights Reserved.
          </div>
        </div>
      </div>
    </section>
  );
};

export default Login;
