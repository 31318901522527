import { connect } from "react-redux";
import { openFileViewer } from "../../store/Layout/FileViewer/actions";
const DocumentAttachments = ({ attachments, openFileViewer }) => {
  return (
    <div>
      <div className="">Attachments ({attachments?.length})</div>
      <div className="flex flex-row flex-wrap items-center mt-4">
        {attachments.map((attachment) => {
          const file = {
            ...attachment,
            link: attachment.url,
            fileOnServer: { file: attachment.cors_url },
          };
          return (
            <div className="py-2">
              <div
                className="cursor-pointer"
                onClick={() => {
                  openFileViewer(file);
                }}
              >
                <img
                  alt="attachment"
                  src={attachment.thumbnails}
                  className="w-40 h-40"
                />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, { openFileViewer })(
  DocumentAttachments
);
