const SolidButton = ({ children, className, ...props }) => {
  return (
    <button
      className={`w-full px-4 py-3 bg-arrowTop rounded text-sm font-bold text-gray-50  disabled:opacity-50 ${className}`}
      type="button"
      {...props}
    >
      {children}
    </button>
  );
};

export default SolidButton;
