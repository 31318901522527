import { connect } from "react-redux";

import SubmitButton from "../../Buttons/Submit";
import CodeVerificationInput from "../../Inputs/CodeVerification";
import { setToken, goNext, goPrev } from "../../../store/resetPassword/actions";
import Timer from "../../Timer";
import { useEffect, useState } from "react";
import ResetPasswordService from "../../../services/ResetPassword";
import ErrorMessage from "../../ErrorMessage";
import { formatContactDisplay } from "../../../utils";

const CodeVerificationToResetPassword = ({
  resetPasswordContact,
  resetPasswordType,
  goNext,
  setToken,
}) => {
  const [canResend, setCanResend] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [minutes, setMinutes] = useState();
  const [code, setCode] = useState("");
  const [error, setError] = useState("");
  const [errorResend, setErrorResend] = useState("");
  const [resetInput, setresetInput] = useState(false);
  const handleVerify = async () => {
    try {
      setIsSubmitting(true);
      setErrorResend(false);
      await ResetPasswordService.verifyCode(resetPasswordContact, code);
      setToken(code);
      goNext();
    } catch (err) {
      setError(err);
    } finally {
      setIsSubmitting(false);
    }
  };
  const resend = async () => {
    try {
      if (resetPasswordType === "email") {
        const { email } = await ResetPasswordService.resendVerifyEmail(
          resetPasswordContact
        );
      } else if (resetPasswordType === "mobile") {
        const { mobile } = await ResetPasswordService.resendVerifyMobile(
          resetPasswordContact
        );
      }
      setCanResend(false);
      setErrorResend("");
      setresetInput(true);
      setError("");
    } catch (err) {
      setErrorResend(err);
    }
  };
  useEffect(() => {
    if (!canResend) {
      const time = new Date();
      time.setSeconds(time.getSeconds() + 120); // 2 minutes timer
      setMinutes(time);
    }
  }, [canResend]);
  const handleOnChangeCode = (code) => {
    setCode(code);
  };
  return (
    <div>
      <div className="w-276 mt-5">
        <div className="font-bold text-2xl leading-9 font-poppins">
          Verification Code
        </div>
        <div className="mb-10 text-sm text-gray-500 leading-5">
          We sent a code to your email
        </div>
        <CodeVerificationInput
          name="token"
          length={4}
          onChange={handleOnChangeCode}
          reset={resetInput}
          setReset={setresetInput}
          serverErrors={error}
        />
        <div className="mt-4 text-sm text-gray-500 leading-5 mb-7">
          Please enter the code we send to your phone number{" "}
          {formatContactDisplay(resetPasswordContact)}
        </div>
        {error?.detail && <ErrorMessage>{error.detail}</ErrorMessage>}
        <div className="">
          <SubmitButton
            onClick={handleVerify}
            disabled={code.length !== 4 || isSubmitting}
          >
            Confirm
          </SubmitButton>
        </div>
        {!canResend && minutes && (
          <div className="mt-8">
            <Timer
              expiryTimestamp={minutes}
              onExpire={() => {
                setCanResend(true);
                setMinutes(null);
                setErrorResend("");
              }}
            />
          </div>
        )}
        {canResend && (
          <div>
            <div className="mt-8 font-roboto leading-5 ">
              I didn't receive a code
            </div>
            <div className="cursor-pointer text-arrowRigh" onClick={resend}>
              Resend
            </div>
            {errorResend?.detail && (
              <ErrorMessage>{errorResend.detail}</ErrorMessage>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    resetPasswordContact: state.resetPassword.resetPasswordContact,
    resetPasswordType: state.resetPassword.resetPasswordType,
  };
};
export default connect(mapStateToProps, {
  goNext,
  goPrev,
  setToken,
})(CodeVerificationToResetPassword);
