import React, { useState, useEffect, useCallback } from "react";
import ArrowBottomIcon from "../../assets/imgs/icons/arrow_drop_down_blue.svg";

const DropDownContent = ({ children, title, className }) => {
  const [show, setShow] = useState(0);
  return (
    <div className={`w-full flex ${className}`}>
      <div className="w-full">
        <div className="bg-white w-full">
          <button
            type="button"
            className="w-full  text-left  border-b border-b-gray-200  mb-6"
            onClick={() => {
              setShow(!show);
            }}
          >
            <div className="flex items-center w-full font-roboto font-bold leading-4 mb-4">
              <span>{title}</span>
              <span className="ml-4">
                <img
                  className={show ? "transform rotate-180" : ""}
                  src={ArrowBottomIcon}
                  alt=""
                />
              </span>
            </div>
          </button>

          <div
            className="relative overflow-hidden transition-all duration-500 w-full"
            style={{ maxHeight: show ? "400px" : "0px" }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DropDownContent;
