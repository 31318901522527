import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import SubmitButton from "../Buttons/Submit";
import ErrorMessage from "../ErrorMessage";
import CancelIcon from "../../assets/imgs/icons/cancel.svg";
import { formatDate, getCookie } from "../../utils";
import CurrencyService from "../../services/Currency";
import { setBaseCurrencies } from "../../store/currencies/actions";
import { setBasePaymentMethods } from "../../store/paymentMethods/actions";
import DateInput from "../Inputs/Date";
import MonetaryWithCurrencyInput from "../Inputs/MonetaryWithCurrency";
import TextareaInput from "../Inputs/TextArea";
import UploadFiles from "../UploadFiles";
import UploadDocumentService from "../../services/UploadDocument";
import MoneyTranferService from "../../services/MoneyTransfers";
import BankIcon from "../../assets/imgs/icons/bank.svg";
import CashIcon from "../../assets/imgs/icons/moneyCash.svg";
import SelectInput from "../Inputs/Select";
import { trackPromise } from "react-promise-tracker";
import useModal from "./useModal";
import SuccessUploadDocument from "./successUploadDocument";
import ConfirmCancel from "./ConfirmDelete";
import { useDispatch } from "react-redux";
import { refreshCurrentPage } from "../../store/Layout/helpers/actions";

const schema = yup
  .object({
    amount: yup
      .number()
      .required("This field is required")
      .transform((val, value) => {
        return value === "" ? 0.0 : val;
      })
      .typeError("Amount must be a number")
      .test("format", "Number with maximum 2 decimal point", (value) => {
        return /^-*[0-9,]+([.][0-9]{0,2})?$/.test(value || 0.0);
      })
      .max("100000000")
      .test("Is Zero?", "Amount can’t be zero", (value) => value !== 0)
      .positive("Amount can’t be a negative number"),
    description: yup
      .string()
      .transform((value) => (value ? value : null))
      .nullable()
      .min(10)
      .max(160),
    to_user: yup
      .number()
      .nullable()
      .required("This field is required")
      .positive("You should select user"),
    from_payment_method: yup
      .number()
      .nullable()
      .required("This field is required")
      .positive("You should select payment method"),
    to_payment_method: yup
      .number()
      .nullable()
      .required("This field is required")
      .positive("You should select payment method"),
    transfer_date: yup
      .string()
      .transform((value) => (value ? value : ""))
      .required("This field is required"),
  })
  .required();
const CreateNewMoneyTransfer = ({
  baseCurrencies,
  basePaymentMethods,
  setBaseCurrencies,
  setBasePaymentMethods,
  currentCompany,
  onResolve,
  onReject,
}) => {
  const callPromptSuccessUploadDocument = useModal(SuccessUploadDocument);
  const callPromptModalConfrimCancel = useModal(ConfirmCancel);
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [fromPaymentMethod, setFromPaymentMethod] = useState([]);
  const [paymentMethodsOfBeneficiary, setPaymentMethodsOfBeneficiary] =
    useState([]);
  const [files, setFiles] = React.useState([]);
  const [isUploading, setIsUploading] = React.useState(false);
  const user = JSON.parse(getCookie("user"));
  const [currencies, setCurrencies] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentCurrency, setCurrentCurrency] = useState(null);
  const [error, setError] = useState();
  const backRef = useRef(null);
  const dispatch = useDispatch();
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      transfer_date: new Date(),
    },
  });
  const onSubmit = async (data) => {
    try {
      const companyId = currentCompany.id;
      const currency = currentCurrency.id;
      const transfer_date = formatDate(data.transfer_date);
      const to_user = data.to_user;
      const from_payment_method = data.from_payment_method;
      const to_payment_method = data.to_payment_method;
      const description = data.description;
      const amount = data.amount;
      const attachmentsDocument = files
        .filter((attachment) => attachment?.fileOnServer?.id)
        .map((attachment) => attachment?.fileOnServer?.id);

      const submit = async () => {
        const document = await MoneyTranferService.createMoneyTransfer(
          companyId,
          currency,
          transfer_date,
          to_user,
          from_payment_method,
          to_payment_method,
          amount,
          description,
          attachmentsDocument
        );
        return document;
      };
      await trackPromise(submit());
      console.log(from_payment_method);
      let selectedPaymentMethodBalance = parseFloat(fromPaymentMethod?.balance);
      selectedPaymentMethodBalance = isNaN(selectedPaymentMethodBalance)
        ? null
        : selectedPaymentMethodBalance;

      await callPromptSuccessUploadDocument({
        time: 5000,
        message: "The Request was processed successfully",
        title: "Successful!",
        availableBalance:
          selectedPaymentMethodBalance !== null
            ? `${selectedPaymentMethodBalance - amount} ${
                fromPaymentMethod?.currency.code
              }`
            : null,
      });
      dispatch(refreshCurrentPage());
      onResolve();
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const cancel = async () => {
    try {
      const res = await callPromptModalConfrimCancel({
        title: "Are you sure?",
        message: `Closing this window without clicking "Send" will delete the content you’ve added to this transfer.`,
        cancelButtonTitle: "Go Back",
        submitButtonTitle: "Discard Changes",
      });
      if (res) {
        onReject();
      }
    } catch (error) {}
  };
  const fromPaymentMethodId = methods.watch("from_payment_method");
  const amount = methods.watch("amount");
  useEffect(() => {
    const fromPaymentMethod = basePaymentMethods.find(
      (payment) => payment.id === fromPaymentMethodId
    );
    setFromPaymentMethod(fromPaymentMethod);
  }, [fromPaymentMethodId]);

  const onChangeCurrency = (value) => {
    methods.setValue("currency", value);
    setCurrentCurrency(
      baseCurrencies.find((currency) => currency.id === value)
    );
  };
  const getCurrencies = async () => {
    try {
      const currencies = await CurrencyService.getCurrencies(currentCompany.id);
      setBaseCurrencies(currencies);
    } catch (error) {
      debugger;
      console.error(error);
    }
  };

  const getPaymentMethods = async () => {
    try {
      let paymentMethods = await UploadDocumentService.getPaymentMethods(
        currentCompany.id,
        user.id
      );

      paymentMethods = paymentMethods.map((paymentMethod) => {
        return {
          ...paymentMethod,
          currency: baseCurrencies.find(
            (currency) => currency.id === paymentMethod.currency
          ),
        };
      });
      setBasePaymentMethods(paymentMethods);
      setPaymentMethods(
        paymentMethods.map((paymentMethod) => {
          return {
            value: paymentMethod.id,
            label: paymentMethod.name,
            text: `${paymentMethod.balance} ${paymentMethod?.currency?.code}`,
            icon: paymentMethod.type === "cash" ? CashIcon : BankIcon,
          };
        })
      );
    } catch (error) {
      debugger;
      console.error(error);
    }
  };

  const getPaymentMethodsOfBeneficiary = async (userId) => {
    try {
      let paymentMethods = await UploadDocumentService.getPaymentMethods(
        currentCompany.id,
        userId
      );

      paymentMethods = paymentMethods.map((paymentMethod) => {
        return {
          ...paymentMethod,
          currency: baseCurrencies.find(
            (currency) => currency.id === paymentMethod.currency
          ),
        };
      });
      setPaymentMethodsOfBeneficiary(
        paymentMethods.map((paymentMethod) => {
          return {
            value: paymentMethod.id,
            label: paymentMethod.name,
            text: `${paymentMethod.balance} ${paymentMethod?.currency?.code}`,
            icon: paymentMethod.type === "cash" ? CashIcon : BankIcon,
          };
        })
      );
    } catch (error) {
      debugger;
      console.error(error);
    }
  };

  const getUsers = async () => {
    try {
      const users = await UploadDocumentService.getAssigneeOfCompany(
        currentCompany.id
      );
      setUsers(
        users.map((creator) => {
          return { value: creator.id, label: creator.name };
        })
      );
    } catch (error) {
      debugger;
      return document;
    }
  };
  useEffect(() => {
    getCurrencies();
    getPaymentMethods();
    getUsers();
  }, []);
  const beneficiaryId = methods.watch("to_user");

  useEffect(() => {
    if (beneficiaryId) {
      getPaymentMethodsOfBeneficiary(beneficiaryId);
    } else {
      setPaymentMethodsOfBeneficiary([]);
    }
  }, [beneficiaryId]);
  useEffect(() => {
    setCurrencies(
      baseCurrencies.map((currency) => {
        return {
          value: currency.id,
          label: `${currency.code} - ${currency.name}`,
          icon: currency.flag,
        };
      })
    );

    methods.setValue(
      "currency",
      baseCurrencies.find((currency) => currency.default)?.id
    );
    setCurrentCurrency(baseCurrencies.find((currency) => currency.default));
  }, [baseCurrencies]);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(onSubmit)}
        name="createMoneyTransfer"
      >
        <div
          ref={backRef}
          isOpen={backRef}
          className="fixed inset-0 overflow-y-auto"
          style={{ zIndex: 1000 }}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span className="fixed inset-0 overflow-y-auto" aria-hidden="true">
              &#8203;
            </span>
            <div className="inline-block overflow-hidden text-left align-bottom bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full">
              <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div className="flex flex-row justify-between pb-2 border-b">
                  <h3
                    className="text-xl font-bold text-gray-900 leading-7 font-poppins"
                    id="modal-title"
                  >
                    Money Transfer
                  </h3>
                  <div className="cursor-pointer" onClick={cancel}>
                    <img src={CancelIcon} alt="" />
                  </div>
                </div>
                <div className="w-full px-4 overflow-auto sm:flex sm:items-start max-h-552">
                  <div className="w-full">
                    <div>
                      {error?.detail && (
                        <ErrorMessage>{error.detail}</ErrorMessage>
                      )}
                      <div className="mt-4">
                        <div>Transfer Date</div>
                        <DateInput
                          name="transfer_date"
                          maxDate={new Date()}
                          serverErrors={error}
                          placeholderText="Transfer Date"
                        />
                      </div>

                      <div className="mt-4">
                        <div>Transfer From</div>
                        <SelectInput
                          options={paymentMethods}
                          name="from_payment_method"
                          placeholder="Select Payment Method"
                          serverErrors={error}
                          menuPortalTarget={backRef.current}
                        />
                      </div>

                      <div className="mt-4">
                        <div>Transfer To Beneficiary</div>
                        <SelectInput
                          options={users}
                          name="to_user"
                          placeholder="Select Beneficiary"
                          serverErrors={error}
                          menuPortalTarget={backRef.current}
                        />
                      </div>

                      <div className="mt-4">
                        <div>Beneficiary Payment Method</div>
                        <SelectInput
                          options={paymentMethodsOfBeneficiary}
                          name="to_payment_method"
                          placeholder="Select Payment Method"
                          serverErrors={error}
                          menuPortalTarget={backRef.current}
                        />
                      </div>

                      <div className="mt-4">
                        <div>Transfer From</div>
                        <MonetaryWithCurrencyInput
                          name="amount"
                          currency={currentCurrency}
                          currencies={currencies}
                          onChangeCurrency={onChangeCurrency}
                          serverErrors={error}
                        />
                        {amount && (
                          <div className="py-2">
                            <div className="flex flex-row justify-between p-2">
                              <div>Balance Before Transfer</div>
                              <div>
                                {`${fromPaymentMethod.balance} ${
                                  isNaN(fromPaymentMethod.balance)
                                    ? ""
                                    : fromPaymentMethod?.currency?.code
                                }`}
                              </div>
                            </div>

                            <div className="flex flex-row justify-between p-2 bg-bgGray2">
                              <div>Transfer Amount</div>
                              <div>{`${amount} ${currentCurrency.code}`}</div>
                            </div>

                            <div className="flex flex-row justify-between p-2">
                              <div>Balance After Transfer</div>
                              <div>
                                {`${
                                  isNaN(fromPaymentMethod.balance)
                                    ? fromPaymentMethod.balance
                                    : fromPaymentMethod.balance - amount
                                } ${
                                  isNaN(fromPaymentMethod.balance)
                                    ? ""
                                    : fromPaymentMethod?.currency?.code
                                }`}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      <div className="mt-4">
                        <div>Description</div>
                        <TextareaInput
                          rows="4"
                          id="description"
                          name={`description`}
                          placeholder="What is this transfer for?"
                          serverErrors={error}
                        />
                      </div>
                      <div className="px-4 mt-4">
                        <UploadFiles
                          files={files}
                          setFiles={setFiles}
                          isUploading={isUploading}
                          setIsUploading={setIsUploading}
                          error={error}
                          title="Related Attachments"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row items-center justify-between pt-6 border-t-2">
                  <div className="flex flex-col"></div>
                  <div className="flex flex-row items-center">
                    <div
                      className="px-8 text-sm font-bold cursor-pointer font-roboto text-grayColor"
                      onClick={cancel}
                    >
                      Cancel
                    </div>
                    <div className="w-36">
                      <SubmitButton>Send</SubmitButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    basePaymentMethods: state.PaymentMethods.basePaymentMethods,
    baseCurrencies: state.Currencies.baseCurrencies,
    currentCompany: state.Company.currentCompany,
  };
};
export default connect(mapStateToProps, {
  setBaseCurrencies,
  setBasePaymentMethods,
})(CreateNewMoneyTransfer);
