import getHttpClient from "../api";
const client = getHttpClient();

const req = {
  createSalaries: async (
    companyId,
    description,
    assignees,
    attachments,
    payrolls
  ) => {
    try {
      const data = {
        salaries: payrolls,
        description: description,
        attachments: attachments,
        assignees: assignees,
      };
      const response = await client.post(
        `/dms/salary-document/${companyId}`,
        data
      );
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default req;
