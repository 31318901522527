import React, { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import CancelIcon from "../../../assets/imgs/icons/cancel.svg";

function TermsAndCondition({ isOpen, closeModal }) {
    return (
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div"

                className={
                    "fixed inset-0 z-10 overflow-y-auto flex justify-center items-center"}
                onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black bg-opacity-25" />
                </Transition.Child>

                <div className="fixed  inset-0 overflow-y-auto ">
                    <div className="flex min-h-full items-center  justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-4xl transform overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="p-6 flex justify-between items-center text-lg font-medium border-b leading-6 text-dark"
                                >
                                    <span>
                                        Terms of service and privacy policy
                                    </span>
                                    <button onClick={closeModal}>
                                        <img src={CancelIcon} alt="" />
                                    </button>
                                </Dialog.Title>
                                <div className="mt-2 h-96 overflow-y-scroll px-6 py-3" >
                                    <h3 className='text-black font-bold pb-3'> Privacy Policy </h3>
                                    <p className='text-black text-base pb-3'>
                                        We respect your privacy and are committed to protecting it through our compliance with this privacy policy (“Policy”). This Policy describes the types of information we may collect from you or that you may provide (“Personal Information”) on the theaccounter.com website (“Website”), “The Accounter” mobile application (“Mobile Application”), and any of their related products and services (collectively, “Services”), and our practices for collecting, using, maintaining, protecting, and disclosing that Personal Information. It also describes the choices available to you regarding our use of your Personal Information and how you can access and update it.
                                    </p>
                                    <p className='text-black text-base pb-3'>
                                        This Policy is a legally binding agreement between you (“User”, “you” or “your”) and The Accounter Technologies LTD (doing business as “The Accounter”, “we”, “us” or “our”). If you are entering into this agreement on behalf of a business or other legal entity, you represent that you have the authority to bind such entity to this agreement, in which case the terms “User”, “you” or “your” shall refer to such entity. If you do not have such authority, or if you do not agree with the terms of this agreement, you must not accept this agreement and may not access and use the Services. By accessing and using the Services, you acknowledge that you have read, understood, and agree to be bound by the terms of this Policy. This Policy does not apply to the practices of companies that we do not own or control, or to individuals that we do not employ or manage.
                                    </p>
                                    <p className='text-black text-base pb-3'>
                                        Automatic collection of information
                                    </p>
                                    <p className='text-black text-base pb-3'>

                                        When you open the Website or use the Mobile Application, our servers automatically record information that your browser or device sends. This data may include information such as your device’s IP address and location, browser and device name and version, operating system type and version, language preferences, the webpage you were visiting before you came to the Services, pages of the Services that you visit, the time spent on those pages, the information you search for on the Services, access times and dates, and other statistics.
                                    </p>

                                    <p className='text-black text-base pb-3'>

                                        Information collected automatically is used only to identify potential cases of abuse and establish statistical information regarding the usage and traffic of the Services. This statistical information is not otherwise aggregated in such a way that would identify any particular User of the system.
                                    </p>
                                    <p className='text-black text-base pb-3'>

                                        Collection of personal information

                                    </p>
                                    <p className='text-black text-base pb-3'>

                                        You can access and use the Services without telling us who you are or revealing any information by which someone could identify you as a specific, identifiable individual. If, however, you wish to use some of the features offered on the Services, you may be asked to provide certain Personal Information (for example, your name and e-mail address).
                                    </p>

                                    <p className='text-black text-base pb-3'>

                                        We receive and store any information you knowingly provide to us when you create an account, make a purchase,  or fill any forms on the Services. When required, this information may include the following:
                                    </p>

                                    <ul className='text-black'>
                                        <li className='list-item pb-3'>
                                            - Account details (such as user name, unique user ID, password, etc)
                                        </li>
                                        <li className='list-item pb-3'>
                                            - Contact information (such as email address, phone number, etc)
                                        </li>
                                        <li className='list-item pb-3'>
                                            - Basic personal information (such as name, country of residence, etc)
                                        </li>
                                        <li className='list-item pb-3'>
                                            - Biometric information (such as facial recognition, fingerprints, etc)

                                        </li>

                                        <li className='list-item pb-3'>

                                            - Proof of identity (such as a photocopy of a government ID)
                                        </li>
                                        <li className='list-item pb-3'>
                                            - Payment information (such as credit card details, bank details, etc)

                                        </li >
                                        <li className='list-item pb-3'>
                                            - Geolocation data of your device (such as latitude and longitude)

                                        </li>
                                        <li className='list-item pb-3'>
                                            - Certain features on the mobile device (such as contacts, calendar, gallery, etc)
                                        </li>

                                        <li className='list-item pb-3'>
                                            - Information about other individuals (such as your family members, friends, etc)
                                        </li>
                                        <li className='list-item pb-3'>
                                            - Any other materials you willingly submit to us (such as articles, images, feedback, etc)
                                        </li>
                                    </ul>



                                    <p className='text-black text-base pb-3'>


                                        You can choose not to provide us with your Personal Information, but then you may not be able to take advantage of some of the features on the Services. Users who are uncertain about what information is mandatory are welcome to contact us.

                                    </p>
                                    <p className='text-black text-base pb-3'>

                                        Privacy of children

                                    </p>

                                    <p className='text-black text-base pb-3'>

                                        We do not knowingly collect any Personal Information from children under the age of 18. If you are under the age of 18, please do not submit any Personal Information through the Services. If you have reason to believe that a child under the age of 18 has provided Personal Information to us through the Services, please contact us to request that we delete that child’s Personal Information from our Services.

                                    </p>
                                    <p className='text-black text-base pb-3'>

                                        We act in the capacity of a data processor in situations when you submit Personal Information through the Services. We do not own, control, or make decisions about the submitted Personal Information, and such Personal Information is processed only in accordance with your instructions. In such instances, the User providing Personal Information acts as a data controller.
                                    </p>

                                    <p className='text-black text-base pb-3'>

                                        In order to make the Services available to you, or to meet a legal obligation, we may need to collect and use certain Personal Information. If you do not provide the information that we request, we may not be able to provide you with the requested products or services. Any of the information we collect from you may be used for the following purposes:
                                    </p>

                                    <ul>
                                        <li className='list-item pb-3'>
                                            - Create and manage user accounts
                                        </li>
                                        <li className='list-item pb-3'>
                                            - Fulfill and manage orders
                                        </li>
                                        <li className='list-item pb-3'>

                                            - Deliver products or services
                                        </li>
                                        <li className='list-item pb-3'>
                                            - Improve products and services

                                        </li>
                                        <li className='list-item pb-3'>
                                            - Send administrative information
                                        </li>


                                        <li className='list-item pb-3'>

                                            - Send marketing and promotional communications

                                        </li>


                                        <li className='list-item pb-3'>

                                            - Send product and service updates

                                        </li>

                                        <li className='list-item pb-3'>

                                            - Respond to inquiries and offer support
                                        </li>


                                        <li className='list-item pb-3'>


                                            - Request user feedback
                                        </li>


                                        <li className='list-item pb-3'>

                                            - Improve user experience


                                        </li>

                                        <li className='list-item pb-3'>

                                            - Post customer testimonials

                                        </li>

                                        <li className='list-item pb-3'>
                                            - Deliver targeted advertising

                                        </li>

                                        <li className='list-item pb-3'>
                                            - Enforce terms and conditions and policies

                                        </li>

                                        <li className='list-item pb-3'>
                                            - Protect from abuse and malicious users

                                        </li>    <li className='list-item pb-3'>

                                            - Respond to legal requests and prevent harm
                                        </li>    <li className='list-item pb-3'>

                                            - Run and operate the Services
                                        </li>
                                    </ul>



                                    <p className='text-black text-base pb-3'>


                                        Processing your Personal Information depends on how you interact with the Services, where you are located in the world and if one of the following applies: (i) you have given your consent for one or more specific purposes; (ii) provision of information is necessary for the performance of an agreement with you and/or for any pre-contractual obligations thereof; (iii) processing is necessary for compliance with a legal obligation to which you are subject; (iv) processing is related to a task that is carried out in the public interest or in the exercise of official authority vested in us; (v) processing is necessary for the purposes of the legitimate interests pursued by us or by a third party. We may also combine or aggregate some of your Personal Information in order to better serve you and to improve and update our Services.

                                    </p>

                                    <p className='text-black text-base pb-3'>

                                        Note that under some legislations we may be allowed to process information until you object to such processing by opting out, without having to rely on consent or any other of the legal bases. In any case, we will be happy to clarify the specific legal basis that applies to the processing, and in particular whether the provision of Personal Information is a statutory or contractual requirement, or a requirement necessary to enter into a contract.
                                    </p>
                                    <p className='text-black text-base pb-3'>

                                        Payment processing

                                    </p>

                                    <p className='text-black text-base pb-3'>


                                        In case of Services requiring payment, you may need to provide your credit card details or other payment account information, which will be used solely for processing payments. We use third-party payment processors (“Payment Processors”) to assist us in processing your payment information securely.


                                    </p>

                                    <p className='text-black text-base pb-3'>

                                        Payment Processors adhere to the latest security standards as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, MasterCard, American Express and Discover.  Sensitive and private data exchange happens over a SSL secured communication channel and is encrypted and protected with digital signatures, and the Services are also in compliance with strict vulnerability standards in order to create as secure of an environment as possible for Users. We will share payment data with the Payment Processors only to the extent necessary for the purposes of processing your payments, refunding such payments, and dealing with complaints and queries related to such payments and refunds.

                                    </p>


                                    <p className='text-black text-base pb-3'>


                                        Please note that the Payment Processors may collect some Personal Information from you, which allows them to process your payments (e.g., your email address, address, credit card details, and bank account number) and handle all the steps in the payment process through their systems, including data collection and data processing. The Payment Processors’ use of your Personal Information is governed by their respective privacy policies which may or may not contain privacy protections as protective as this Policy. We suggest that you review their respective privacy policies.

                                    </p>

                                    <p className='text-black text-base pb-3'>


                                        Managing information

                                    </p>


                                    <p className='text-black text-base pb-3'>

                                        You are able to delete certain Personal Information we have about you. The Personal Information you can delete may change as the Services change. When you delete Personal Information, however, we may maintain a copy of the unrevised Personal Information in our records for the duration necessary to comply with our obligations to our affiliates and partners, and for the purposes described below. If you would like to delete your Personal Information or permanently delete your account, you can do so by contacting us.

                                    </p>

                                    <p className='text-black text-base pb-3'>
                                        Disclosure of information

                                    </p>
                                    <p className='text-black text-base pb-3'>

                                        Depending on the requested Services or as necessary to complete any transaction or provide any Service you have requested, we may share your information with our affiliates, contracted companies, and service providers (collectively, “Service Providers”) we rely upon to assist in the operation of the Services available to you and whose privacy policies are consistent with ours or who agree to abide by our policies with respect to Personal Information. We will not share any personally identifiable information with third parties and will not share any information with unaffiliated third parties.

                                    </p>

                                    <p className='text-black text-base pb-3'>


                                        Service Providers are not authorized to use or disclose your information except as necessary to perform services on our behalf or comply with legal requirements. Service Providers are given the information they need only in order to perform their designated functions, and we do not authorize them to use or disclose any of the provided information for their own marketing or other purposes.

                                    </p>

                                    <p className='text-black text-base pb-3'>
                                        We may also disclose any Personal Information we collect, use or receive if required or permitted by law, such as to comply with a subpoena or similar legal process, and when we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request.
                                    </p>
                                    <p className='text-black text-base pb-3'>


                                        Retention of information

                                    </p>

                                    <p className='text-black text-base pb-3'>

                                        We will retain and use your Personal Information for the period necessary to comply with our legal obligations, as long as your user account remains active, until the purchase order or Services are fulfilled, until our and our affiliates and partners obligations are fulfilled, to enforce our agreements, resolve disputes, and unless a longer retention period is required or permitted by law.
                                    </p>
                                    <p className='text-black text-base pb-3'>

                                        We may use any aggregated data derived from or incorporating your Personal Information after you update or delete it, but not in a manner that would identify you personally. Once the retention period expires, Personal Information shall be deleted. Therefore, the right to access, the right to erasure, the right to rectification, and the right to data portability cannot be enforced after the expiration of the retention period.

                                    </p>

                                    <p className='text-black text-base pb-3'>


                                        Data analytics

                                    </p>

                                    <p className='text-black text-base pb-3'>


                                        Our Services may use third-party analytics tools that use cookies, web beacons, or other similar information-gathering technologies to collect standard internet activity and usage information. The information gathered is used to compile statistical reports on User activity such as how often Users visit our Services, what pages they visit and for how long, etc. We use the information obtained from these analytics tools to monitor the performance and improve our Services. We do not use third-party analytics tools to track or to collect any personally identifiable information of our Users and we will not associate any information gathered from the statistical reports with any individual User.
                                    </p>

                                    <p className='text-black text-base pb-3'>


                                        Do Not Track signals

                                    </p>

                                    <p className='text-black text-base pb-3'>


                                        Some browsers incorporate a Do Not Track feature that signals to websites you visit that you do not want to have your online activity tracked. Tracking is not the same as using or collecting information in connection with a website. For these purposes, tracking refers to collecting personally identifiable information from consumers who use or visit a website or online service as they move across different websites over time. How browsers communicate the Do Not Track signal is not yet uniform. As a result, the Services are not yet set up to interpret or respond to Do Not Track signals communicated by your browser. Even so, as described in more detail throughout this Policy, we limit our use and collection of your Personal Information.

                                    </p>

                                    <p className='text-black text-base pb-3'>


                                        Social media features

                                    </p>

                                    <p className='text-black text-base pb-3'>


                                        Our Services may include social media features, such as the Facebook and Twitter buttons, Share This buttons, etc (collectively, “Social Media Features”). These Social Media Features may collect your IP address, what page you are visiting on our Services, and may set a cookie to enable Social Media Features to function properly. Social Media Features are hosted either by their respective providers or directly on our Services. Your interactions with these Social Media Features are governed by the privacy policy of their respective providers.

                                    </p>
                                    <p className='text-black text-base pb-3'>

                                        Links to other resources

                                    </p>
                                    <p className='text-black text-base pb-3'>


                                        The Services contain links to other resources that are not owned or controlled by us. Please be aware that we are not responsible for the privacy practices of such other resources or third parties. We encourage you to be aware when you leave the Services and to read the privacy statements of each and every resource that may collect Personal Information.

                                    </p>

                                    <p className='text-black text-base pb-3'>


                                        Information security

                                    </p>

                                    <p className='text-black text-base pb-3'>


                                        We secure information you provide on computer servers in a controlled, secure environment, protected from unauthorized access, use, or disclosure. We maintain reasonable administrative, technical, and physical safeguards in an effort to protect against unauthorized access, use, modification, and disclosure of Personal Information in our control and custody. However, no data transmission over the Internet or wireless network can be guaranteed.

                                    </p>

                                    <p className='text-black text-base pb-3'>


                                        Therefore, while we strive to protect your Personal Information, you acknowledge that (i) there are security and privacy limitations of the Internet which are beyond our control; (ii) the security, integrity, and privacy of any and all information and data exchanged between you and the Services cannot be guaranteed; and (iii) any such information and data may be viewed or tampered with in transit by a third party, despite best efforts.

                                    </p>
                                    <p className='text-black text-base pb-3'>


                                        As the security of Personal Information depends in part on the security of the device you use to communicate with us and the security you use to protect your credentials, please take appropriate measures to protect this information.

                                    </p>
                                    <p className='text-black text-base pb-3'>

                                        Data breach

                                    </p>
                                    <p className='text-black text-base pb-3'>

                                        In the event we become aware that the security of the Services has been compromised or Users’ Personal Information has been disclosed to unrelated third parties as a result of external activity, including, but not limited to, security attacks or fraud, we reserve the right to take reasonably appropriate measures, including, but not limited to, investigation and reporting, as well as notification to and cooperation with law enforcement authorities. In the event of a data breach, we will make reasonable efforts to notify affected individuals if we believe that there is a reasonable risk of harm to the User as a result of the breach or if notice is otherwise required by law. When we do, we will send you an email, get in touch with you over the phone.

                                    </p>


                                    <p className='text-black text-base pb-3'>


                                        Changes and amendments

                                    </p>

                                    <p className='text-black text-base pb-3'>


                                        We reserve the right to modify this Policy or its terms related to the Services at any time at our discretion. When we do, we will revise the updated date at the bottom of this page. We may also provide notice to you in other ways at our discretion, such as through the contact information you have provided.

                                    </p>


                                    <p className='text-black text-base pb-3'>

                                        An updated version of this Policy will be effective immediately upon the posting of the revised Policy unless otherwise specified. Your continued use of the Services after the effective date of the revised Policy (or such other act specified at that time) will constitute your consent to those changes. However, we will not, without your consent, use your Personal Information in a manner materially different than what was stated at the time your Personal Information was collected.

                                    </p>

                                    <p className='text-black text-base pb-3'>

                                        Acceptance of this policy

                                    </p>

                                    <p className='text-black text-base pb-3'>
                                        You acknowledge that you have read this Policy and agree to all its terms and conditions. By accessing and using the Services and submitting your information you agree to be bound by this Policy. If you do not agree to abide by the terms of this Policy, you are not authorized to access or use the Services.
                                    </p>
                                    <p className='text-black text-base pb-3'>

                                        Contacting us
                                    </p>
                                    <p className='text-black text-base pb-3'>

                                        If you have any questions, concerns, or complaints regarding this Policy, the information we hold about you, or if you wish to exercise your rights, we encourage you to contact us using the details below:
                                    </p>
                                    <p className='text-black text-base pb-3'>
                                        https://theaccounter.com/privacy-policy
                                    </p>
                                    <p className='text-black text-base pb-3'>
                                        support@theaccounter.com

                                    </p>
                                    <p className='text-black text-base pb-3'>
                                        We will attempt to resolve complaints and disputes and make every reasonable effort to honor your wish to exercise your rights as quickly as possible and in any event, within the timescales provided by applicable data protection laws.
                                    </p>
                                    <p className='text-black text-base pb-3'>

                                        This document was last updated on January 25, 2022
                                    </p>

                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition >
    )
}

export default TermsAndCondition