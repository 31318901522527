import { generateAvatar } from "../../../utils";

const UserValue = ({ name, sizeOfAvatar }) => {
  const avatar = generateAvatar(name);
  return (
    <div className="flex flex-row items-center">
      <div
        className={`w-${sizeOfAvatar || 10} h-${
          sizeOfAvatar || 10
        } rounded-full flex items-center justify-center mr-2`}
        style={{ backgroundColor: "#25abc31f" }}
      >
        <div className="text-arrowTop font-bold">{avatar}</div>
      </div>
      <div>{name}</div>
    </div>
  );
};

export default UserValue;
