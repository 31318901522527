import { useEffect, useRef, useState, useMemo, Suspense } from "react";
import arrowDropDown from "../../../assets/imgs/icons/arrow_drop_down.svg";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import AmountInput from "../../Inputs/Amount";

const AmountRangeFilter = ({
  onChangeFilter,
  from_name,
  to_name,
  title,
  filters,
}) => {
  const [isOpen, setIsOpen] = useState();
  const [hasValue, setHasValue] = useState();
  const ref = useRef(null);

  const methods = useForm({
    defaultValues: {
      [from_name]: filters[from_name],
      [to_name]: filters[to_name],
    },
  });

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };
  const onClickOutside = () => {
    setIsOpen(false);
  };

  const onChange = (e) => {
    onChangeFilter(e.target.name, e.target.value);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);
  const from_value = methods.watch(from_name);
  const to_value = methods.watch(to_name);
  useEffect(() => {
    onChangeFilter(from_name, from_value);
  }, [from_value]);
  useEffect(() => {
    onChangeFilter(to_name, to_value);
  }, [to_value]);

  const handleClearSelection = (e) => {
    methods.setValue(from_name, null);
    methods.setValue(to_name, null);
  };
  return (
    <div className="relative flex p-2">
      <div
        onClick={toggleOpen}
        className={`px-3 py-2 rounded-md cursor-pointer  min-w-max ${
          from_value || to_value ? "border-black border-2" : "border"
        }`}
      >
        <div className="flex flex-row items-center">
          <div className="flex flex-row items-center px-2 text-grayColorText2">
            {title}
          </div>
          {(from_value || to_value) && (
            <div className="ml-1 font-bold">{`${from_value ? from_value : ""}:${
              to_value ? to_value : ""
            }`}</div>
          )}
          <img src={arrowDropDown} />
        </div>
      </div>
      {isOpen && (
        <div
          className="absolute py-2 px-4 bg-white rounded-md shadow-dropdownMenu overflow-hidden z-20"
          ref={ref}
          style={{ width: "20rem", left: 0, top: 50 }}
        >
          <FormProvider {...methods}>
            <form>
              <div className="flex flex-row items-center justify-between">
                <div>
                  <div className="px-1">From</div>
                  <AmountInput name={from_name} placeholder="From" />
                </div>
                <div className="px-4">-</div>
                <div>
                  <div className="px-1">To</div>
                  <AmountInput name={to_name} placeholder="To" />
                </div>
              </div>
              {methods.getValues(from_name) | methods.getValues(to_name) ? (
                <div
                  className="mt-4 flex justify-center items-center py-3 border-t-2 cursor-pointer text-grayColorText2"
                  onClick={handleClearSelection}
                >
                  Clear
                </div>
              ) : null}
            </form>
          </FormProvider>
        </div>
      )}
    </div>
  );
};

export default AmountRangeFilter;
