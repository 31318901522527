import { useEffect, useRef, useState } from "react";
import Logo from "../../components/Logo";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import InfoIcon from "../../assets/imgs/icons/info.svg";
import CancelIcon from "../../assets/imgs/icons/cancel.svg";

import ReactTooltip from "react-tooltip";
import RadioButtonInput from "../../components/Inputs/RadioButton";
const THEACCOUNTER_ENV = process.env.REACT_APP_THEACCOUNTER_ENV;
let DOCUMENT_TYPE = [];
if (THEACCOUNTER_ENV === "development") {
  DOCUMENT_TYPE = [
    {
      key: "payments",
      value: "Payments",
      color: "#25ABC3",
      link: "/new-request/create-payment",
    },
    {
      key: "receipt",
      value: "Receipts",
      color: "#25ABC3",
      link: "/new-request/create-receipt",
    },
    {
      key: "salaries",
      value: "Salaries",
      color: "#25ABC3",
      link: "/new-request/create-salaries",
    },
  ];
} else {
  DOCUMENT_TYPE = [
    {
      key: "payments",
      value: "Payments",
      color: "#25ABC3",
      link: "/new-request/create-payment",
    },
    {
      key: "receipt",
      value: "Receipts",
      color: "#25ABC3",
      link: "/new-request/create-receipt",
    },
  ];
}
const NewRequest = () => {
  const navigator = useNavigate();
  const location = useLocation();
  const info = useRef(null);
  const [type, setType] = useState();
  useEffect(() => {
    setTimeout(() => ReactTooltip.show(info.current), 500);
    setTimeout(ReactTooltip.hide, 5000);
  }, [info]);
  useEffect(() => {
    if (location.pathname === "/new-request/create-payment") {
      setType("payments");
    }
    if (location.pathname === "/new-request/create-receipt") {
      setType("receipt");
    }
    if (location.pathname === "/new-request/create-salaries") {
      setType("salaries");
    }
  }, [location]);

  return (
    <div>
      <div className="flex w-full shadow-headerShadow h-30">
        <div className="flex flex-row items-center justify-between w-full mx-36">
          <div className="flex flex-row h-full">
            <div
              className="mt-7  cursor-pointer"
              onClick={() => {
                navigator("/");
              }}
            >
              <Logo />
            </div>
            <div className="flex flex-col justify-between ml-10 mt-7">
              <div className="text-2xl font-bold font-poppins leading-9">
                Add New Request
              </div>
              <div className="flex">
                <div
                  className={`flex  items-center  px-2 text-center whitespace-nowrap cursor-pointer`}
                >
                  <span
                    className={`h-full py-1 text-base font-Roboto ${
                      location.pathname === "/new-request/upload"
                        ? "activeTab"
                        : ""
                    }`}
                  >
                    <Link to="/new-request/upload" className="text-black">
                      Upload Documents
                    </Link>
                  </span>
                </div>
                <div
                  className={`flex items-center px-2 text-center whitespace-nowrap cursor-pointer `}
                >
                  <span
                    className={`h-full py-1 text-base font-Roboto ${
                      location.pathname !== "/new-request/upload"
                        ? "activeTab"
                        : ""
                    }`}
                  >
                    <Link
                      to="/new-request/create-payment"
                      className="text-black"
                    >
                      Create Documents
                    </Link>
                  </span>
                  <div className="flex items-center justify-center ml-2">
                    <img
                      src={InfoIcon}
                      alt=""
                      ref={info}
                      data-tip="Don't have a Document? Create one"
                      data-for="tooltip"
                    />
                    <ReactTooltip
                      effect="solid"
                      place="bottom"
                      id="tooltip"
                      backgroundColor="#25ABC3"
                      className="text-sm font-medium font-roboto rounded-md"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="cursor-pointer"
            onClick={() => {
              navigator("/");
            }}
          >
            <img src={CancelIcon} alt="" width="30" height="30" />
          </div>
        </div>
      </div>
      <div className="mx-auto container px-20">
        {location.pathname !== "/new-request/upload" && (
          <div className="w-full py-4 border-b-2 mt-8">
            <div className="text-arrowTop pb-4">Create</div>
            <div className="flex justify-center w-1/3">
              {DOCUMENT_TYPE.map((option) => {
                return (
                  <div className="w-full p-1">
                    <div
                      className="flex items-center justify-center w-full h-12 px-4 py-2 text-sm border rounded cursor-pointer border-borderColor font-Roboto"
                      style={{
                        backgroundColor:
                          type === option.key ? `${option.color}29` : "",
                        borderColor: type === option.key ? option.color : "",
                        color: type === option.key ? option.color : "",
                      }}
                      onClick={() => {
                        if (option?.link) navigator(option?.link);
                      }}
                    >
                      {option.value}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        <Outlet />
      </div>
    </div>
  );
};

export default NewRequest;
