import UserValue from "../../components/View/Field/UserValue";

const DocumentAssingees = ({ assignees }) => {
  return (
    <div>
      <div className="">Assigned to ({assignees?.length})</div>
      <div className="flex flex-col flex-wrap  mt-4">
        {assignees.map((assignee) => {
          return (
            <div className="mb-4">
              <UserValue name={assignee[1]} sizeOfAvatar={14} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default DocumentAssingees;
