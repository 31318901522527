import { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import DropDownIcon from "../../assets/imgs/icons/arrow_drop_down.svg";
import RadioButtonCheckedIcon from "../../assets/imgs/icons/radio_button_checked.svg";
import { setCurrentCompany } from "../../store/Company/actions";
const SwitchCompany = ({
  currentCompany,
  allowedCompanies,
  setCurrentCompany,
}) => {
  const [isOpen, setIsOpen] = useState();
  const ref = useRef(null);

  const toggleOpen = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  const onClickOutside = () => {
    setIsOpen(false);
  };
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);
  return (
    <div className="relative" ref={ref}>
      <div
        className="flex items-center justify-start w-auto cursor-pointer"
        onClick={toggleOpen}
      >
        <div className="dream-soft-company roboto-medium-outer-space-16px">
          {currentCompany?.name}
        </div>
        <img className="arrow_drop_down" src={DropDownIcon} />
      </div>
      {isOpen && (
        <div
          className="absolute z-20 py-2 mt-4 overflow-hidden bg-white rounded-md shadow-dropdownMenu"
          style={{ marginLeft: "-10px" }}
        >
          {allowedCompanies?.map((company) => (
            <div
              className="flex items-center justify-between px-4 py-2 w-max"
              onClick={() => {
                setCurrentCompany(company);
                setIsOpen(false);
                document.location.reload();
              }}
            >
              <div className="flex items-center text-xl cursor-pointer place-content-start">
                <div className="mr-2">
                  {company.id !== currentCompany.id && (
                    <span className="inline-block w-4 h-4 border rounded-full border-grey flex-no-shrink"></span>
                  )}
                  {company.id === currentCompany.id && (
                    <img src={RadioButtonCheckedIcon} alt="" />
                  )}
                </div>
                <div className="text-sm leading-3">{company?.name}</div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentCompany: state.Company.currentCompany,
    allowedCompanies: state.Company.allowedCompanies,
  };
};
export default connect(mapStateToProps, { setCurrentCompany })(SwitchCompany);
