import { connect } from "react-redux";

import "../Home/style.scss";
import HomeImg from "../../assets/imgs/home-beta.svg";
import { useNavigate, Link } from "react-router-dom";
import Container from "../../components/Container";
import Button from "../../components/Button";

const Dashboard = ({ user, currentCompany }) => {
  const navigator = useNavigate();
  return (
    <div>
      <div
        style={{
          background: `url(${HomeImg})`,
          backgroundRepeat: "no-repeat",
          backgroundPositionX: "right",
          backgroundPositionY: "top",
          backgroundSize: "40%",
          minHeight: "calc(100vh - 6rem)",
        }}
        // className="min-h-screen"
      >
        <div className="flex items-center pl-20 pt-52">
          <div className="w-1/2">
            <div className="flex items-center w-20 p-2 text-white bg-orange rounded-md">
              <p className="text-sm font-bold">BETA 2.0</p>
            </div>
            <h1 className="mt-3 text-3xl font-bold text-black ">
              Welcome to the first beta edition of The Accounter web-version.
            </h1>
            <ul className="px-8 mt-5 list-disc">
              <li className="text-sm text-black list-item">
                Here to change the world of Accounting for SMEs.
              </li>
              <li className="mt-2 text-sm text-black list-item">
                This is not our final form, many more amazing features are under
                preparation and will be added to this page very soon.
              </li>
              <li className="mt-2 text-sm text-black list-item">
                Enjoy simple accounting made easy. Start uploading now.
              </li>
            </ul>
            <div className="flex mt-4 buttons gap-3">
              <Button className="font-bold" type="primary" size="medium">
                Upload Document
              </Button>

              <Button className="font-bold" type="outline" size="medium">
                Create Document
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-center -mt-5">
        <p className="if-you-have-any-ques">
          <span className="roboto-normal-outer-space-14px">
            If you have any questions or need help, contact our
          </span>
          <span className="span1">&nbsp;</span>
          <a
            rel="noreferrer"
            href={`mailto:customersupport@theaccounter.com?subject=${
              user?.name
            }%20is%20facing%20an%20issue%20on%20Theaccounter%20web%20app%20-%20(${
              user?.email || user?.mobile
            })&body=User%20Name%3A%20${user?.name}%0D%0AUser%20Contact%3A%20${
              user?.email || user?.mobile
            }%0D%0ACompany%20Name%3A%20${
              currentCompany?.name
            }%0D%0A%0D%0AStart%20describing%20your%20issue%20here`}
            target="_blank"
          >
            <span className="span2">support team</span>
          </a>
        </p>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.currentUser,
    currentCompany: state.Company.currentCompany,
  };
};

export default connect(mapStateToProps, {})(Dashboard);
