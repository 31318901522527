import react, { Fragment } from "react";
import { Route, Navigate, useLocation, Outlet } from "react-router-dom";
import { getCookie } from "../utils";
import Home from "../pages/Home";
import NewRequest from "../pages/NewRequest";
import Signup from "../pages/Signup";
import Login from "../pages/Login";
import ResetPassword from "../pages/ResetPassword";
import UploadDocument from "../components/Forms/UploadDocument";
import CreatePayment from "../components/Forms/CreatePayment";
import CreateReceipt from "../components/Forms/CreateReceipt";
import VerifyAccount from "../pages/VerifyAccount";
import CreateSalaries from "../components/Forms/CreateSalaries";
import AcceptInvitation from "../pages/AcceptInvitation";
import Documents from "../pages/Documents";
import DocumentDetails from "../pages/Documents/DocumentDetails";
import Layout from "../components/Layout";
import Dashboard from "../pages/Dashboard";
import MoneyTransfer from "../pages/MoneyTransfer";
import Reports from "../pages/Reports";
import MoneyTransferDetails from "../pages/MoneyTransfer/MoneyTransferDetails";
import React from "react";
import ReportsLayout from "../components/ReportsLayout";
import BalanceSheet from "../pages/Reports/BalanceSheet";
import ProfitAndLoss from "../pages/Reports/ProfitAndloss";
import CashFlow from "../pages/Reports/CashFlow";

const authProtectedRoutes = [
  {
    path: "/",
    Component: Layout,
    childrens: [
      { path: "", Component: Dashboard },
      {
        path: "documents",
        Component: Outlet,
        childrens: [
          { path: "", Component: Documents },
          { path: ":documentId", Component: DocumentDetails },
        ],
      },
      {
        path: "money-transfer",
        Component: Outlet,
        childrens: [
          { path: "", Component: MoneyTransfer },
          { path: ":documentId", Component: MoneyTransferDetails },
        ],
      },
      {
        path: "reports",
        Component: ReportsLayout,
        childrens: [
          { path: "balancesheet", Component: BalanceSheet },
          { path: "profitandLossStatement", Component: ProfitAndLoss },
          { path: "cashFlowstatement", Component: CashFlow },
          { path: "inventoryReport", Component: Reports },
          { path: "taxReport", Component: Reports },
          { path: "agedReceivable", Component: Reports },
          { path: "agedPayable", Component: Reports },
          { path: "assetReport", Component: Reports },
          { path: "moneyStatement", Component: Reports },
          { path: "statementofAccount", Component: Reports },
        ],
      },
    ],
  },
  {
    path: "/new-request",
    Component: NewRequest,
    childrens: [
      { path: "upload", Component: UploadDocument },
      { path: "create-payment", Component: CreatePayment },
      { path: "create-receipt", Component: CreateReceipt },
      { path: "create-salaries", Component: CreateSalaries },
    ],
  },
];

const publicRoutes = [
  { path: "/login", Component: Login },
  { path: "/signup", Component: Signup },
  { path: "/resetPassword", Component: ResetPassword },
  { path: "/verifyAccount", Component: VerifyAccount },
  { path: "/user-invitation/:token", Component: AcceptInvitation },
];

function RequireAuth({ children }) {
  const savedJwt = getCookie("jwt");
  let location = useLocation();

  if (!savedJwt) {
    if (location.pathname === "/") {
      return <Navigate to="/login" />;
    } else {
      return <Navigate to="/login" state={{ from: location }} />;
    }
  }

  if (!children) {
    return <Navigate replace to="/" />;
  }

  return children;
}

function RequireNonAuth({ children }) {
  const savedJwt = getCookie("jwt");
  let location = useLocation();
  if (savedJwt) {
    if (location.pathname === "/login") {
      return <Navigate to="/" />;
    }
  }

  return children;
}
export { authProtectedRoutes, publicRoutes, RequireAuth, RequireNonAuth };
