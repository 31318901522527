import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";

import EmployeeIcon from "../../../assets/imgs/icons/employees.svg";
import AddIcon from "../../../assets/imgs/icons/add.svg";
import { useFormContext, useFieldArray } from "react-hook-form";
import useModal from "../../Modal/useModal";

import ArrowUpCircle from "../../../assets/imgs/icons/arrow-up-circle.svg";
import ArrowDropDownCircle from "../../../assets/imgs/icons/arrow-drop-down-circle.svg";
import SelectFromEmployeeList from "../../Modal/SelectFromEmployeeList";

import DateInput from "../../Inputs/Date";
import PaymentMethod from "../CreatePayment/paymentMethod";
import MonthPicker from "../../Inputs/MonthPicker";
import MonetaryInput from "../../Inputs/Monetary";
import TextInput from "../../Inputs/Text";
import CostCenter from "../CreatePayment/costCenter";
import Category from "../CreatePayment/category";
import TextareaInput from "../../Inputs/TextArea";
import CheckBoxInput from "../../Inputs/CheckBox";
import trashIcon from "../../../assets/imgs/icons/trash.svg";
import deselectIcon from "../../../assets/imgs/icons/Checkbox.svg";
import { toast } from "react-toastify";
import { ToastifyError, ToastifySuccess } from "../../../toastify";

const PayrollMonth = forwardRef((props, ref) => {
  const { index, error, currentCurrency, employeesOfCompany, minDate } = props;
  const callPromptModalSelectEmployee = useModal(SelectFromEmployeeList);
  const methods = useFormContext();
  const [employees, setEmployees] = useState([]);
  const [mixed, setMixed] = useState(null);
  const {
    fields: payslipFields,
    append: appendPayslip,
    insert: insertPayslip,
    remove: removePayslip,
  } = useFieldArray({
    control: methods.control,
    name: `salaries.${index}.payslips`,
  });
  // The component instance will be extended
  // with whatever you return from the callback passed
  // as the second argument
  useImperativeHandle(ref, () => ({
    removePayslipOutside(index) {
      removePayslip(index);
    },
    insertPayslipOutside(index, vals) {
      insertPayslip(index, vals);
    },
  }));
  const document_date = methods.watch(`salaries.${index}.document_date`);
  const payment_method = methods.watch(`salaries.${index}.payment_method`);
  const handleAddEmployee = async () => {
    try {
      const res = await callPromptModalSelectEmployee({
        title: "Employees",
        employees: employeesOfCompany,
        selectedList: payslipFields.map((payslip) => payslip.employee),
      });
      // payslipFields.forEach((payslip, i) => {
      //   if (!res.map((r) => r.id).includes(payslip.employee)) {
      //     removePayslip(i);
      //   }
      // });
      const employeesToBeAdded = res.filter(
        (r) => !payslipFields.map((payslip) => payslip.employee).includes(r.id)
      );
      appendPayslip(
        employeesToBeAdded.map((employee) => {
          return {
            selected: false,
            expanded: false,
            employee: employee.id,
            currency: currentCurrency.id,
            name: employee.name,
            basic_salary: employee.basic_salary || "",
            allowance: employee.allowance || "",
            payment_date: document_date || new Date(),
            payment_method: payment_method,
          };
        })
      );
    } catch (e) {
      console.error(e);
    }
  };
  const payslips = methods.watch(`salaries.${index}.payslips`) || [];

  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      const payslips = methods.watch(`salaries.${index}.payslips`) || [];
      if (name.includes(`salaries.${index}.payslips`)) {
        if (payslips.length > 0) {
          const refDate = payslips[0].payment_date;
          const refPaymentMethod = payslips[0].payment_method;
          const isNotDateMixed = payslips.every((payslip) => {
            return (
              payslip.payment_date?.toDateString() === refDate?.toDateString()
            );
          });
          const isNotPaymentMethodMixed = payslips.every((payslip) => {
            return payslip.payment_method === refPaymentMethod;
          });
          if (isNotDateMixed) {
            methods.setValue(`salaries.${index}.document_date`, refDate, {});
          }

          if (isNotPaymentMethodMixed) {
            methods.setValue(
              `salaries.${index}.payment_method`,
              refPaymentMethod,
              {}
            );
          }
          methods.setValue(
            `salaries.${index}.isDateMixed`,
            isNotDateMixed ? null : "Mixed",
            {}
          );
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);

  useEffect(() => {
    payslips.forEach((payslip, j) => {
      methods.setValue(
        `salaries.${index}.payslips.${j}.payment_date`,
        document_date
      );
    });
  }, [document_date]);

  useEffect(() => {
    payslips.forEach((payslip, j) => {
      methods.setValue(
        `salaries.${index}.payslips.${j}.payment_method`,
        payment_method
      );
    });
  }, [payment_method]);
  return (
    <div ref={ref} className="mb-16">
      <div className="flex flex-row justify-between w-full mt-4">
        <div className="mb-6 w-1/3">
          <div className="flex flex-row items-center mb-4">
            <div className="ml-3">Payroll Month</div>
          </div>
          <div>
            <MonthPicker
              name={`salaries.${index}.salary_month`}
              maxDate={new Date()}
              minDate={minDate}
              serverErrors={error}
            />
          </div>
        </div>
        <div className="w-1/3 px-4 mb-6">
          <div className="flex flex-row items-center mb-4">
            <div className="ml-3">Payment Date</div>
          </div>
          <div>
            <DateInput
              name={`salaries.${index}.document_date`}
              maxDate={new Date()}
              minDate={minDate}
              showText={methods.watch(`salaries.${index}.isDateMixed`)}
              serverErrors={error}
            />
          </div>
        </div>
        <div className="w-1/3 mb-6">
          <PaymentMethod
            name={`salaries.${index}.payment_method`}
            currentCurrency={currentCurrency}
            error={error}
            showIcon={false}
          />
        </div>
      </div>
      <div className="mt-4">
        <table className="table-auto text-sm w-full  max-w-full">
          <thead className="bg-bgGray w-full">
            <tr>
              <th className="p-4 border-r  text-black ">Employee</th>
              <th className="p-4 border-r  text-black ">Currency</th>
              <th className="p-4 border-r  text-black ">Basic Salary</th>
              <th className="p-4 border-r  text-black ">Other Allowances</th>
              <th className="p-4 border-r  text-black ">Additional</th>
              <th className="p-4 border-r  text-black ">Deduction</th>
              <th className="p-4 border-r  text-black ">Reason</th>
              <th className="p-4 border-r  text-black ">Total</th>
              <th className="p-4 border-r  text-black ">Payment Date</th>
              <th className="p-4 border-r  text-black ">Payment Method</th>
              <th className="p-4">
                <div>
                  <img
                    src={ArrowDropDownCircle}
                    alt=""
                    style={{
                      maxWidth: "None",
                    }}
                  />
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="whitespace-nowrap">
            {payslips.map((payslip, j) => {
              return (
                <>
                  <tr className="font-bold text-center border border-gray-100">
                    <td className="hidden">{payslip.employee}</td>
                    <td>
                      <div className="flex flex-row items-center justify-between">
                        <div className="px-2">
                          <CheckBoxInput
                            name={`salaries.${index}.payslips.${j}.selected`}
                            serverErrors={error}
                          />
                        </div>
                        <div>{payslip.name}</div>
                      </div>
                    </td>
                    <td>{currentCurrency?.code}</td>
                    <td>
                      <MonetaryInput
                        name={`salaries.${index}.payslips.${j}.basic_salary`}
                        serverErrors={error}
                        showCurrency={false}
                      />
                    </td>

                    <td>
                      <MonetaryInput
                        name={`salaries.${index}.payslips.${j}.allowance`}
                        serverErrors={error}
                        showCurrency={false}
                      />
                    </td>

                    <td>
                      <MonetaryInput
                        name={`salaries.${index}.payslips.${j}.additional`}
                        serverErrors={error}
                        showCurrency={false}
                      />
                    </td>

                    <td>
                      <MonetaryInput
                        name={`salaries.${index}.payslips.${j}.deduction`}
                        serverErrors={error}
                        showCurrency={false}
                      />
                    </td>
                    <td>
                      <TextInput
                        name={`salaries.${index}.payslips.${j}.deduction_reason`}
                        serverErrors={error}
                        placeholder="Reason"
                      />
                    </td>
                    <td>
                      {payslip.total?.toFixed(2)} {currentCurrency?.code}
                    </td>
                    <td>
                      <DateInput
                        name={`salaries.${index}.payslips.${j}.payment_date`}
                        maxDate={new Date()}
                        serverErrors={error}
                      />
                    </td>
                    <td>
                      <PaymentMethod
                        name={`salaries.${index}.payslips.${j}.payment_method`}
                        currentCurrency={currentCurrency}
                        error={error}
                        showTitle={false}
                      />
                    </td>

                    <td>
                      <div className="flex items-center justify-center w-full h-full">
                        <img
                          className="cursor-pointer"
                          src={
                            payslip.expanded
                              ? ArrowUpCircle
                              : ArrowDropDownCircle
                          }
                          alt=""
                          onClick={() => {
                            methods.setValue(
                              `salaries.${index}.payslips.${j}.expanded`,
                              !payslip.expanded
                            );
                          }}
                        />
                      </div>
                    </td>
                  </tr>
                  {payslip.expanded && (
                    <tr>
                      <td colSpan="3">
                        <CostCenter
                          name={`salaries.${index}.payslips.${j}.cost_center`}
                          error={error}
                          showTitle={false}
                        />
                      </td>
                      <td colSpan="3">
                        <Category
                          name={`salaries.${index}.payslips.${j}.client_category`}
                          error={error}
                          showTitle={false}
                        />
                      </td>
                      <td colSpan="5">
                        <TextInput
                          name={`salaries.${index}.payslips.${j}.description`}
                          serverErrors={error}
                          placeholder="Description"
                        />
                      </td>
                    </tr>
                  )}
                </>
              );
            })}
          </tbody>
        </table>

        {payslipFields.length <= 0 && (
          <div className="flex mt-12 p-7 flex-col items-center justify-center w-full py-4">
            <div className="py-2">
              <img src={EmployeeIcon} alt="" />
            </div>
            <div className="text-sm text-grayColorText">
              Add at least one employee to create a payslip
            </div>
          </div>
        )}
        <div className="flex  flex-row justify-center mt-8">
          <div className="text-sm font-bold cursor-pointer font-roboto text-arrowTop">
            <button
              className="flex flex-row items-center font-bold justify-center"
              onClick={handleAddEmployee}
            >
              <img alt="add icon" src={AddIcon} />
              <div>Add employee</div>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
});

export default PayrollMonth;
