import RadioTabInput from "../../components/Inputs/RadioTabs";
import { useForm, FormProvider } from "react-hook-form";

import listIcon from "../../assets/imgs/icons/list.svg";
import gridIcon from "../../assets/imgs/icons/apps.svg";

import Tabs from "../../components/Tabs";
import DocumentList from "./DocumentList";

import { useMemo } from "react";
import Thumbnail from "../../components/Thumbnail";
import DocumentsService from "../../services/Documents";
import ClientStatus from "./ClientStatus";
import PaymentStatus from "./PaymentStatus";
import { useNavigate } from "react-router-dom";
import Container from "../../components/Container";
const view_types = [
  { key: "grid", value: "Grid View", icon: gridIcon },
  { key: "list", value: "List View", icon: listIcon },
];
const DOCUMENT_TYPE = [
  {
    text: "All",
    key: "all",
  },
  {
    text: "Documents",
    onActiveText: "Uploaded Documents",
    key: "documents",
  },

  // {
  //   text: "Payments",
  //   key: "payments",
  // },
  // {
  //   text: "Receipts",
  //   key: "receipts",
  // },
  // {
  //   text: "Salaries",
  //   key: "salaries",
  // },
  {
    text: "Cash Transfer",
    key: "cashTransfer",
  },
];

const Documents = () => {
  const methods = useForm({
    defaultValues: {
      module_type: "documents",
      view_type: "list",
    },
  });

  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        disableSortBy: true,
      },
      {
        Header: "Thumbnail",
        accessor: "erp_data.thumbnails", // accessor is the "key" in the data
        Cell: ({ cell: { value }, row }) => {
          return (
            <Thumbnail
              srcImage={value}
              numberOfAttachments={row.original.erp_data.attachments.length}
            />
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Request ID & Status",
        Cell: ({ row }) => {
          return (
            <div>
              <div>{row.original.erp_data.name}</div>
              <div className="flex justify-center mt-2">
                <ClientStatus status={row.original.erp_data.client_status} />
              </div>
            </div>
          );
        },
        disableSortBy: true,
      },

      {
        Header: "Doc. Ref #",
        accessor: "erp_data.reference",
        disableSortBy: true,
        Cell: ({ cell: { value } }) => {
          return value ? (
            <span>{value}</span>
          ) : (
            <span className="text-grayColorText2">Pending</span>
          );
        },
      },
      {
        Header: "Document Date",
        accessor: "erp_data.date",

        Cell: ({ cell: { value } }) => {
          return value ? (
            <span>{value}</span>
          ) : (
            <span className="text-grayColorText2">Pending</span>
          );
        },
      },
      {
        Header: "Partner Name",
        accessor: "erp_data.beneficiary",
        disableSortBy: true,

        Cell: ({ cell: { value } }) => {
          return value ? (
            <span>{value}</span>
          ) : (
            <span className="text-grayColorText2">Pending</span>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "erp_data.amount_with_currency",
        disableSortBy: true,

        Cell: ({ cell: { value } }) => {
          return value ? (
            <span>{value}</span>
          ) : (
            <span className="text-grayColorText2">Pending</span>
          );
        },
      },
      {
        Header: "Payment Status",
        accessor: "erp_data.payment_status",
        disableSortBy: true,
        Cell: ({ cell: { value } }) => {
          return value ? (
            <PaymentStatus status={value} />
          ) : (
            <span className="text-grayColorText2">Pending</span>
          );
        },
      },
      {
        Header: "Creation Date  & Time",
        accessor: "erp_data.created_at",
      },
      {
        Header: "",
        accessor: "options",
        disableSortBy: true,
      },
    ],
    []
  );

  const navigate = useNavigate();
  const handleClickRow = (id, documentObject) => {
    navigate(`/documents/${id}`, { state: { document: documentObject } });
  };
  return (
    <Container>
      <div className="flex justify-between mt-8">
        <div className="text-2xl font-bold ">Documents</div>
        <div>
          {/* <FormProvider {...methods}>
            <form>
              <RadioTabInput name="view_type" options={view_types} />
            </form>
          </FormProvider> */}
        </div>
      </div>
      <div className="flex mt-4 border-b">
        <Tabs tabs={DOCUMENT_TYPE} />
      </div>
      <DocumentList
        columns={columns}
        getDocumentsService={DocumentsService.getDocuments}
        handleClickRow={handleClickRow}
      />
    </Container>
  );
};
export default Documents;
