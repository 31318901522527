import { useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import ResetPasswordService from "../../../services/ResetPassword";
import { ShouldVerifyAccount } from "../../../exceptions";
import TextInput from "../../Inputs/Text";
import SubmitButton from "../../Buttons/Submit";
import ErrorMessage from "../../ErrorMessage";
import resetPasswordIcon from "../../../assets/imgs/icons/resetPassword.svg";
import {
  goNext,
  setContacts,
  setStep,
  setResetPasswordContact,
} from "../../../store/resetPassword/actions";
import { ToastifySuccess } from "../../../toastify";

const schema = yup
  .object({
    contact: yup.string().required("This field is required"),
  })
  .required();
const AskResetPassword = ({
  goNext,
  setContacts,
  setStep,
  setResetPasswordContact,
}) => {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const methods = useForm({
    resolver: yupResolver(schema),
  });
  const onSubmit = async (data) => {
    try {
      const contacts = await ResetPasswordService.forgetPassword(data?.contact);
      setContacts(contacts);
      goNext();
    } catch (error) {
      if (error instanceof ShouldVerifyAccount) {
        const contact = data?.contact;
        const channel = contact.includes("@") ? "email" : "mobile";
        setResetPasswordContact(contact, channel);
        if (channel === "email") {
          const { email } = await ResetPasswordService.verifyEmail(contact);
        } else if (channel === "mobile") {
          const { mobile } = await ResetPasswordService.verifyMobile(contact);
        }
        ToastifySuccess(
          `we have sent a verification code to the ${channel} provided`
        );
        setStep(2);
      }
      setError(error);
    }
  };
  return (
    <>
      <div className="flex justify-center	">
        <img src={resetPasswordIcon} alt="" />
      </div>
      <div className="text-2xl mt-4 font-bold text-center leading-9 font-poppins">
        Forgot your password?
      </div>
      <div className="mt-3 text-base font-light text-center text-grayColor leading-5 font-roboto">
        Don't worry! it happens. Please enter verified email or phone number to
        reset password.
      </div>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex flex-col">
            <div className="flex mt-10">
              <TextInput
                name="contact"
                serverErrors={error}
                placeholder="Email or Mobile Number"
              />
            </div>
            {error?.detail && <ErrorMessage>{error.detail}</ErrorMessage>}
            <div className="mt-8">
              <SubmitButton disabled={methods.formState.isSubmitting}>
                Reset password
              </SubmitButton>
            </div>
          </div>
        </form>
      </FormProvider>
    </>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {
  goNext,
  setContacts,
  setStep,
  setResetPasswordContact,
})(AskResetPassword);
