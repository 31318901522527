import resolveConfig from "tailwindcss/resolveConfig";

const fullConfig = resolveConfig();
const customStyles = {
  option: (provided, state) => ({
    ...provided,
    backgroundColor: "#FFFFFF",
    color: fullConfig.theme.colors.black,
    padding: 10,
  }),
  input: (base, state) => {
    return {
      ...base,
      margin: 0,
      paddingTop: 0,
      paddingBottom: 0,
    };
  },

  menu: (provided, state) => ({
    ...provided,
    minWidth: "fit-content",
  }),
  control: (base, state) => {
    return {
      ...base,
      minHeight: "2.75rem",
      maxHeight: "2.75rem",
      "&:hover": "#25ABC3",
      borderColor: state.isFocused ? "#25ABC3" : "",
      boxShadow: "",
      backgroundColor: state.isFocused ? "#25ABC30D" : "",
      // You can also use state.isFocused to conditionally style based on the focus state
    };
  },
  valueContainer: (base, state) => {
    return {
      ...base,
      padding: "0px 8px",
    };
  },
};
export default customStyles;
