import React, { useState, useEffect, useRef } from "react";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import { connect } from "react-redux";
import _, { remove } from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import SolidButton from "../../Buttons/SolidButton";
import AddWhiteIcon from "../../../assets/imgs/icons/addWhite.svg";
import useModal from "../../Modal/useModal";
import CreateNewEmployee from "../../Modal/CreateNewEmployee";
import EmployeeIcon from "../../../assets/imgs/icons/employees.svg";
import SelectFromList from "../../Modal/selectFromList";
import EmployeeService from "../../../services/Employee";
import CreateSalariesService from "../../../services/CreateSalaries";
import SelectFromEmployeeList from "../../Modal/SelectFromEmployeeList";
import UploadDocumentService from "../../../services/UploadDocument";
import PaymentMethod from "../CreatePayment/paymentMethod";
import MonthPicker from "../../Inputs/MonthPicker";
import MonetaryInput from "../../Inputs/Monetary";
import TextInput from "../../Inputs/Text";
import AssignToSomeone from "../../AssignToSomeone";
import { useNavigate } from "react-router-dom";
import { useBlocker } from "../../../hooks/usePrompt";

import SubmitButton from "../../Buttons/Submit";
import CostCenter from "../CreatePayment/costCenter";
import Category from "../CreatePayment/category";
import TextareaInput from "../../Inputs/TextArea";
import CheckBoxInput from "../../Inputs/CheckBox";
import * as yup from "yup";
import WorkAddIcon from "../../../assets/imgs/icons/work-add.svg";
import deselectIcon from "../../../assets/imgs/icons/Checkbox.svg";
import { trackPromise } from "react-promise-tracker";
import successUploadDocument from "../../Modal/successUploadDocument";
import format from "date-fns/format";
import { getMonth, getYear } from "date-fns";
import { setBaseCurrencies } from "../../../store/currencies/actions";
import CurrencyService from "../../../services/Currency";
import { formatDate } from "../../../utils";
import RadioTabInput from "../../Inputs/RadioTabs";
import PayrollMonth from "./PayrollMonth";
import SelectMonth from "../../Modal/SelectMonth";
import trashIcon from "../../../assets/imgs/icons/trash.svg";
import listIcon from "../../../assets/imgs/icons/list.svg";
import gridIcon from "../../../assets/imgs/icons/grid-alt.svg";
import { toast } from "react-toastify";
import { ToastifyError, ToastifySuccess } from "../../../toastify";
import DescriptionIcon from "../../../assets/imgs/icons/description.svg";
import UploadFiles from "../../UploadFiles";
import ConfirmExit from "../../Modal/confirmExit";

const VIEW_TYPES = [
  { key: "table", value: "Table View", icon: gridIcon },
  { key: "list", value: "List View", icon: listIcon },
];
const schema = yup.object({
  description: yup
    .string()
    .transform((value) => (value ? value : null))
    .nullable()
    .min(10)
    .max(160),
  salaries: yup.array().of(
    yup.object({
      salary_month: yup.string().nullable().required("This field is required"),
      payslips: yup.array().of(
        yup.object().shape({
          employee: yup.string().required("Id is required"),
          payment_date: yup
            .string()
            .transform((value) => (value ? value : ""))
            .required("This field is required"),
          payment_method: yup
            .number()
            .nullable()
            .required("This field is required")
            .positive("You should select payment method"),
          basic_salary: yup
            .number()
            .required("This field is required")
            .transform((val, value) => {
              return value === "" ? 0.0 : val;
            })
            .typeError("Amount must be a number")
            .test("format", "Invalid Number", (value) => {
              return /^-*[0-9,]+([.][0-9]{0,2})?$/.test(value || 0.0);
            })
            .max("100000000")
            .test("Is Zero?", "Amount can’t be zero", (value) => value !== 0)
            .positive("Amount can’t be a negative number"),
          allowance: yup
            .number()
            .transform((val, value) => {
              return value === "" ? 0.0 : val;
            })
            .typeError("Amount must be a number")
            .test("format", "Invalid Number", (value) => {
              return /^-*[0-9,]+([.][0-9]{0,2})?$/.test(value || 0.0);
            })
            .max("100000000")
            .min(0, "Amount can’t be a negative number"),
          additional: yup
            .number()
            .transform((val, value) => {
              return value === "" ? 0.0 : val;
            })
            .typeError("Amount must be a number")
            .test("format", "Invalid Number", (value) => {
              return /^-*[0-9,]+([.][0-9]{0,2})?$/.test(value || 0.0);
            })
            .max("100000000")
            .min(0, "Amount can’t be a negative number"),

          deduction: yup
            .number()
            .transform((val, value) => {
              return value === "" ? 0.0 : val;
            })
            .typeError("Amount must be a number")
            .test("format", "Invalid Number", (value) => {
              return /^-*[0-9,]+([.][0-9]{0,2})?$/.test(value || 0.0);
            })
            .max("100000000")
            .min(0, "Amount can’t be a negative number")
            .test(
              "is-greater-than-total",
              "Can not be greater than total salary",
              function (deduction) {
                const { additional, allowance, basic_salary } = this.parent;
                return deduction <= basic_salary + allowance + additional;
              }
            ),
          deduction_reason: yup
            .string()
            .max(140, "must be at most 140 characters")
            .when("deduction", {
              is: (deduction) => {
                return deduction;
              },
              then: yup.string().nullable().required("This field is required"),
            }),
        })
      ),
    })
  ),
});
const CreateSalaries = ({
  currentCompany,
  baseCurrencies,
  setBaseCurrencies,
}) => {
  const payrollsRef = useRef([]);
  const [minDate, setMinDate] = useState(null);
  const [selectedEmployees, setSelectedEmployees] = useState({});
  const callPromptSuccessUploadDocument = useModal(successUploadDocument);
  const callPromptModalSelectMonth = useModal(SelectMonth);
  const callPromptModalCreateNewEmployee = useModal(CreateNewEmployee);
  const callPromptModalSelectEmployee = useModal(SelectFromEmployeeList);
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      view_type: "table",
      salaries: [
        {
          salary_month: new Date(),
          document_date: new Date(),
          description: "",
        },
      ],
    },
  });

  const salaries = methods.watch(`salaries`) || [];

  const {
    fields: payrollFields,
    append: appendPayroll,
    insert: insertPayroll,
    remove: removePayroll,
  } = useFieldArray({
    control: methods.control,
    name: "salaries",
  });
  const [files, setFiles] = React.useState([]);
  const [isUploading, setIsUploading] = React.useState(false);
  const [error, setError] = useState("");
  const [assignees, setAssignees] = useState([]);
  const [selectedAssignee, setSelectAssignee] = React.useState([]);
  const [total, setTotal] = useState(0.0);
  const [currentCurrency, setCurrentCurrency] = useState(null);
  const [employeesOfCompany, setEmployeesOfCompany] = useState([]);
  const [isBlocking, setIsBlocking] = useState(true);
  const callPromptModalConfrimExit = useModal(ConfirmExit);

  const isMonthSelected = (date) => {
    const month = getMonth(date).toString() + getYear(date).toString();
    const selectedMonths = salaries.map(
      (payroll) =>
        getMonth(payroll.salary_month).toString() +
        getYear(payroll.salary_month).toString()
    );
    return !selectedMonths.includes(month);
  };

  const handleConfirmExit = async (nextLocation) => {
    try {
      setIsBlocking(false);
      const res = await callPromptModalConfrimExit({
        title: "Save changes before closing",
        message:
          "All changes will be lost if you close, are you sure you want to discard your changes?",
      });
      if (res) {
        navigate(nextLocation.location.pathname);
      } else {
        setIsBlocking(true);
      }
    } catch (e) {
      setIsBlocking(true);
      console.error(e);
    }
  };

  useBlocker(handleConfirmExit, isBlocking);

  useEffect(() => {
    setIsBlocking(methods.formState.isDirty);
  }, [methods.formState.isDirty]);

  const handleAddMonthPayroll = async () => {
    try {
      const res = await callPromptModalSelectMonth({
        title: "Select Payroll Month!",
        filterDate: isMonthSelected,
      });
      const payroll_month = res.payroll_month;
      const employees = await callPromptModalSelectEmployee({
        title: "Employees",
        employees: employeesOfCompany,
        selectedList: [],
      });
      appendPayroll({
        salary_month: payroll_month,
        document_date: new Date(),
        isDateMixed: null,
        isPaymentMethodMixed: null,
        payslips: employees.map((employee) => {
          return {
            selected: false,
            expanded: false,
            employee: employee.id,
            currency: currentCurrency.id,
            name: employee.name,
            basic_salary: employee.basic_salary || "",
            allowance: employee.allowance || "",
            payment_date: new Date(),
          };
        }),
      });
    } catch (error) {}
  };
  const getAssignee = async () => {
    try {
      const assignees = await UploadDocumentService.getAssigneeOfCompany(
        currentCompany.id
      );
      setAssignees(
        assignees.map((assignee) => {
          return { value: assignee.id, label: assignee.name };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
  const handleCreateNewEmployee = async () => {
    try {
      const res = await callPromptModalCreateNewEmployee({});
      setEmployeesOfCompany([...employeesOfCompany, res]);
    } catch (e) {
      console.error(e);
    }
  };

  const getEmployeesOfCompany = async () => {
    try {
      const employeesOfCompany = await EmployeeService.getEmployeesOfCompany(
        currentCompany?.id
      );
      setEmployeesOfCompany(employeesOfCompany);
    } catch (error) {
      console.error(error);
    }
  };

  const getFiscalYear = async () => {
    try {
      const fiscalYear = await UploadDocumentService.getFiscalYear(
        currentCompany.id
      );
      setMinDate(
        Math.max(
          new Date(fiscalYear?.lock_date),
          new Date(fiscalYear?.date_from)
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const getCurrencies = async () => {
    try {
      const currencies = await CurrencyService.getCurrencies(currentCompany.id);
      setBaseCurrencies(currencies);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    getEmployeesOfCompany();
    getFiscalYear();
    getAssignee();
    getCurrencies();
  }, []);

  useEffect(() => {
    setCurrentCurrency(baseCurrencies.find((currency) => currency.default));
  }, [baseCurrencies]);
  useEffect(() => {
    const subscription = methods.watch((value, { name, type }) => {
      let selectedEmployees = {};
      value.salaries.forEach((payroll, index) => {
        payroll.payslips?.forEach(
          (payslip) =>
            (payslip.total =
              parseFloat(payslip.basic_salary || 0.0) +
              parseFloat(payslip.allowance || 0.0) +
              parseFloat(payslip.additional || 0.0) -
              parseFloat(payslip.deduction || 0.0))
        );
        payroll.total = payroll.payslips?.reduce(
          (partialSum, a) => partialSum + parseFloat(a.total),
          0
        );
        const selected = payroll.payslips?.filter(
          (payslip) => payslip.selected
        );
        if (selected.length) selectedEmployees[index] = selected;
      });
      const totalOfRows = value.salaries.reduce(
        (partialSum, a) => partialSum + parseFloat(a.total) || 0.0,
        0
      );
      setTotal(totalOfRows);

      setSelectedEmployees({ ...selectedEmployees });
    });
    return () => subscription.unsubscribe();
  }, [methods.watch]);
  const navigate = useNavigate();
  const onSubmit = async (data) => {
    try {
      const companyId = currentCompany.id;
      const description = data.description || "";
      const salaries = data?.salaries.map((payroll) => {
        return {
          ...payroll,
          salary_month: format(new Date(payroll?.salary_month), "MMMM, yyyy"),
          payslips: payroll.payslips.map((payslip) => {
            return {
              ...payslip,
              payment_date: formatDate(payslip.payment_date),
            };
          }),
        };
      });
      const attachmentsDocument = files
        .filter((attachment) => attachment?.fileOnServer?.id)
        .map((attachment) => attachment?.fileOnServer?.id);

      const submit = async () => {
        await CreateSalariesService.createSalaries(
          companyId,
          description,
          selectedAssignee,
          attachmentsDocument,
          salaries
        );
      };
      await trackPromise(submit());
      await callPromptSuccessUploadDocument({
        time: 5000,
        message: "The Request was processed successfully",
        title: "Successful!",
        availableBalance: null,
      });
      navigate("/documents");
      // window.location.reload();
    } catch (error) {
      console.error(error);
      if (error?.detail) ToastifyError(error?.detail);
      setError(error);
    }
  };
  useEffect(() => {
    payrollsRef.current = payrollsRef.current.slice(0, payrollFields.length);
  }, [payrollFields]);
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="mb-60">
          <div className="flex flex-row items-center justify-between mt-5">
            <div className="flex flex-row">
              <div className="mr-4">
                <img src={EmployeeIcon} alt="" />
              </div>
              <div>
                <p>
                  Employees(
                  {salaries.reduce(
                    (partialSum, payroll) =>
                      partialSum + parseFloat(payroll.payslips?.length),
                    0
                  )}
                  )
                </p>
              </div>
            </div>
            <div>
              <div className="flex flex-row">
                <div className="mx-4">
                  {/* <RadioTabInput */}
                  {/*   name="view_type" */}
                  {/*   options={VIEW_TYPES} */}
                  {/*   serverErrors={error} */}
                  {/* /> */}
                </div>
                <SolidButton onClick={handleCreateNewEmployee}>
                  <div className="flex flex-row">
                    <div className="mr-4">
                      <img src={AddWhiteIcon} alt="" />
                    </div>
                    <div>Create New Employee</div>
                  </div>
                </SolidButton>
              </div>
            </div>
          </div>
          <div className="mt-16">
            {payrollFields.map((payroll, i) => {
              return (
                <PayrollMonth
                  key={i}
                  ref={(el) => {
                    payrollsRef.current[i] = el;
                  }}
                  index={i}
                  error={error}
                  currentCurrency={currentCurrency}
                  employeesOfCompany={employeesOfCompany}
                  minDate={minDate}
                />
              );
            })}
          </div>
          {selectedEmployees && Object.keys(selectedEmployees).length > 0 && (
            <div className="fixed left-0 flex justify-center w-screen h-16 bottom-36">
              <div className="flex flex-row justify-center w-full h-full max-w-screen-lg">
                <div className="flex flex-row items-center justify-between h-12 px-2 align-middle bg-arrowTop w-60 rounded-md">
                  <div>
                    <img
                      src={deselectIcon}
                      className="cursor-pointer"
                      alt=""
                      onClick={() => {
                        salaries.forEach((payroll, index) => {
                          payroll.payslips.forEach((payslip, j) => {
                            methods.setValue(
                              `salaries.${index}.payslips.${j}.selected`,
                              false
                            );
                          });
                        });
                      }}
                    />
                  </div>
                  <div className="text-white">
                    {Object.values(selectedEmployees).flat(1).length} Employee
                    selected
                  </div>
                  <div
                    className="cursor-pointer"
                    onClick={() => {
                      let removedPayslips = [];
                      const payrolls_copy = salaries.map((a) => {
                        return { ...a };
                      });
                      const allremovedPayslips = [];
                      salaries.forEach((payroll, index) => {
                        let removedPayslips = [];
                        payroll.payslips.forEach((payslip, i) => {
                          if (payslip.selected) {
                            removedPayslips.push([i, payslip]);
                            allremovedPayslips.push([index, i, payslip]);
                          }
                        });
                        payrollsRef.current[index].removePayslipOutside(
                          removedPayslips.map((payslip) => payslip[0])
                        );
                      });
                      ToastifyError(
                        <div className="flex flex-row justify-between w-full">
                          <div>Employees has been successfully deleted</div>
                          <div className="flex flex-row">
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                toast.dismiss();
                                allremovedPayslips.forEach((removedPayslip) => {
                                  payrollsRef.current[
                                    removedPayslip[0]
                                  ].insertPayslipOutside(
                                    removedPayslip[1],
                                    removedPayslip[2]
                                  );
                                });
                              }}
                            >
                              undo
                            </div>
                            <div className="mx-2">|</div>
                          </div>
                        </div>,
                        {
                          icon: <img src={trashIcon} alt="" />,
                        }
                      );
                    }}
                  >
                    <img src={trashIcon} alt="" />
                  </div>
                </div>
              </div>
            </div>
          )}
          {salaries.at(-1).payslips?.length > 0 && (
            <div className="flex flex-row justify-center">
              <div className="">
                <button
                  className="flex flex-row items-center justify-center"
                  onClick={handleAddMonthPayroll}
                >
                  <img src={WorkAddIcon} />
                  <label className="text-sm font-bold cursor-pointer font-roboto text-arrowTop ml-2">
                    Add a new month payroll
                  </label>
                </button>
              </div>
            </div>
          )}

          <div className="mt-4 border-t pt-16">
            <div className="mb-6">
              <div className="flex flex-row items-center mb-4">
                <div className="w-6 h-6">
                  <img src={DescriptionIcon} alt="" />
                </div>
                <div className="ml-3">Description</div>
              </div>
              <div className="w-2/3 h-full">
                <TextareaInput
                  rows="4"
                  id="description"
                  name={`description`}
                  placeholder="Add Description"
                  serverErrors={error}
                />
              </div>
            </div>
          </div>

          <div className="mt-4 pb-44 w-2/3">
            <UploadFiles
              files={files}
              setFiles={setFiles}
              isUploading={isUploading}
              setIsUploading={setIsUploading}
              error={error}
              title="Related Attachments"
            />
          </div>
          <div className="fixed bottom-0 left-0 flex justify-center w-screen bg-white h-36 shadow-footerShadow">
            <div className="w-full h-full ">
              <div className="flex flex-row items-center justify-center align-middle border-b-2 h-1/3">
                <div className="px-2 text-grayColorText">Total</div>
                <div className="text-xl font-bold">
                  {parseFloat(total).toFixed(2)} {currentCurrency?.code}
                </div>
              </div>
              <div className="container m-auto pt-6">
                <div className="flex flex-row items-center justify-between align-middle h-2/3">
                  <AssignToSomeone
                    options={assignees}
                    onChange={(selectedAssignee) => {
                      setSelectAssignee(selectedAssignee);
                    }}
                  />
                  <div className="flex flex-row items-center justify-center">
                    <div className="p-4 font-bold font-roboto text-arrowTop"></div>
                    <div className="w-40">
                      <SubmitButton
                        disabled={methods.formState.isSubmitting || isUploading}
                      >
                        Create
                      </SubmitButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    currentCompany: state.Company.currentCompany,
    baseCurrencies: state.Currencies.baseCurrencies,
  };
};
export default connect(mapStateToProps, {
  setBaseCurrencies,
})(CreateSalaries);
