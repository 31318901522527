import SearchIcon from "../../assets/imgs/icons/search.svg";
import filterIcon from "../../assets/imgs/icons/Filter.svg";
import { useState, useEffect } from "react";
import MultiSelectFilter from "../../components/Filters/MultiSelectFilter";
import DateRangeFilter from "../../components/Filters/DateRangeFilter";
import AmountRangeFilter from "../../components/Filters/AmountRangeFilter";
import { connect } from "react-redux";
import BankIcon from "../../assets/imgs/icons/bank.svg";
import CashIcon from "../../assets/imgs/icons/moneyCash.svg";
import UploadDocumentService from "../../services/UploadDocument";
import SolidButton from "../../components/Buttons/SolidButton";
import { displayText, PATMENT_STATUSES, STATUSES } from "./documentUtils";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const Filter = ({
  currentCompany,
  onApplyFilter,
  onSearchChange,
  searchValue,
}) => {
  const [paymentMethods, setPaymentMethods] = useState([]);
  const [partners, setPartners] = useState([]);
  const [constCenters, setConstCenters] = useState([]);
  const [categories, setCategories] = useState([]);
  const [creators, setCreators] = useState([]);
  const [countFilters, setCountFilters] = useState(0);
  const location = useLocation();
  const [showFilter, setShowFilter] = useState(false);
  const [filters, setFilters] = useState(queryString.parse(location.search));

  const onChangeFilter = (name, value) => {
    setFilters({ ...filters, [name]: value });
  };

  const onChangeDateRangeFilter = (value) => {
    setFilters({ ...filters, ...value });
  };
  const handleClickApplyFilter = () => {
    onApplyFilter(filters);
  };

  const handleClearFilter = () => {
    setFilters({});
    setShowFilter(false);
    onApplyFilter({});
  };

  const handleSearchChange = (e) => {
    const value = e.target.value;
    onSearchChange(value);
  };
  const getCategories = async () => {
    try {
      const categories = await UploadDocumentService.getCategories();
      setCategories(
        categories.map((category) => {
          return { value: category.id, label: category.name };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
  const getCreators = async () => {
    try {
      const creators = await UploadDocumentService.getAssigneeOfCompany(
        currentCompany.id
      );
      setCreators(
        creators.map((creator) => {
          return { value: creator.id, label: creator.name };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
  const getPaymentMethods = async () => {
    try {
      const paymentMethods = await UploadDocumentService.getPaymentMethods(
        currentCompany.id
      );
      setPaymentMethods(
        paymentMethods.map((paymentMethod) => {
          return {
            value: paymentMethod.id,
            label: paymentMethod.name,
            icon: paymentMethod.type === "cash" ? CashIcon : BankIcon,
          };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const handleAddFilterClick = () => {
    setShowFilter(!showFilter);
  };
  useEffect(() => {
    getCategories();
    getPaymentMethods();
    getCostCenters();
    getCreators();
    getPartners();
  }, []);

  useEffect(() => {
    const filters_copy = { ...filters };
    if (filters_copy["amount_to"] && filters_copy["amount_from"]) {
      delete filters_copy["amount_to"];
    }
    if (filters_copy["date_to"] && filters_copy["date_from"]) {
      delete filters_copy["date_to"];
    }
    setCountFilters(Object.entries(filters_copy).filter(([_, v]) => v)?.length);
  }, [filters]);
  const getCostCenters = async () => {
    try {
      const constCenters = await UploadDocumentService.getCostCenters(
        currentCompany.id
      );
      setConstCenters(
        constCenters.map((constCenter) => {
          return { value: constCenter.id, label: constCenter.name };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  const getPartners = async () => {
    try {
      const partners = await UploadDocumentService.getPartners(
        currentCompany.id
      );
      setPartners(
        partners.map((partner) => {
          return { value: partner.id, label: partner.name };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <div>
      <div className="flex flex-row mt-4">
        <div className="flex flex-row p-2 border-2">
          <img src={SearchIcon} className="" />
          <input
            placeholder="Request ID or Doc. Ref #"
            className="ml-1 border-none outline-none"
            onChange={handleSearchChange}
            value={searchValue}
          />
        </div>
        <div className="ml-4">
          <SolidButton
            style={{ backgroundColor: "#2D3748" }}
            onClick={handleAddFilterClick}
          >
            <div className="flex flex-row items-center">
              <img src={filterIcon} className="" />
              <div className="ml-2">Add Filter</div>
              {countFilters > 0 && (
                <div className="flex items-center justify-center w-6 h-6 ml-4 text-black bg-white rounded-full">
                  {countFilters}
                </div>
              )}
            </div>
          </SolidButton>
        </div>
        {countFilters > 0 && (
          <div
            className="p-4 font-bold cursor-pointer font-roboto text-arrowTop"
            onClick={handleClearFilter}
          >
            Clear filters
          </div>
        )}
      </div>
      {showFilter && (
        <div className="mt-4">
          <div className="flex flex-row flex-wrap items-center justify-start">
            <DateRangeFilter
              name="document_date"
              title="Document Date"
              from_name="date_from"
              to_name="date_to"
              onChangeFilter={onChangeDateRangeFilter}
              filters={filters}
            />
            <MultiSelectFilter
              options={STATUSES}
              name="status"
              title="Status"
              labelClassName="px-2 py-2 text-xs font-bold text-white max-w-max rounded-md leading-3"
              useBgColor={true}
              onChangeFilter={onChangeFilter}
              filters={filters}
            />
            <AmountRangeFilter
              from_name="amount_from"
              to_name="amount_to"
              title="Amount"
              onChangeFilter={onChangeFilter}
              filters={filters}
            />
            {/*  */}
            {/* <MultiSelectFilter */}
            {/*   options={creators} */}
            {/*   name="user_name" */}
            {/*   title="User Name" */}
            {/*   onChangeFilter={onChangeFilter} */}
            {/* /> */}

            <MultiSelectFilter
              options={paymentMethods}
              name="payment_methods"
              title="Payment Method"
              onChangeFilter={onChangeFilter}
              filters={filters}
            />

            <MultiSelectFilter
              options={partners}
              name="partners"
              title="Partner"
              onChangeFilter={onChangeFilter}
              filters={filters}
            />

            <MultiSelectFilter
              options={PATMENT_STATUSES}
              name="payment_status"
              title="Payment Status"
              useDotColor={true}
              onChangeFilter={onChangeFilter}
              filters={filters}
            />
            <MultiSelectFilter
              options={creators}
              name="creators"
              title="Created By"
              onChangeFilter={onChangeFilter}
              filters={filters}
            />

            <MultiSelectFilter
              options={categories}
              name="categories"
              title="Category"
              onChangeFilter={onChangeFilter}
              filters={filters}
            />

            <MultiSelectFilter
              options={constCenters}
              name="cost_centers"
              title="Cost Center"
              onChangeFilter={onChangeFilter}
              filters={filters}
            />
          </div>
          <div className="flex flex-row mx-2 my-2">
            <div>
              <SolidButton onClick={handleClickApplyFilter}>Apply</SolidButton>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentCompany: state.Company.currentCompany,
  };
};

export default connect(mapStateToProps, {})(Filter);
