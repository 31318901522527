import { useEffect, useState, useRef } from "react";
import { useFormContext, Controller } from "react-hook-form";
import ErrorMessage from "../../ErrorMessage";
import arrowDropDown from "../../../assets/imgs/icons/arrow_drop_down.svg";
import ErrorInput from "../Error";
import countryCodes from "./CountryCodes.json";
import SearchIcon from "../../../assets/imgs/icons/search.svg";
import RadioButtonCheckedIcon from "../../../assets/imgs/icons/radio_button_checked.svg";
import _ from "lodash";
const CountryMobileInput = ({ name, serverErrors, ...props }) => {
  const {
    register,
    formState: { errors },
    control,
  } = useFormContext();

  const [country, setCountry] = useState(
    countryCodes.find((country) => country.code === "AE")
  );
  const [isOpen, setIsOpen] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [virtualOptions, setVirtualOptions] = useState([]);
  const [showedOptions, setShowedOptions] = useState([]);

  const ref = useRef(null);
  const onClickOutside = () => {
    setIsOpen(false);
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);

  const searchOption = (e) => {
    const key = e.target.value;
    setSearchValue(key);
    setShowedOptions(
      virtualOptions.filter((option) =>
        option.name.toLowerCase().includes(key.toLowerCase())
      )
    );
  };

  useEffect(() => {
    setShowedOptions(virtualOptions);
    setSearchValue("");
  }, [virtualOptions]);

  useEffect(() => {
    setVirtualOptions(
      countryCodes.map((option) => {
        return { ...option };
      })
    );
  }, [countryCodes]);
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { onChange, value, ref } }) => {
        return (
          <div className="flex flex-col w-full">
            <div className="w-full ">
              <div className="relative w-full">
                <input
                  type="tel"
                  className={`w-full pl-32 mb-1 placeholder:text-inputGrayColor placeholder:text-sm ${
                    _.get(errors, name)
                      ? "text-errorColor border-errorColor focus:text-errorColor focus:border-errorColor focus:bg-white placeholder:text-errorColor"
                      : ""
                  }`}
                  placeholder="Mobile Number"
                  onChange={(e) => {
                    onChange(country.dial_code + e.target.value);
                  }}
                  {...props}
                />
                <div
                  className="absolute top-0 left-0 w-28 h-full pb-1 mb-1 font-bold"
                  onClick={toggleOpen}
                >
                  <div className="flex items-center justify-center w-full h-full border border-borderColor rounded-l-md">
                    <div className="flex flex-row items-center justify-between cursor-pointer px-2">
                      <div>
                        <img
                          src={`https://flagcdn.com/h40/${country.code.toLowerCase()}.png`}
                          alt=""
                          className="h-5 rounded-sm w-7"
                        />
                      </div>
                      <div className="ml-2">{country.dial_code}</div>
                      <div className="ml-2">
                        <img src={arrowDropDown} alt="" />
                      </div>
                    </div>
                  </div>
                </div>

                {isOpen && (
                  <div
                    className="absolute py-2 bg-white rounded-md shadow-dropdownMenu overflow-hidden z-20"
                    ref={ref}
                    style={{ width: "20rem", left: 0, top: 45 }}
                  >
                    <div className="flex flex-row w-full h-full px-2 border-b-2">
                      <img src={SearchIcon} />
                      <input
                        className="w-full px-2 py-2 ml-2 border-0 focus:outline-none"
                        placeholder="Search"
                        value={searchValue}
                        onChange={searchOption}
                      />
                    </div>
                    <div className="overflow-auto max-h-60">
                      {showedOptions.length === 0 && (
                        <div className="py-4 text-sm font-roboto">
                          <div className="flex flex-row justify-center">
                            <div>No Option</div>
                          </div>
                        </div>
                      )}
                      {showedOptions.length > 0 && (
                        <div>
                          {showedOptions.map((option) => (
                            <div
                              className="py-4 text-sm cursor-pointer font-roboto"
                              onClick={() => {
                                setCountry(option);
                                setIsOpen(false);
                              }}
                            >
                              <div className="flex flex-row items-center justify-between pr-4">
                                <div className="flex flex-row items-center">
                                  <div className="mx-2">
                                    {option.value !== country.code && (
                                      <span className="inline-block w-4 h-4 border rounded-full border-grey flex-no-shrink"></span>
                                    )}
                                    {option.value === country.code && (
                                      <img
                                        src={RadioButtonCheckedIcon}
                                        alt=""
                                      />
                                    )}
                                  </div>
                                  <div className="mr-2">
                                    <img
                                      src={`https://flagcdn.com/h40/${option.code.toLowerCase()}.png`}
                                      alt=""
                                      className="h-5 rounded-sm w-7"
                                    />
                                  </div>
                                  <div>{option.name}</div>
                                </div>
                                <div className="font-bold">
                                  {option.dial_code}
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div>
              <ErrorInput
                name={name}
                errors={errors}
                serverErrors={serverErrors}
              />
            </div>
          </div>
        );
      }}
    />
  );
};

export default CountryMobileInput;
