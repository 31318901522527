import { useState, useMemo, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import DocumentsService from "../../services/Documents";
import Tabs from "../../components/Tabs";
import TitleField from "../../components/View/Field/Title";
import ValueFeild from "../../components/View/Field/value";
import ClientStatus from "../Documents/ClientStatus";
import PaymentStatus from "../Documents/PaymentStatus";
import DocumentAttachments from "../Documents/DocumentAttachments";
import ConfirmCancel from "../../components/Modal/ConfirmDelete";
import { trackPromise } from "react-promise-tracker";
import Spinner from "../../components/Spinner";
import UserValue from "../../components/View/Field/UserValue";
import CalendarIcon from "../../assets/imgs/icons/calendar.svg";
import { ReactComponent as CancelIcon } from "../../assets/imgs/icons/cancel.svg";
import { ReactComponent as CheckIcon } from "../../assets/imgs/icons/checkBlank.svg";
import { ReactComponent as ErrorIcon } from "../../assets/imgs/icons/errorIcon.svg";
import ActionButton from "../../components/Buttons/ActionButton";
import MoneyTranferService from "../../services/MoneyTransfers";
import { connect } from "react-redux";
import useModal from "../../components/Modal/useModal";
import Container from "../../components/Container";

const MoneyTransferDetails = ({ currentUser }) => {
  const callPromptModalConfrimCancel = useModal(ConfirmCancel);
  console.log(currentUser);
  const params = useParams();
  const documentId = params.documentId;
  const { state } = useLocation();
  const [document, setDocument] = useState();

  const getDocument = async (documentId) => {
    const document = await DocumentsService.getDocument(documentId);
    setDocument(document);
  };

  useEffect(() => {
    trackPromise(getDocument(documentId), "document");
  }, [documentId]);

  const confirmMoneyTransfer = async () => {
    const document = await MoneyTranferService.confirmMoneyTransfer(documentId);
    getDocument(documentId);
  };

  const declineMoneyTransfer = async () => {
    const document = await MoneyTranferService.delcineMoneyTransfer(documentId);
    getDocument(documentId);
  };

  const cancelMoneyTransfer = async () => {
    const document = await MoneyTranferService.cancelMoneyTransfer(documentId);
    getDocument(documentId);
  };
  const confirm = async () => {
    trackPromise(confirmMoneyTransfer(), "document");
  };

  const decline = async () => {
    trackPromise(declineMoneyTransfer(), "document");
  };

  const cancel = async () => {
    try {
      const res = await callPromptModalConfrimCancel({
        title: "Cancel Transfer",
        message: `You are canceling the transfer, Are you sure?`,
        cancelButtonTitle: "Don't cancel",
        submitButtonTitle: "Yes, Cancel the transfer",
      });
      if (res) {
        trackPromise(cancelMoneyTransfer(), "document");
      }
    } catch (error) {}
  };
  const cancelMessage = (title) => {
    return (
      <div className="p-4 mt-2 bg-redBackground">
        <div className="flex flex-row items-center px-4">
          <div className="pr-2">
            <CancelIcon className="error" />
          </div>
          <div>{title}</div>
        </div>
      </div>
    );
  };

  const warningMessage = (title) => {
    return (
      <div className="p-4 mt-2 bg-orangeBackground">
        <div className="flex flex-row items-center px-4">
          <div className="pr-2">
            <ErrorIcon className="warning" />
          </div>
          <div>{title}</div>
        </div>
      </div>
    );
  };
  const handleMessage = () => {
    if (document?.erp_data?.client_status === "REJECTED") {
      switch (currentUser?.erp_id) {
        case document?.erp_data.creator_id:
          return cancelMessage("This transfer has been cancelled.");
          break;
        case document?.erp_data.approver_id:
          return cancelMessage("Declined the transfer request.");
          break;
        default:
          return null;
      }
    } else if (document?.erp_data?.client_status === "PENDING_APPROVAL") {
      switch (currentUser?.erp_id) {
        case document?.erp_data.creator_id:
          return warningMessage(
            "The Document is Pending Confirmation, You will be notified if confirmed, or rejected"
          );
          break;
        default:
          return null;
      }
    }
  };
  return (
    <div className="container">
      {document && (
        <div>
          <Container>
            <div className="flex pb-4 flex-row items-center justify-between mt-8">
              <div className="flex flex-row items-center">
                <div className="flex flex-col pr-32">
                  <TitleField title="Request ID" />
                  <div className="mt-2">
                    <ValueFeild
                      value={document?.erp_data?.name}
                      className="font-bold"
                    />
                  </div>
                </div>

                <div className="flex flex-col">
                  <TitleField title="Status" />
                  <div className="mt-2">
                    <ClientStatus status={document?.erp_data.client_status} />
                  </div>
                </div>
              </div>
              <div>
                {document?.erp_data?.client_status === "PENDING_APPROVAL" &&
                  document.erp_data.creator_id === currentUser?.erp_id && (
                    <ActionButton
                      className="text-black bg-white border"
                      onClick={cancel}
                      Icon={CancelIcon}
                    >
                      Cancel Transfer
                    </ActionButton>
                  )}
              </div>
            </div>
          </Container>
          {handleMessage()}
          <div className="mt-2 border-t border-b ">
            <Container>
              <div className="flex flex-row items-center justify-start py-8">
                <div className="flex flex-col pr-32">
                  <TitleField title="Creation Date & Time" />
                  <div className="mt-2">
                    <ValueFeild
                      value={document?.erp_data?.created_at}
                      icon={CalendarIcon}
                    />
                  </div>
                </div>

                <div className="flex flex-col">
                  <TitleField title="Transfer Date" />
                  <div className="mt-2">
                    <ValueFeild
                      value={document?.erp_data?.date}
                      showPending={true}
                      icon={CalendarIcon}
                    />
                  </div>
                </div>
              </div>
            </Container>
          </div>
          <Container>
            <div className="my-4 border-b pb-4">
              <div className="font-bold">From</div>
              <div className="w-2/3">
                <table className="table-auto">
                  <tbody className="">
                    <tr className="">
                      <td className="py-2 pr-44">
                        <TitleField title="Transfer From" />
                      </td>
                      <td>
                        <UserValue name={document?.erp_data?.issuer} />
                      </td>
                    </tr>

                    <tr>
                      <td className="py-2 pr-44">
                        <TitleField title="From (Payment Method)" />
                      </td>
                      <td>
                        <ValueFeild
                          value={document?.erp_data?.payment_method}
                          className="font-bold"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Container>
          <Container>
            <div className="mt-4">
              <div className="font-bold">To</div>
              <div className="w-2/3">
                <table className="table-auto">
                  <tbody className="">
                    <tr className="">
                      <td className="py-2 pr-44">
                        <TitleField title="Transfer To" />
                      </td>
                      <td>
                        <UserValue name={document?.erp_data?.beneficiary} />
                      </td>
                    </tr>

                    <tr>
                      <td className="py-2 pr-44">
                        <TitleField title="To (Payment Method)" />
                      </td>
                      <td>
                        <ValueFeild
                          value={document?.erp_data?.to_payment_method}
                          className="font-bold"
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="py-2 pr-44">
                        <TitleField title="Transfer Amount" />
                      </td>
                      <td>
                        <ValueFeild
                          value={document?.erp_data?.amount_with_currency}
                          className="font-bold"
                          showPending
                        />
                      </td>
                    </tr>

                    <tr>
                      <td className="py-2 pr-44">
                        <TitleField title="Payment Status" />
                      </td>
                      <td>
                        <PaymentStatus
                          status={document?.erp_data?.payment_status}
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Container>
          {document?.erp_data?.is_cash_transfer &&
            document.erp_data.client_status === "PENDING_APPROVAL" &&
            document.erp_data.approver_id === currentUser?.erp_id && (
              <div className="flex flex-row items-center w-full px-8 py-4 mt-4 bg-bgGray">
                <div className="px-4">
                  <ActionButton
                    className="text-black bg-white"
                    onClick={decline}
                    Icon={CancelIcon}
                  >
                    Decline
                  </ActionButton>
                </div>
                <div className="px-4">
                  <ActionButton
                    className="text-white bg-greenColor"
                    onClick={confirm}
                    Icon={CheckIcon}
                  >
                    Confirm
                  </ActionButton>
                </div>
              </div>
            )}
          <div className="py-2 mt-8 border-t">
            <Container>
              <DocumentAttachments
                attachments={document?.erp_data?.attachments || []}
              />
            </Container>
          </div>
        </div>
      )}
      <Spinner area="document" />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    currentUser: state.user.currentUser,
  };
};

export default connect(mapStateToProps, {})(MoneyTransferDetails);
