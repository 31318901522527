import React, { useCallback, useEffect } from "react";
import { ReactOneDriveFilePicker } from "react-onedrive-filepicker";
import DropboxChooser from "react-dropbox-chooser";
import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";

import ErrorMessage from "../ErrorMessage";
import FileUploaderProgress from "../FileUploaderProgress";

import CloudUploadIcon from "../../assets/imgs/icons/cloud-upload.svg";
import OneDriveIcon from "../../assets/imgs/icons/OneDrive.svg";
import DropBoxIcon from "../../assets/imgs/icons/DropBox.svg";
import { ToastifyError, ToastifySuccess } from "../../toastify";
import { useLocation, useSearchParams } from "react-router-dom";
import axios from "axios";
import trashIcon from "../../assets/imgs/icons/trash.svg";
const extensions = [
  ".png",
  ".jpeg",
  ".jpg",
  ".doc",
  ".docx",
  ".pdf",
  ".xls",
  ".xlsx",
  ".xlsm",
];

const MAX_COUNT_FILE = 10;
const MAX_FILE_SIZE_MB = 10;
const UploadFiles = ({
  files,
  setFiles,
  setIsUploading,
  isUploading,
  error,
  title,
}) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [count, setCount] = React.useState(0);
  const onDrop = useCallback((acceptedFiles, fileRejections) => {
    if (fileRejections.length) {
      ToastifyError(
        `Allowed extensions are ${extensions.toString().toUpperCase()}`
      );
    }
    addNewFiles(acceptedFiles);
  });
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: extensions.toString(),
  });
  const setFileIsUploading = async () => {
    setIsUploading(files.some((file) => file?.isUploading));
  };
  const removeFile = (index) => {
    const file = files[index];
    const newFiles = [...files];
    newFiles[index] = null;
    setFiles(newFiles);
    setCount(count - 1);
    ToastifyError(
      <div className="w-full flex flex-row justify-between">
        <div>The file was deleted</div>
        <div className="flex flex-row">
          <div
            className="cursor-pointer"
            onClick={() => {
              toast.dismiss();
              const newFiles = [...files];
              newFiles[index] = file;
              setFiles(newFiles);
            }}
          >
            undo
          </div>
          <div className="mx-2">|</div>
        </div>
      </div>,
      {
        icon: <img src={trashIcon} alt="" />,
      }
    );
  };
  const addNewFiles = (newFiles) => {
    const newCountFiles = count + newFiles.length;
    if (newCountFiles > MAX_COUNT_FILE) {
      ToastifyError(`You can upload only a maximum of ${MAX_COUNT_FILE} files`);
      return false;
    }
    const filteredFiles = newFiles.filter((file) => {
      const fileSize = Math.round(file.size / 1024);
      return fileSize <= MAX_FILE_SIZE_MB * 1000;
    });
    if (
      newFiles.some((file) => {
        const fileSize = Math.round(file.size / 1024);
        return fileSize > MAX_FILE_SIZE_MB * 1000;
      })
    ) {
      ToastifyError(
        `Your attachment is bigger than the ${MAX_FILE_SIZE_MB} MB file size limit. Try a smaller file size`
      );
    }

    setFiles([...files, ...filteredFiles]);
    setCount(newCountFiles);
  };
  useEffect(() => {
    localStorage.setItem("location", location.hash);
  }, [location]);
  return (
    <div className="w-full">
      <div className="font-semibold font-poppins leading-6 ">{title}</div>
      <div className="mt-3 text-sm ArrowBottomIcontext-base text-grayColor leading-5 font-roboto">
        If more than one attachment is uploaded, they will be related to each
        other. You can upload a maximum of 10 files, 10MB each
      </div>
      <div className="mt-5" {...getRootProps()}>
        <div className="relative flex items-center justify-center w-full border border-dashed cursor-pointer h-18 border-grayColor bg-bgGray rounded-md ">
          <input {...getInputProps()} />
          {!isDragActive ? (
            <div className="text-center absolute top-200 right-0 left-0 m-auto font-roboto text-sm">
              <div className="flex flex-row items-center justify-center">
                <img src={CloudUploadIcon} alt="" className="mr-3" />
                <h4>
                  Drop files to attach, or{" "}
                  <span className="text-arrowTop">browse</span>
                </h4>
              </div>
            </div>
          ) : (
            <div className="flex items-center justify-center w-full h-full border border-dashed border-grayColor bg-bgGray">
              <div>Drag files here</div>
            </div>
          )}
        </div>
      </div>
      <div className="flex flex-row justify-center w-full mt-5">
        or import from
        <ReactOneDriveFilePicker
          clientID="c0939e12-16bb-4f62-a539-0feb3bf95253"
          action="share"
          multiSelect={true}
          advanced={{ filter: `folder,${extensions.toString()}` }}
          onSuccess={(result) => {
            const files = result.value.map((file) => ({
              name: file.name,
              size: file.size,
              link: file["@microsoft.graph.downloadUrl"],
            }));
            addNewFiles(files);
          }}
          onError={(result) => {
            ToastifyError(`something went wrong in OneDrive`);
            try {
              axios.post(
                "https://login.microsoftonline.com/common/oauth2/v2.0/logoutsession"
              );
            } catch (e) {
              console.error(e);
            }
          }}
        >
          <div className="flex flex-row items-center mx-4 cursor-pointer">
            <img src={OneDriveIcon} alt="" className="mr-1" />
            <span>OneDrive</span>
          </div>
        </ReactOneDriveFilePicker>
        <DropboxChooser
          appKey={"b1xb65ey7jm3xtq"}
          success={(result) => {
            const files = result.map((file) => ({
              name: file.name,
              size: file.bytes,
              link: file.link,
            }));
            addNewFiles(files);
          }}
          error={() => ToastifyError(`something went wrong in Dropbox`)}
          multiselect={true}
          linkType="direct"
          placeholder="Select Cost Center"
          extensions={extensions}
        >
          <div className="flex flex-row items-center cursor-pointer">
            <img src={DropBoxIcon} alt="" className="mr-1" />
            <div className="dropbox-button">DropBox</div>
          </div>
        </DropboxChooser>
      </div>
      <div>
        {error?.attachments && (
          <ErrorMessage>{error?.attachments}</ErrorMessage>
        )}
      </div>
      <div>
        {count > 0 && (
          <div className="mt-8 font-semibold font-poppins leading-6">
            Files ({count})
          </div>
        )}
        <div className="mt-4">
          {files?.map((file, index) => {
            return file ? (
              <FileUploaderProgress
                key={index}
                file={file}
                removeFile={removeFile}
                index={index}
                setFileIsUploading={setFileIsUploading}
              />
            ) : (
              ""
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default UploadFiles;
