import { useEffect, useState } from "react";

const ValueFeild = ({ value, icon, showPending, ...props }) => {
  const [pending, setPending] = useState(false);
  useEffect(() => {
    if (showPending && !value) {
      setPending(true);
    }
  }, [showPending]);
  return (
    <div className="flex flex-row items-center">
      {icon && (
        <div className="mr-2">
          <img src={icon} />
        </div>
      )}
      <div>
        {pending ? (
          <span className="text-grayColorText2">Pending</span>
        ) : (
          <div className="font-medium text-sm	" {...props}>
            {value}
          </div>
        )}
      </div>
    </div>
  );
};

export default ValueFeild;
