import "./style.scss";

const Avatar = ({ avatar, colors }) => {
  return (
    <div
      style={{
        backgroundColor: colors?.bg || "#25abc31f",
        color: colors?.color,
      }}
      className="avatar-comp"
    >
      <div className="mj valign-text-middle roboto-bold-pacific-blue-16px">
        {avatar}
      </div>
    </div>
  );
};

export default Avatar;
