import { useFormContext } from "react-hook-form";
import ErrorInput from "../Error";

import "./styles.css";
const RadioTabInput = ({ name, options, serverErrors, ...props }) => {
  const {
    register,
    watch,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="w-full">
      <div className="segmented-control">
        {options.map((option) => {
          return (
            <div className="w-full p-1" key={option.key}>
              <label htmlFor={option.key}>
                {watch(name) == option.key ? (
                  <div className="option">
                    <div className="w-full label roboto-medium-outer-space-14px">
                      <div className="flex flex-row items-center">
                        {option.icon && <img src={option.icon} alt="" />}
                        <div>
                          <span className="roboto-medium-outer-space-14px pl-1">
                            {option.value}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="option-1">
                    <div className="w-full label-1 roboto-medium-outer-space-14px">
                      <div className="flex flex-row items-center">
                        {option.icon && <img src={option.icon} alt="" />}
                        <div>
                          <span className="roboto-medium-outer-space-14px pl-1">
                            {option.value}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </label>
              <input
                id={option.key}
                className="hidden"
                type="radio"
                name={name}
                value={option.key}
                hidden={true}
                {...register(name, {})}
                {...props}
              />
            </div>
          );
        })}
      </div>
      <div>
        <ErrorInput name={name} errors={errors} serverErrors={serverErrors} />
      </div>
    </div>
  );
};

export default RadioTabInput;
