import { components } from "react-select";
const SingleValue = (props) => {
  const { isSelected, data, children } = props;
  return (
    <components.SingleValue {...props}>
      <div>
        <div className="flex flex-row items-center place-content-start cursor-pointer text-xl">
          {data?.icon && (
            <div className="mr-2">
              <img src={data?.icon} alt="" />
            </div>
          )}
          <div className="text-sm font-medium font-roboto">{children}</div>
        </div>
      </div>
    </components.SingleValue>
  );
};

export default SingleValue;
