import { useState, useEffect } from "react";
import React, { useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import SubmitButton from "../Buttons/Submit";
import CancelIcon from "../../assets/imgs/icons/cancel.svg";
import SearchIcon from "../../assets/imgs/icons/SearchBlue.svg";
import { generateAvatar } from "../../utils";
import Avatar from "../Avatar";
import Employee from "../../services/Employee";
import DeleteEmployee from "./DeleteEmployee";
import useModal from "../Modal/useModal";

const schema = yup.object({}).required();

const SelectFromEmployeeList = ({
  title,
  employees,
  selectedList,
  onResolve,
  onReject,
}) => {
  const backRef = useRef(null);
  const [error, setError] = useState("");
  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const callPromptModalDeleteEmployee = useModal(DeleteEmployee);
  const [virtualOptions, setVirtualOptions] = useState([]);
  const [showedOptions, setShowedOptions] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [countSelected, setCountSelected] = useState(0);
  const [allChecked, setAllChecked] = useState();
  const toggleOption = (e, optionId) => {
    setVirtualOptions(
      virtualOptions.map((option) => {
        return option.id === optionId
          ? { ...option, checked: e.target.checked }
          : { ...option };
      })
    );
  };

  const toggleAll = (e) => {
    setVirtualOptions(
      virtualOptions.map((option) => {
        return showedOptions.includes(option)
          ? { ...option, checked: e.target.checked }
          : { ...option };
      })
    );
  };

  const Cancel = () => {
    onReject();
  };
  const searchOption = (e) => {
    const key = e.target.value;
    setSearchValue(key);
  };

  const isAllChecked = () => {
    return virtualOptions.every((option) => option.checked);
  };
  useEffect(() => {
    setAllChecked(isAllChecked);
    setShowedOptions(
      virtualOptions.filter((option) =>
        option.name.toLowerCase().includes(searchValue?.toLowerCase())
      )
    );
    const checkedList = virtualOptions.filter((option) => option.checked);

    if (checkedList.length) {
      setCountSelected(checkedList.length);
    } else {
      setCountSelected(0);
    }
  }, [virtualOptions, searchValue]);

  const avatarColor = [
    { bg: "#CCEEF3", color: "#00ACC4" },
    { bg: "#FDE6D2", color: "#F5831F" },
    { bg: "#CCEEF3", color: "#005D85" },
    { bg: "#FDD9D9", color: "#EF2D28" },
  ];

  function randomIntFromInterval(min, max) {
    // min and max included
    return Math.floor(Math.random() * (max - min + 1) + min);
  }

  useEffect(() => {
    setVirtualOptions(
      employees
        .filter((employee) => !selectedList.includes(employee.user.id))
        .map((option) => {
          return {
            ...option,
            checked: selectedList.includes(option.user.id),
            id: option.user.id,
            name: option.user.first_name + " " + option.user.last_name,
            colors: avatarColor[randomIntFromInterval(1, 4)],
            avatar: generateAvatar(
              option.user.first_name + " " + option.user.last_name
            ),
          };
        })
    );
  }, [employees]);

  const onSubmit = async (data) => {
    try {
      const checkedList = virtualOptions.filter((option) => option.checked);
      onResolve(checkedList);
    } catch (error) {
      setError(error);
    }
  };
  const handleDeleteEmployee = async (employee) => {
    try {
      const res = await callPromptModalDeleteEmployee({
        employee: employee,
      });

      setVirtualOptions(
        virtualOptions.filter((option) => option.id !== res.id)
      );
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <div
      ref={backRef}
      isOpen={backRef}
      className="fixed inset-0 overflow-y-auto"
      // style={{ zIndex: 1000 }}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block overflow-hidden text-left align-bottom bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
              <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div className="w-full sm:flex sm:items-start ">
                  <div className="w-full ">
                    <div className="flex flex-row justify-between py-4 border-b">
                      <h3
                        className="text-lg font-medium text-gray-900 leading-6"
                        id="modal-title"
                      >
                        {title}
                      </h3>
                      <div
                        className="cursor-pointer"
                        onClick={() => onReject()}
                      >
                        <img src={CancelIcon} alt="" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row w-full h-full p-2 border-b-2">
                  <img src={SearchIcon} />
                  <input
                    className="w-full px-2 py-2 ml-2 border-0 focus:outline-none"
                    placeholder="Search"
                    value={searchValue}
                    onChange={searchOption}
                  />
                </div>
                <div
                  style={{ minHeight: "20rem" }}
                  className="overflow-y-auto  max-h-552"
                >
                  {showedOptions.length === 0 ? (
                    <div className="py-14 flex justify-center items-center">
                      <p className="text-black">No matching record</p>{" "}
                    </div>
                  ) : (
                    showedOptions.map((option) => {
                      return (
                        <div className="py-2 py-4 text-sm font-roboto">
                          <div className="flex flex-row items-center justify-between ">
                            <div className="flex flex-row items-center justify-center">
                              <div className="px-4">
                                <input
                                  type="checkbox"
                                  className="focus:outline-none rounded-sm "
                                  checked={option.checked}
                                  onChange={(e) => {
                                    return toggleOption(e, option.id);
                                  }}
                                />
                              </div>
                              <div className="pr-4">
                                <Avatar
                                  colors={option.colors}
                                  avatar={option.avatar}
                                />
                              </div>
                              <div>
                                <div>{option.name}</div>
                                <div className="text-grayColorText">
                                  {option.user.email || option.user.mobile}
                                </div>
                              </div>
                            </div>
                            <div className="font-bold font-roboto">
                              <div>{option.amount_formated}</div>
                            </div>
                            <div>
                              <div
                                className="px-8 text-sm font-bold cursor-pointer font-roboto hover:bg-redHover px-2 py-3 rounded-md text-redColor"
                                onClick={() => handleDeleteEmployee(option)}
                              >
                                Delete
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
                <div className="flex flex-row items-center justify-between mt-10">
                  <div className="flex flex-row items-center">
                    <div className="px-2">
                      <input
                        type="checkbox"
                        onChange={toggleAll}
                        checked={allChecked}
                      />
                    </div>
                    <div>Select All Employees</div>
                  </div>
                  <div className="flex flex-row items-center">
                    <div
                      className="px-8 text-sm font-bold cursor-pointer font-roboto text-grayColor"
                      onClick={Cancel}
                    >
                      Cancel
                    </div>
                    <div className="w-36">
                      {countSelected < 1 && (
                        <div className="flex items-center justify-center p-4 text-sm font-bold bg-gray-300 text-grayColorText">
                          Add Employees
                        </div>
                      )}
                      {countSelected >= 1 && (
                        <SubmitButton>Add Employees</SubmitButton>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

export default SelectFromEmployeeList;
