import SolidButton from "../../components/Buttons/SolidButton";
import MoneyTransferIcon from "../../assets/imgs/icons/telegram.svg";
import CreateMoneyTransfer from "../../components/Modal/CreateMoneyTransfer";
import useModal from "../../components/Modal/useModal";
import Table from "../../components/Table";
import Spinner from "../../components/Spinner";
import Pagination from "../../components/Pagination";
import Container from "../../components/Container";
import { useMemo, useState } from "react";
import DocumentList from "../Documents/DocumentList";
import ClientStatus from "../Documents/ClientStatus";
import PaymentStatus from "../Documents/PaymentStatus";
import MoneyTranferService from "../../services/MoneyTransfers";
import { ReactComponent as FromToIcon } from "../../assets/imgs/icons/from-to.svg";
import { useNavigate } from "react-router-dom";
const MoneyTransfer = () => {
  const columns = useMemo(
    () => [
      {
        Header: "Id",
        accessor: "id",
        disableSortBy: true,
      },
      {
        Header: "Request ID & Status",
        Cell: ({ row }) => {
          return (
            <div>
              <div>{row.original.erp_data.name}</div>
              <div className="flex justify-center mt-2">
                <ClientStatus status={row.original.erp_data.client_status} />
              </div>
            </div>
          );
        },
        disableSortBy: true,
      },
      {
        Header: "Transfer Date",
        accessor: "erp_data.date",

        Cell: ({ cell: { value } }) => {
          return value ? (
            <span>{value}</span>
          ) : (
            <span className="text-grayColorText2">Pending</span>
          );
        },
      },
      {
        Header: "From - To",
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <div className="flex flex-row items-center justify-center">
              <div className="px-1">
                <FromToIcon />
              </div>
              <div className="flex flex-col items-start justify-center">
                <div className="py-1">{row.original.erp_data.issuer}</div>
                <div className="py-1">{row.original.erp_data.beneficiary}</div>
              </div>
            </div>
          );
        },
      },
      {
        Header: "Amount",
        accessor: "erp_data.amount_with_currency",
        disableSortBy: true,

        Cell: ({ cell: { value } }) => {
          return value ? (
            <span className="font-bold">{value}</span>
          ) : (
            <span className="text-grayColorText2">Pending</span>
          );
        },
      },
      {
        Header: "Payment Status",
        accessor: "erp_data.payment_status",
        disableSortBy: true,
        Cell: ({ cell: { value } }) => {
          return value ? (
            <PaymentStatus status={value} />
          ) : (
            <span className="text-grayColorText2">Pending</span>
          );
        },
      },
      {
        Header: "Creation Date  & Time",
        accessor: "erp_data.created_at",
      },
      {
        Header: "",
        accessor: "options",
        disableSortBy: true,
      },
    ],
    []
  );
  const callPromptModalCreateMoneyTransfer = useModal(CreateMoneyTransfer);
  const handleCreateMoneyTransfer = async () => {
    try {
      const res = await callPromptModalCreateMoneyTransfer({}).then((r) => {
        console.log("aaaaaaaa");
      });
    } catch (e) {
      console.error(e);
    }
  };

  const navigate = useNavigate();
  const handleClickRow = (id, documentObject) => {
    navigate(`/money-transfer/${id}`, { state: { document: documentObject } });
  };
  return (
    <div>
      <div className="border-b">
        <Container>
          <div className="flex flex-row items-center py-5 justify-between ">
            <div className="text-3xl font-bold">Money Transfer</div>
            <div>
              <SolidButton onClick={handleCreateMoneyTransfer}>
                <div className="flex flex-row items-center">
                  <div className="pr-2">
                    <img src={MoneyTransferIcon} />
                  </div>
                  <div>Money Transfer</div>
                </div>
              </SolidButton>
            </div>
          </div>
        </Container>
      </div>
      <Container>
        <DocumentList
          columns={columns}
          getDocumentsService={MoneyTranferService.getMoneyTransfers}
          handleClickRow={handleClickRow}
        />
      </Container>
    </div>
  );
};

export default MoneyTransfer;
