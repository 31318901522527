import { REFRESH_CURRENT_PAGE } from "./actionType";

const initialState = {
  refreshKey: null,
};

const FileViewerReducer = (state = initialState, action) => {
  switch (action.type) {
    case REFRESH_CURRENT_PAGE:
      const id = "id" + Math.random().toString(16).slice(2);
      return {
        refreshKey: id,
      };
    default:
      return state;
  }
};
export default FileViewerReducer;
