import { useFormContext } from "react-hook-form";
import ErrorInput from "../Error";

const CheckBoxInput = ({ name, serverErrors, label, ...props }) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();
  return (
    <div className="w-full">
      <div className="flex flex-row items-center">
        <div>
          <input
            className="border-gray-300 focus:ring-3 focus:ring-blue-300 outline-none rounded"
            type="checkbox"
            {...register(name)}
            {...props}
          />
        </div>
        <div className="ml-2 text-sm font-normal">{label}</div>
      </div>
      <ErrorInput name={name} errors={errors} serverErrors={serverErrors} />
    </div>
  );
};

export default CheckBoxInput;
