import { useState } from "react";
import { connect } from "react-redux";
import React, { useRef } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import UploadDocumentService from "../../services/UploadDocument";
import TextInput from "../Inputs/Text";
import validateTextInput from "../Inputs/Text/validation_with_numbers";
import SubmitButton from "../Buttons/Submit";
import ErrorMessage from "../ErrorMessage";
import CancelIcon from "../../assets/imgs/icons/cancel.svg";
import { capitalizeFirstLetter, getCookie } from "../../utils";

const schema = yup
  .object({
    name: validateTextInput
      .required("This field is required")
      .max(20, "max 20 characters"),
  })
  .required();

const CreateNewVendor = ({
  type,
  message,
  onResolve,
  onReject,
  currentCompany,
}) => {
  const backRef = useRef(null);
  const user = JSON.parse(getCookie("user"));
  const [error, setError] = useState("");
  const methods = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (data) => {
    try {
      const name = data.name;
      if (type === "vendor") {
        const vendors = await UploadDocumentService.addVendor(
          currentCompany.id,
          name
        );
        onResolve(vendors);
      } else {
        const vendors = await UploadDocumentService.addCustomer(
          currentCompany.id,
          name
        );
        onResolve(vendors);
      }
    } catch (error) {
      setError(error);
    }
  };
  return (
    <div
      ref={backRef}
      isOpen={backRef}
      className="fixed inset-0 overflow-y-auto"
      style={{ zIndex: 1000 }}
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(onSubmit)}>
          <div className="flex items-end justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block overflow-hidden text-left align-bottom bg-white shadow-xl rounded-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full">
              <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div className="w-full sm:flex sm:items-start ">
                  <div className="w-full">
                    <div className="flex flex-row justify-between">
                      <h3
                        className="text-xl font-bold text-gray-900 leading-7 font-poppins"
                        id="modal-title"
                      >
                        Add a new {capitalizeFirstLetter(type)}
                      </h3>
                      <div
                        className="cursor-pointer"
                        onClick={() => onReject()}
                      >
                        <img src={CancelIcon} alt="" />
                      </div>
                    </div>
                    <div className="w-full mt-4">
                      <TextInput
                        name="name"
                        serverErrors={error}
                        placeholder={`${capitalizeFirstLetter(type)} name`}
                      />
                    </div>
                    {error?.detail && (
                      <ErrorMessage>{error.detail}</ErrorMessage>
                    )}
                  </div>
                </div>
                <div className="flex flex-row-reverse mt-4">
                  <div className="w-24">
                    <SubmitButton className="px-4 py-3 rounded-lg">
                      Add
                    </SubmitButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { currentCompany: state.Company.currentCompany };
};
export default connect(mapStateToProps, {})(CreateNewVendor);
