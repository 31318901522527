import { useEffect, useState, useRef } from "react";
import { useFormContext } from "react-hook-form";
import ErrorInput from "../Error";
import SearchIcon from "../../../assets/imgs/icons/search.svg";
import RadioButtonCheckedIcon from "../../../assets/imgs/icons/radio_button_checked.svg";
import _ from "lodash";

import arrowDropDown from "../../../assets/imgs/icons/arrow_drop_down.svg";
const MonetaryWithCurrencyInput = ({
  name,
  currency,
  currencies,
  onChangeCurrency,
  serverErrors,
  ...props
}) => {
  const {
    register,
    formState: { errors },
  } = useFormContext();

  const [isOpen, setIsOpen] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [virtualOptions, setVirtualOptions] = useState([]);
  const [showedOptions, setShowedOptions] = useState([]);

  const ref = useRef(null);
  const onClickOutside = () => {
    setIsOpen(false);
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        onClickOutside && onClickOutside();
      }
    };
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, [ref]);

  const searchOption = (e) => {
    const key = e.target.value;
    setSearchValue(key);
    setShowedOptions(
      virtualOptions.filter((option) =>
        option.label.toLowerCase().includes(key.toLowerCase())
      )
    );
  };

  useEffect(() => {
    setShowedOptions(virtualOptions);
    setSearchValue("");
  }, [virtualOptions]);

  useEffect(() => {
    setVirtualOptions(
      currencies.map((option) => {
        return { ...option };
      })
    );
  }, [currencies]);
  return (
    <div className="w-full ">
      <div className="relative w-full">
        <input
          type="text"
          placeholder="00.00"
          className={` w-full mb-1 font-bold leading-5 placeholder-monetary	${
            _.get(errors, name)
              ? "text-errorColor border-errorColor focus:text-errorColor focus:border-errorColor focus:bg-white placeholder:text-errorColor"
              : ""
          }`}
          {...register(name, { defaultValue: 0.0 })}
          {...props}
        />

        <div className="absolute top-0 right-0 w-24 h-full pb-1 mb-1 font-bold">
          {/* onClick={toggleOpen} */}
          <div className="border border-borderColor flex justify-center items-center w-full h-full bg-bgGray rounded-r-md">
            <div className="flex flex-row items-center justify-center cursor-pointer">
              <div>{currency?.code || "AED"}</div>
              <div className="ml-2">
                <img src={arrowDropDown} alt="" />
              </div>
            </div>
          </div>
        </div>
        {isOpen && (
          <div
            className="absolute py-2 bg-white rounded-md shadow-dropdownMenu overflow-hidden z-20"
            ref={ref}
            style={{ width: "20rem", right: 0, top: 45 }}
          >
            <div className="flex flex-row w-full h-full px-2 border-b-2">
              <img src={SearchIcon} />
              <input
                className="w-full px-2 py-2 ml-2 border-0 focus:outline-none"
                placeholder="Search"
                value={searchValue}
                onChange={searchOption}
              />
            </div>
            <div className="overflow-auto max-h-60">
              {showedOptions.length === 0 && (
                <div className="py-4 text-sm font-roboto">
                  <div className="flex flex-row justify-center">
                    <div>No Option</div>
                  </div>
                </div>
              )}
              {showedOptions.length > 0 && (
                <div>
                  {showedOptions.map((option) => (
                    <div
                      className="py-4 text-sm cursor-pointer font-roboto"
                      onClick={() => {
                        onChangeCurrency(option.value);
                        setIsOpen(false);
                      }}
                    >
                      <div className="flex flex-row items-center">
                        <div className="mx-2">
                          {option.value !== currency.id && (
                            <span className="inline-block w-4 h-4 border rounded-full border-grey flex-no-shrink"></span>
                          )}
                          {option.value === currency.id && (
                            <img src={RadioButtonCheckedIcon} alt="" />
                          )}
                        </div>
                        <div className="mr-2">
                          <img src={option?.icon} alt="" />
                        </div>
                        <div>{option.label}</div>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      <ErrorInput name={name} errors={errors} serverErrors={serverErrors} />
    </div>
  );
};

export default MonetaryWithCurrencyInput;
