import React, { useState, useEffect, useCallback, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { closeFileViewer } from "../../store/Layout/FileViewer/actions";
import { getFileType } from "../../utils";
import CancelIcon from "../../assets/imgs/icons/cancelWhite.svg";
import ImageViewer from "../ImageViewer";
import DocViewer from "../DocViewer";
import SheetViewer from "../SheetViewer";
import PdfViewer from "../PdfViewer";
import { Dialog, Transition } from "@headlessui/react";
import pdf from "../../assets/imgs/icons/file.png";
import { FaSearchMinus, FaSearchPlus } from "react-icons/fa";

import { FiPrinter } from "react-icons/fi";

const ZOOM = [
  {
    scale: 0.5,
    perc: "50%",
  },
  {
    scale: 1,
    perc: "100%",
  },
  {
    scale: 1.5,
    perc: "150%",
  },
];

const FileViewer = ({ file, isOpen, closeFileViewer }) => {
  const [currentIndex, setCurrentIndex] = useState(1);
  const handleViewFile = () => {
    const type = file?.type || getFileType(file?.name);
    if (!file.link) {
      const url = window.URL.createObjectURL(file, {});
      file.link = url;
    }
    if (type === "image") {
      return <ImageViewer image={file?.fileOnServer?.file} />;
    } else if (type === "word") {
      return <DocViewer word={file?.fileOnServer?.file} />;
    } else if (type === "excel") {
      return <SheetViewer sheet={file?.fileOnServer?.file} />;
    } else if (type === "pdf") {
      return (
        <PdfViewer
          currentScale={ZOOM[currentIndex].scale}
          pdf={file?.fileOnServer?.file}
        />
      );
    }
  };

  const escFunction = useCallback((event) => {
    if (event.key === "Escape") {
      closeFileViewer();
    }
  }, []);
  useEffect(() => {
    document.addEventListener("keydown", escFunction, false);

    return () => {
      document.removeEventListener("keydown", escFunction, false);
    };
  }, []);

  if (!isOpen) return null;
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative"
        style={{ zIndex: "1000000" }}
        onClose={closeFileViewer}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div
            className="fixed inset-0 bg-black"
            style={{
              opacity: ".9",
            }}
          />
        </Transition.Child>
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full transform overflow-hidden rounded-2xl  transition-all">
                <div className="px-20 py-5">
                  {/* file viewer header */}
                  <div className="flex justify-between items-center">
                    <div className="flex gap-2 items-center">
                      <img src={pdf} alt="" />
                      <p className="text-white">
                        {file.name || "Document"}.{file?.type || ""}
                      </p>
                    </div>
                    <div className="flex text-white gap-2 items-center text-lg">
                      <button
                        onClick={() => {
                          if (currentIndex === 0) {
                            return;
                          }
                          setCurrentIndex(currentIndex - 1);
                        }}
                        className="cursor-pointer "
                      >
                        <FaSearchMinus />
                      </button>
                      <p> {ZOOM[currentIndex].perc} </p>
                      <button
                        onClick={() => {
                          if (currentIndex === 2) {
                            return;
                          }
                          setCurrentIndex(currentIndex + 1);
                        }}
                        className="cursor-pointer "
                      >
                        <FaSearchPlus />
                      </button>
                    </div>

                    <div className="flex gap-3 items-center">
                      {/* <button className="text-xl cursor-pointer text-white">
                        <FiPrinter />
                      </button> */}
                      <button
                        className="cursor-pointer "
                        onClick={closeFileViewer}
                      >
                        <img src={CancelIcon} alt="" />
                      </button>
                    </div>
                  </div>
                  {/* body  */}
                  <div
                    className="text-white flex justify-center items-center "
                    style={{
                      transform: "translateY(10px)",
                    }}
                  >
                    {handleViewFile()}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
const mapStateToProps = (state) => {
  return {
    isOpen: state.FileViewer.isOpen,
    file: state.FileViewer.file,
  };
};
export default connect(mapStateToProps, { closeFileViewer })(FileViewer);
