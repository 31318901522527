import React, { useState, useEffect } from "react";
import ErrorMessage from "../../ErrorMessage";
import { useFormContext, Controller } from "react-hook-form";
import DatePicker from "react-datepicker";
import arrowDropDown from "../../../assets/imgs/icons/arrow_drop_down.svg";
import ErrorInput from "../Error";
import _ from "lodash";

import "react-datepicker/dist/react-datepicker.css";
const DateInput = ({ showText, name, serverErrors, label, ...props }) => {
  const {
    formState: { errors },
    control,
    setValue,
    watch,
  } = useFormContext();
  const [prevValue, setPrevValue] = useState(watch(name));
  const [myRef, setMyRef] = useState(false);
  const closeCalendar = () => {
    myRef.setOpen(false);
  };
  return (
    <div className="w-full">
      <Controller
        control={control}
        name={name}
        render={({ field: { onChange, value, ref } }) => (
          <div className="relative w-full">
            <div className="w-full" style={{ zIndex: 10000 }}>
              <DatePicker
                selected={value}
                ref={(r) => {
                  setMyRef(r);
                }}
                onChange={onChange}
                value={showText}
                dateFormat="MMMM d, yyyy"
                className={`w-full font-roboto font-medium text-sm pr-8 min-w-160 ${
                  _.get(errors, name) ? "border-redColor" : ""
                }`}
                shouldCloseOnSelect={true}
                {...props}
                portalId="root-portal"
              ></DatePicker>
            </div>
            <div
              className="absolute top-4 right-3 h-full font-bold text-lg"
              onClick={() => {
                myRef.setOpen(true);
              }}
            >
              <img src={arrowDropDown} alt="" />
            </div>
          </div>
        )}
      />
      <ErrorInput name={name} errors={errors} serverErrors={serverErrors} />
    </div>
  );
};

export default DateInput;
