import getHttpClient from "../api";
const client = getHttpClient();

const services = {
  getDocument: async (documentId) => {
    try {
      const response = await client.get(`/dms/document/${documentId}`, {});
      return response.data;
    } catch (error) {
      throw error;
    }
  },
  getDocuments: async (companyId, filters) => {
    try {
      let data = { ...filters };
      if (data.page) {
        data.page = +data.page;
      }
      // if (orderBy) {
      //   data.order_by = orderBy;
      // }
      // if (searchValue) {
      //   data.search_value = searchValue;
      // }
      const response = await client.get(`/dms/documents/${companyId}`, {
        params: data,
      });
      return response.data;
    } catch (error) {
      throw error;
    }
  },
};

export default services;
