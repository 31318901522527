import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useMatch,
  useNavigate,
  useResolvedPath,
} from "react-router-dom";
import { useForm, FormProvider, useFieldArray } from "react-hook-form";
import Logo from "../../assets/imgs/logo-sidebar.svg";
import { ReactComponent as DashboardIcon } from "../../assets/imgs/icons/Union.svg";
import { ReactComponent as DocumentsIcon } from "../../assets/imgs/icons/documents.svg";
import { ReactComponent as ReportsIcon } from "../../assets/imgs/icons/reports.svg";
import { ReactComponent as MoneyTransferIcon } from "../../assets/imgs/icons/moneyTransfer.svg";
import Header from "../Header";
import RadioTabInput from "../Inputs/RadioTabs";

const module_types = [
  { key: "documents", value: "Documents" },
  { key: "invoicing", value: "Invoicing" },
];
const MENU = [
  { key: "home", value: "Home", Icon: DashboardIcon, link: "/" },
  {
    key: "documents",
    value: "Documents",
    Icon: DocumentsIcon,
    link: "/documents",
  },
  {
    key: "reports",
    value: "Reports",
    Icon: ReportsIcon,
    link: "/reports/balancesheet",
  },
  {
    key: "moneyTransfer",
    value: "Money Transfer",
    Icon: MoneyTransferIcon,
    link: "/money-transfer",
  },
];
const Layout = () => {
  const methods = useForm({
    defaultValues: {
      module_type: "documents",
      view_type: "list",
    },
  });
  return (
    <div className="flex w-screen h-screen">
      <aside
        className="flex top-0 left-0 flex-col fixed z-50  items-center h-screen bg-white shadow"
        style={{
          width: "20rem",
        }}
      >
        <div className="flex flex-row justify-center w-full pb-5 mt-8 border-b">
          <img src={Logo} alt="" />
        </div>
        {/* <div className="flex flex-row justify-center w-full px-8 pb-4 mt-8 border-b">
          <FormProvider {...methods}>
            <form>
               <RadioTabInput name="module_type" options={module_types} /> 
            </form>
          </FormProvider>
        </div> */}
        <div className="flex flex-col w-full mt-4 cursor-pointer">
          <nav>
            {MENU.map(({ link, value, Icon }, i) => (
              <NavLink
                key={i}
                to={link}
                className={({ isActive }) =>
                  "flex items-center px-4 py-4 border-l-4 text-black" +
                  (isActive
                    ? " bg-backgroundBlue border-arrowTop menu-is-active"
                    : "")
                }
              >
                <div className="flex flex-row">
                  {Icon && <Icon />}
                  <div className="ml-4">{value}</div>
                </div>
              </NavLink>
            ))}
          </nav>
        </div>
      </aside>

      <div
        style={{
          marginLeft: "20rem",
        }}
        className="w-full h-screen ml-auto"
      >
        <Header />
        <div className="container">
          <Outlet />{" "}
        </div>
      </div>
    </div>
  );
};

export default Layout;
