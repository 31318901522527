import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import _ from "lodash";
import { yupResolver } from "@hookform/resolvers/yup";
import CalendarIcon from "../../../assets/imgs/icons/calendar.svg";
import DescriptionIcon from "../../../assets/imgs/icons/description.svg";
import UploadDocumentService from "../../../services/UploadDocument";
import CreatePaymentService from "../../../services/CreatePayment";

import TextareaInput from "../../Inputs/TextArea";
import { formatDate, getCookie } from "../../../utils";

import useModal from "../../Modal/useModal";
import DropDownContent from "../../DropdownContent";
import UploadFiles from "../../UploadFiles";
import DateInput from "../../Inputs/Date";
import AssignToSomeone from "../../AssignToSomeone";
import SubmitButton from "../../Buttons/Submit";
import { useBlocker } from "../../../hooks/usePrompt";
import ConfirmExit from "../../Modal/confirmExit";
import GlobalErrorMessage from "../../GlobalErrorMessage";
import successUploadDocument from "../../Modal/successUploadDocument";
import ConfirmPayment from "../../Modal/ConfirmPayment";
import { ToastifyError } from "../../../toastify";
import { trackPromise } from "react-promise-tracker";
import valiationForm from "./validation";
import Category from "./category";
import CostCenter from "./costCenter";
import PaymentMethod from "./paymentMethod";
import AmountWithCurrency from "./AmountWithCurrency";
import PartnerWithOutstanding from "./partner";

const schema = valiationForm("payment");
const CreateDocument = ({ basePaymentMethods, currentCompany }) => {
  const outstandingRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const callPromptModalConfrimExit = useModal(ConfirmExit);
  const callPromptSuccessUploadDocument = useModal(successUploadDocument);
  const callPromptConfirmPayment = useModal(ConfirmPayment);
  const user = JSON.parse(getCookie("user"));
  const [files, setFiles] = React.useState([]);
  const [isUploading, setIsUploading] = React.useState(false);
  const [outstandingsOfPartner, setOutstandingsOfPartner] = useState([]);
  const [remainingAmount, setRemainingAmount] = useState([]);
  const [currentCurrency, setCurrentCurrency] = useState(null);
  const [assignees, setAssignees] = useState([]);
  const [error, setError] = useState("");
  const [minDate, setMinDate] = useState(null);
  const [isBlocking, setIsBlocking] = useState(true);
  const [selectedAssignee, setSelectAssignee] = React.useState([]);
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      document_date: new Date(),
      type: "payments",
      description: "",
      paid_amount: "",
      outstandings: [],
      currency: null,
      partner: null,
      paymentMethod: null,
    },
  });
  const handleConfirmExit = async (nextLocation) => {
    try {
      setIsBlocking(false);
      const res = await callPromptModalConfrimExit({
        title: "Save changes before closing",
        message:
          "All changes will be lost if you close, are you sure you want to discard your changes?",
      });
      if (res) {
        navigate(nextLocation.location.pathname);
      } else {
        setIsBlocking(true);
      }
    } catch (e) {
      setIsBlocking(true);
      console.error(e);
    }
  };
  useBlocker(handleConfirmExit, isBlocking);

  useEffect(() => {
    setIsBlocking(methods.formState.isDirty);
  }, [methods.formState.isDirty]);

  useEffect(() => {
    setIsBlocking(files.length > 0 || selectedAssignee.length > 0);
  }, [files, selectedAssignee]);
  useEffect(() => {
    getAssignee();
    getFiscalYear();
  }, []);

  const getAssignee = async () => {
    try {
      const assignees = await UploadDocumentService.getAssigneeOfCompany(
        currentCompany.id
      );
      setAssignees(
        assignees.map((assignee) => {
          return { value: assignee.id, label: assignee.name };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };
  const getFiscalYear = async () => {
    try {
      const fiscalYear = await UploadDocumentService.getFiscalYear(
        currentCompany.id
      );
      setMinDate(
        Math.max(
          new Date(fiscalYear?.lock_date),
          new Date(fiscalYear?.date_from)
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const onSubmit = async (data) => {
    try {
      const companyId = currentCompany.id;
      const paymentMethod = data.payment_method;
      const clientCategory = data?.client_category;
      const costCenter = data?.cost_center;
      const paid_amount = data?.paid_amount;
      const description = data?.description;
      const partner = data.partner;
      const currency = currentCurrency.id;
      const documentDate = formatDate(data.document_date);
      const outstandings = data.outstandings;
      const addRemainingCredit = data.addRemainingCredit;
      const attachmentsDocument = files
        .filter((attachment) => attachment?.fileOnServer?.id)
        .map((attachment) => attachment?.fileOnServer?.id);
      if (
        partner &&
        outstandings.length === 0 &&
        outstandingsOfPartner.length > 0
      ) {
        const res = await callPromptConfirmPayment({
          message:
            "Would you like to add the amount as a credit to the vendor or select one of the outstanding bills you have for that vendor?",
          title: "You didn’t select any of the outstanding bills",
          addAsCredit: "Add as a credit to the vendor",
          selectOutstandings:
            outstandings.length !== outstandingsOfPartner.length - 1 &&
            "Select outstanding bills",
        });
        if (res === "selectOutstandings") {
          await outstandingRef.current.selectOutstandingsFromOutside();
          return;
        }
      } else {
        if (
          !addRemainingCredit &&
          remainingAmount > 0 &&
          outstandings.length > 0
        ) {
          const res = await callPromptConfirmPayment({
            title: `The remaining amount ${remainingAmount} ${currentCurrency?.code}`,
            message:
              "Would you like to add the remaining amount as a credit to the vendor",
            addAsCredit: "Add as a credit to the vendor",
            selectOutstandings:
              outstandings.length !== outstandingsOfPartner.length - 1 &&
              "Select more outstanding bills",
          });
          if (res === "selectOutstandings") {
            await outstandingRef.current.selectOutstandingsFromOutside();
            return;
          }
        }
      }
      const paidOutstandings = outstandings.map((outstanding) => {
        return {
          id: outstanding.outstandingId,
          amount: outstanding.outstandingAmount,
        };
      });
      const submit = async () => {
        await CreatePaymentService.createPayment(
          companyId,
          paymentMethod,
          clientCategory,
          costCenter,
          paid_amount,
          description,
          partner,
          currency,
          documentDate,
          false,
          addRemainingCredit,
          selectedAssignee,
          attachmentsDocument,
          paidOutstandings
        );
      };
      await trackPromise(submit());
      const selectedPaymentMethod = basePaymentMethods.find(
        (payment) => payment.id === paymentMethod
      );
      let selectedPaymentMethodBalance = parseFloat(
        selectedPaymentMethod.balance
      );
      selectedPaymentMethodBalance = isNaN(selectedPaymentMethodBalance)
        ? null
        : selectedPaymentMethodBalance;
      await callPromptSuccessUploadDocument({
        time: 5000,
        message: "The Request was processed successfully",
        title: "Successful!",
        availableBalance:
          selectedPaymentMethodBalance !== null
            ? `${selectedPaymentMethodBalance - paid_amount} ${
                selectedPaymentMethod?.currency.code
              }`
            : null,
      });
      navigate("/documents");
    } catch (error) {
      console.error(error);
      if (error?.detail) ToastifyError(error?.detail);
      setError(error);
    }
  };
  const partner = methods.watch("partner");
  const description = methods.watch("description");
  useEffect(() => {
    if (methods.formState.isSubmitted) methods.trigger("vendorOrDescripation");
  }, [partner, description]);
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <div className="flex flex-col">
          {methods.formState?.errors?.vendorOrDescripation && (
            <GlobalErrorMessage>
              {methods.formState?.errors?.vendorOrDescripation?.message}
            </GlobalErrorMessage>
          )}
          <div className="flex flex-row mt-10 mb-28">
            <div className="flex justify-center w-1/2">
              <div className="w-full">
                <div className="mb-6">
                  <div className="flex flex-row items-center mb-4">
                    <div className="w-6 h-6">
                      <img src={CalendarIcon} alt="" />
                    </div>
                    <div className="ml-3">Date</div>
                  </div>
                  <div>
                    <DateInput
                      name="document_date"
                      maxDate={new Date()}
                      minDate={minDate}
                      serverErrors={error}
                    />
                  </div>
                </div>

                <div className="mb-6">
                  <AmountWithCurrency
                    type="vendor"
                    currentCurrency={currentCurrency}
                    setCurrentCurrency={setCurrentCurrency}
                    error={error}
                  />
                </div>

                <div className="mb-6">
                  <PaymentMethod
                    currentCurrency={currentCurrency}
                    error={error}
                  />
                </div>

                <div className="mb-6">
                  <PartnerWithOutstanding
                    type="vendor"
                    remainingAmount={remainingAmount}
                    setRemainingAmount={setRemainingAmount}
                    outstandingsOfPartner={outstandingsOfPartner}
                    setOutstandingsOfPartner={setOutstandingsOfPartner}
                    currentCurrency={currentCurrency}
                    error={error}
                    currentCompany={currentCompany}
                    ref={outstandingRef}
                  />
                  {/* <Child ref={outstandingRef} /> */}
                </div>
                <div className="mb-6">
                  <div className="flex flex-row items-center mb-4">
                    <div className="w-6 h-6">
                      <img src={DescriptionIcon} alt="" />
                    </div>
                    <div className="ml-3">Description</div>
                  </div>
                  <div className="w-full h-full">
                    <TextareaInput
                      rows="4"
                      id="description"
                      name="description"
                      placeholder="Add Description"
                      serverErrors={error}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center w-1/2 ml-20">
              <div className="w-full">
                <div>
                  <DropDownContent title="Additional info">
                    <div className="mb-6">
                      <CostCenter error={error} />
                    </div>
                    <div className="mb-6">
                      <Category error={error} />
                    </div>
                  </DropDownContent>
                </div>
                <div>
                  <UploadFiles
                    files={files}
                    setFiles={setFiles}
                    isUploading={isUploading}
                    setIsUploading={setIsUploading}
                    error={error}
                    title="Related Attachments"
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="fixed bottom-0 left-0 flex justify-center w-screen h-20 bg-white shadow-footerShadow">
            <div className="w-full h-full max-w-screen-lg">
              <div className="flex flex-row items-center justify-between h-full align-middle">
                <AssignToSomeone
                  options={assignees}
                  onChange={(selectedAssignee) => {
                    setSelectAssignee(selectedAssignee);
                  }}
                />
                <div className="flex flex-row items-center justify-center">
                  <div className="p-4 font-bold font-roboto text-arrowTop"></div>
                  <div className="w-40">
                    <SubmitButton
                      disabled={
                        methods.formState.isSubmitting ||
                        !methods.watch("paid_amount") ||
                        isUploading
                      }
                    >
                      Create
                    </SubmitButton>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const mapStateToProps = (state) => {
  return {
    basePaymentMethods: state.PaymentMethods.basePaymentMethods,
    currentCompany: state.Company.currentCompany,
  };
};
export default connect(mapStateToProps, {})(CreateDocument);
