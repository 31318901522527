import React, { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { toast } from "react-toastify";
import * as yup from "yup";

import SubmitButton from "../Buttons/Submit";
import ErrorMessage from "../ErrorMessage";
import DateInput from "../Inputs/Date";
import CancelIcon from "../../assets/imgs/icons/cancel.svg";
import RadioTabInput from "../Inputs/RadioTabs";
import Avatar from "../Avatar";
import Employee from "../../services/Employee";
import CheckBoxInput from "../Inputs/CheckBox";
import { formatDate } from "../../utils";
import ConfirmDeleteEmployee from "./ConfirmDelete";
import useModal from "./useModal";
import { trackPromise } from "react-promise-tracker";
import { ToastifyError, ToastifySuccess } from "../../toastify";
import trashIcon from "../../assets/imgs/icons/trash.svg";

const REASON_FOR_LEAVE = [
  { key: "resignation", value: "Resignation" },
  { key: "termination", value: "Termination" },
];
const schema = yup
  .object({
    last_working_day: yup.string().required("This field is required"),

    reason_for_leave: yup.string().when("employee_type", {
      is: "permanent",
      then: (schema) => schema.required("This field is required"),
    }),
  })
  .required();
const DeleteEmployee = ({ employee, onResolve, onReject }) => {
  const callPromptModalConfrimDeleteEmployee = useModal(ConfirmDeleteEmployee);
  const [error, setError] = useState();
  const backRef = useRef(null);
  const methods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      reason_for_leave: "resignation",
      employee_type: employee.type,
    },
  });
  const onSubmit = async (data) => {
    try {
      const res = await callPromptModalConfrimDeleteEmployee({
        title: "Delete Employee",
        message: `Are you sure you want to delete ${employee.name}?`,
        cancelButtonTitle: "No, keep the employee",
        submitButtonTitle: "Yes, Delete the employee",
      });

      const submit = async () => {
        data["last_working_day"] = formatDate(data.last_working_day || null);
        if (employee.employee_type !== "permanent")
          delete data["reason_for_leave"];
        await Employee.deleteEmployee(employee.id, data);
        ToastifyError(
          <div className="flex flex-row justify-between w-full">
            <div>Employees has been successfully deleted</div>
            <div className="flex flex-row">
              <div className="mx-2">|</div>
            </div>
          </div>,
          {
            icon: <img src={trashIcon} alt="" />,
          }
        );
      };
      if (res) {
        await trackPromise(submit());
      }
      onResolve(employee);
    } catch (error) {
      setError(error);
    }
  };

  const Cancel = () => {
    onReject();
  };
  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)} name="createEmployee">
        <div
          ref={backRef}
          isOpen={backRef}
          className="fixed inset-0 overflow-y-auto"
          // style={{ zIndex: 1000 }}
          aria-labelledby="modal-title"
          role="dialog"
          aria-modal="true"
        >
          <div className="flex items-end justify-center  px-4 pt-4 pb-20 text-center sm:block sm:p-0">
            <div
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
              aria-hidden="true"
            ></div>
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <div className="inline-block overflow-hidden text-left align-bottom bg-white rounded-lg shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
              <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
                <div className="w-full sm:flex sm:items-start ">
                  <div className="w-full">
                    <div className="flex flex-row justify-between">
                      <h3
                        style={{ fontSize: "1.4rem" }}
                        className=" font-bold text-black leading-7 font-poppins"
                        id="modal-title"
                      >
                        Delete Employee
                      </h3>
                      <div
                        className="cursor-pointer"
                        onClick={() => onReject()}
                      >
                        <img src={CancelIcon} alt="" />
                      </div>
                    </div>

                    <div className="flex flex-row items-center mt-8">
                      <div className="pr-4">
                        <Avatar avatar={employee.avatar} />
                      </div>
                      <div>
                        <p className="text-black capitalize font-medium">
                          {employee.name}
                        </p>
                        <div className="text-black">
                          {employee.user.email || employee.user.mobile}
                        </div>
                      </div>
                    </div>
                    {error?.detail && (
                      <ErrorMessage>{error.detail}</ErrorMessage>
                    )}
                    <div className="">
                      <div className="mt-4">
                        <p className="mb-3 text-dark_black">
                          Last Working Date
                        </p>
                        <DateInput
                          name="last_working_day"
                          maxDate={new Date()}
                          serverErrors={error}
                          placeholderText="Last Working Date"
                        />
                      </div>
                      {employee.employee_type === "permanent" && (
                        <div className="flex flex-row items-center justify-between mt-4">
                          <div>Reason For Leave</div>
                          <div>
                            <RadioTabInput
                              name="reason_for_leave"
                              options={REASON_FOR_LEAVE}
                              serverErrors={error}
                            />
                          </div>
                        </div>
                      )}
                      <div
                        className={`p-2 mt-1 text-sm ${
                          employee?.allow_access ? "" : "hidden"
                        }`}
                      >
                        <CheckBoxInput
                          name="restrict_login"
                          serverErrors={error}
                          label={`Remove this employee's access to The Accounter`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-row-reverse items-center justify-between pt-6 mt-10 border-t-2">
                  <div className="flex flex-row items-center">
                    <div
                      className="px-8 text-sm font-bold cursor-pointer font-roboto text-grayColor"
                      onClick={Cancel}
                    >
                      Cancel
                    </div>
                    <div className="w-36">
                      <SubmitButton>Delete</SubmitButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </FormProvider>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {})(DeleteEmployee);
