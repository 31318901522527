import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useForm, FormProvider } from "react-hook-form";
import UploadDocument from "../../services/UploadDocument";
import ErrorMessage from "../ErrorMessage";
import DeleteIcon from "../../assets/imgs/icons/delete.svg";
import PdfFileIcon from "../../assets/imgs/icons/pdffile.svg";
import XlsFileIcon from "../../assets/imgs/icons/xlsfile.svg";
import XlsxFileIcon from "../../assets/imgs/icons/xlsxfile.svg";
import DocFileIcon from "../../assets/imgs/icons/docfile.svg";
import DocxFileIcon from "../../assets/imgs/icons/docxfile.svg";
import ImgFileIcon from "../../assets/imgs/icons/imgfile.svg";
import UploadFailIcon from "../../assets/imgs/icons/rotate-right.svg";
import { openFileViewer } from "../../store/Layout/FileViewer/actions";

const Icons = {
  pdf: PdfFileIcon,
  doc: DocFileIcon,
  docx: DocxFileIcon,
  xls: XlsFileIcon,
  xlsx: XlsxFileIcon,
  image: ImgFileIcon,
};

const FileUploaderProgress = ({
  file,
  removeFile,
  index,
  openFileViewer,
  setFileIsUploading,
}) => {
  const [failOnUpload, setFailOnUpload] = useState(false);
  const [progress, setProgress] = useState(0);
  const updateProgress = (data) => {
    setProgress(Math.round((100 * data.loaded) / data.total));
  };
  const handleDeleteFile = async (e) => {
    try {
      e.stopPropagation();
      removeFile(index);
      if (file.fileOnServer)
        await UploadDocument.deleteAttachment(file.fileOnServer.id);
    } catch (error) {
      console.error(error);
    }
  };

  const getFileType = () => {
    const name = file?.name?.toLowerCase();
    if (name?.endsWith(".pdf")) {
      return "pdf";
    } else if (name?.endsWith(".doc")) {
      return "doc";
    } else if (name?.endsWith(".docx")) {
      return "docx";
    } else if (name?.endsWith(".xls")) {
      return "xls";
    } else if (name?.endsWith(".xlsx")) {
      return "xlsx";
    } else if (name?.endsWith(".xlsm")) {
      return "xlsx";
    } else {
      return "image";
    }
  };
  const uploadFile = async (e) => {
    try {
      if (e) e.stopPropagation();
      file.isUploading = true;
      setFileIsUploading();
      setFailOnUpload(false);
      let fileObj = null;
      if (file?.link) {
        fileObj = await UploadDocument.uploadAttachmentViaUrl(
          file.name,
          file.link.split("?")[0],
          updateProgress
        );
      } else {
        fileObj = await UploadDocument.uploadAttachment(file, updateProgress);
      }
      file.fileOnServer = fileObj;
      file.isUploading = false;
      setFileIsUploading();
    } catch (error) {
      setFailOnUpload(true);
      setProgress(0);
    } finally {
      await setFileIsUploading(index, false);
    }
  };
  useEffect(() => {
    uploadFile();
  }, [file]);
  const getProgressColor = (progress) => {
    if (progress >= 100) {
      return "#48BB78";
    }
    return "#25ABC3";
  };
  return (
    <div className="w-full h-75 mt-3">
      <div
        className="bg-bgGray my-1.5 flex flex-row items-center p-3 cursor-pointer"
        onClick={() => {
          if (file?.fileOnServer?.file && !failOnUpload) openFileViewer(file);
        }}
      >
        <div className="flex justify-center">
          <img className="cursor-pointer" src={Icons[getFileType()]} alt="" />
        </div>
        <div className="flex-grow ml-6 mr-12 font-roboto">
          <div className="flex flex-col justify-between">
            <div className="text-sm font-medium break-all">{file.name}</div>

            {!failOnUpload && (
              <>
                <div className="w-full bg-gray-200 h-1 mt-2">
                  <div
                    className="h-1"
                    style={{
                      width: `${progress}%`,
                      backgroundColor: getProgressColor(progress),
                    }}
                  ></div>
                </div>
                <div className="flex mt-2 text-sm leading-3">
                  {progress < 100 ? progress + "%" : ""} done
                </div>
              </>
            )}
            {failOnUpload && (
              <div className="flex mt-2 text-sm leading-3 text-errorColor ">
                Upload Failed
                <img
                  className="ml-1"
                  src={UploadFailIcon}
                  alt=""
                  onClick={uploadFile}
                />
              </div>
            )}
          </div>
        </div>
        <div onClick={handleDeleteFile} className="cursor-pointer">
          <img src={DeleteIcon} alt="" />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, { openFileViewer })(
  FileUploaderProgress
);
