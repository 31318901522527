import { Link } from "react-router-dom";

const textSize = 'text-base ';

const color = {
    primary: 'text-white',
    secondary: 'text-slate-700 dark:text-slate-200',
    text: 'text-slate-700 hover:text-white dark:text-slate-200 dark:hover:text-white',
    outline: 'text-primaryDefault'
};

const btnSizes = {
    large: 'w-40 py-3 text-lg rounded-md',
    medium: 'px-5 py-3 text-md rounded-md',
    small: 'px-5 py-3 text-sm rounded-sm'
}

const backgroundColors = {
    primary: 'bg-primaryDefault',
    secondary: 'bg-black',
    text: 'bg-light-button hover:bg-blue-500 dark:bg-gray-800 dark:hover:bg-blue-500',
    outline: 'bg-transparent'
};

const border = {
    primary: 'border-none',
    secondary: 'border-2 border-gray-800 dark:border-white',
    text: 'border-none',
    outline: 'border border-primaryDefault'
};

const Button = ({
    type = 'primary',
    children,
    onClick,
    className = '',
    disabled = false,
    href,
    target,
    isBlock = false,
    size,
    htmlType = "button",
    ...props
}) => {
    const disabledStyle = disabled
        ? 'opacity-50 cursor-not-allowed'
        : 'transition ease-in-out duration-300 hover:cursor-pointer';

    let baseClasses = [
        'capitalize',
        textSize,
        border[type],
        btnSizes[size],
        backgroundColors[type],
        color[type],
        disabledStyle,
    ];

    if (className) {
        baseClasses = [...baseClasses, ...className.split(' ')];
    }
    if (isBlock) {
        baseClasses = [...baseClasses, 'block w-full'];
    }

    if (href) {
        let linkClasses = [...baseClasses, 'flex items-center justify-center whitespace-nowrap'];
        return (
            <Link to={href} target={target} onClick={onClick} className={linkClasses.join(' ')}>
                {children}
            </Link>
        );
    }

    return (
        <button htmlType={htmlType}  {...props} onClick={onClick} className={baseClasses.join(' ')} disabled={disabled}>
            {children}
        </button>
    );
};

export default Button;